import {
  ManagedLearnpathElementOneOf,
  ManagedLearnpathEmbeddedVideoElement,
  ManagedLearnpathEventElement,
  ManagedLearnpathFileElement,
  ManagedLearnpathLinkElement,
  ManagedLearnpathPlatformTopicElement,
  ManagedLearnpathQuizElement,
  ManagedLearnpathScormElement,
  ManagedLearnpathVideoElement,
} from '@/api/generated-api-and-types'
import { LearnpathElementType } from '@/api/types/LearnpathElementType'

// With better backend we could use Discriminated unions instead of these functions.

export function isElementOfType(
  element,
  type: 'event',
): element is ManagedLearnpathEventElement

export function isElementOfType(
  element,
  type: 'link',
): element is ManagedLearnpathLinkElement

export function isElementOfType(
  element,
  type: 'platform_topic',
): element is ManagedLearnpathPlatformTopicElement

export function isElementOfType(
  element,
  type: 'quiz',
): element is ManagedLearnpathQuizElement

export function isElementOfType(
  element,
  type: 'scorm',
): element is ManagedLearnpathScormElement

export function isElementOfType(
  element,
  type: 'embedded_video',
): element is ManagedLearnpathEmbeddedVideoElement

export function isElementOfType(
  element,
  type: 'file',
): element is ManagedLearnpathFileElement

export function isElementOfType(
  element,
  type: 'video',
): element is ManagedLearnpathVideoElement

export function isElementOfType(
  element: ManagedLearnpathElementOneOf,
  type: LearnpathElementType,
) {
  return element && element.type === type
}
