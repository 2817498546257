export const DASHBOARD_NAVIGATION_DOM_ID = 'dashboard-navigation'
export const BOOKMARKS_DOWNLOAD_ENDPOINT = '/bookmarks/download'
export const BOOKMARKS_ENDPOINT = '/bookmarks'
export const COURSE_ENDPOINT = '/course'
export const CURRENT_LECTURE_ENDPOINT = '/progress/current_lecture'
export const SCORE_ENDPOINT = '/progress/score'
export const DASHBOARD_SECTION_NAMES = {
  'best-topics': 'Best rated courses',
  'current-lesson': 'Your current lesson',
  'digital-transformation': 'Digital Transformation',
  'highlighted-topic': 'Highlighted topic',
  internal: 'Exclusive content for your company',
  mindset: 'Digital Transformation: Mindset',
  'k-soft-skills': 'Soft Skills',
  'l-digitale-produktentwicklung': 'Digital Product Development',
  'm-leadership-and-hr': 'Leadership and Human Resources',
  'new-technologies': 'New Technologies',
  'new-topics': 'New releases',
  'n-marketing': 'Marketing',
  organisation: 'Digital Transformation: Organisation',
  'o-lernkultur': 'Learning Culture',
  'p-digital-tools': 'Digital Tools',
  'q-daten-und-recht': 'Data and Law',
  recommended: 'Top picks for you',
  'r-sales': 'Sales',
  's-events': 'Events & Conferences',
  'z-pflichtschulungen': 'Compulsory Training',
} as const
