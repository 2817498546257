import { isNil } from 'lodash/fp'

import { UrlBuilder } from '@masterplandev/utils'

import { URL_DISCOVER } from '../../constants/constants'

const learnpathUrl = UrlBuilder()
  .template('/:prefix/:learnpathId/:infix/:elementId/:topic/:lecture/:suffix')
  .addParam({ prefix: 'learnpaths' })

const topicSlugUrl = UrlBuilder()
  .template('/:prefix/:topic/:lecture/:suffix')
  .addParam({ prefix: 'lectures' })

const skillSlugUrl = UrlBuilder()
  .template('/:prefix/:skill/:topic/:lecture/:suffix')
  .addParam({ prefix: 'skill' })

const courseSlugUrl = UrlBuilder()
  .template('/:prefix/:course/:topic/:lecture/:suffix')
  .addParam({ prefix: 'course-generic' })

const topicPageUrl = UrlBuilder()
  .template('/:prefix/:channel/:infix/:topic')
  .addParam({ prefix: URL_DISCOVER })

/*
 * This function builds links for both topic lectures and learnpath lectures.
 */
export default function buildLectureLink({
  channel,
  course,
  skill,
  elementId,
  hash,
  infix,
  learnpathId,
  lecture,
  prefix,
  query = {},
  suffix: originalSuffix,
  topic,
}) {
  // Adding suffix without lecture or element generates invalid lecture link.
  const suffix = isNil(lecture ?? elementId) ? null : originalSuffix

  // Parameter lecture and topic are both used also in context of learnpaths for
  // masterplan content.
  const commonParams = { prefix, topic, lecture, suffix }

  let urlCreator
  if (channel) {
    urlCreator = topicPageUrl.addParam({
      ...commonParams,
      channel,
      infix: topic ? 'view' : '',
    })
  } else if (learnpathId) {
    // For learnpath we can have app URL '/learnpaths/:learnpathId/:elementId'
    // or redux store api URL '/learnpaths/:learnpathId/elements/:elementId'
    // and this is why dynamic infix is required.
    urlCreator = learnpathUrl.addParam({
      ...commonParams,
      learnpathId,
      infix,
      elementId,
    })
  } else if (skill) {
    urlCreator = skillSlugUrl.addParam({
      ...commonParams,
      skill,
    })
  } else if (course) {
    urlCreator = courseSlugUrl.addParam({
      ...commonParams,
      course,
    })
  } else {
    urlCreator = topicSlugUrl.addParam({
      ...commonParams,
    })
  }

  return urlCreator
    .addQuery({ ...query }, { sort: false })
    .joinHash(hash)
    .toString()
}
