import { compact } from 'lodash/fp'

import { LearnpathElementType } from './types/LearnpathElementType'

export const QueryKeys = {
  companyKpi: {
    coursesRankingByRatings: () => [
      'company_kpi',
      'courses_ranking_by_ratings',
    ],
    coursesRankingByViews: () => ['company_kpi', 'courses_ranking_by_views'],
    metrics: () => ['company_kpi', 'metrics'],
    monthlyMetrics: () => ['company_kpi', 'monthly_metrics'],
  },
  learnpaths: {
    managed: {
      all: ['learnpaths', 'managed'],
      lists: () => [...QueryKeys.learnpaths.managed.all, 'list'],
      list: (query: Record<string, any>) => [
        ...QueryKeys.learnpaths.managed.lists(),
        query,
      ],
      details: () => [...QueryKeys.learnpaths.managed.all, 'detail'],
      detail: (learnpathId: string) => [
        ...QueryKeys.learnpaths.managed.details(),
        learnpathId,
      ],
      elements: {
        all: (learnpathId: string) => [
          ...QueryKeys.learnpaths.managed.detail(learnpathId),
          'elements',
        ],
        lists: (learnpathId: string) => [
          ...QueryKeys.learnpaths.managed.elements.all(learnpathId),
          'list',
        ],
        list: (learnpathId: string) => [
          ...QueryKeys.learnpaths.managed.elements.lists(learnpathId),
        ],
        details: (learnpathId: string) => [
          ...QueryKeys.learnpaths.managed.elements.all(learnpathId),
          'detail',
        ],
        detail: (
          learnpathId: string,
          elementType: LearnpathElementType,
          elementId: string,
        ) => [
          ...QueryKeys.learnpaths.managed.elements.details(learnpathId),
          elementType,
          elementId,
        ],
      },
      userGroups: (learnpathId: string) => [
        ...QueryKeys.learnpaths.managed.detail(learnpathId),
        'user_groups',
      ],
      members: (learnpathId: string) => [
        ...QueryKeys.learnpaths.managed.detail(learnpathId),
        'members',
      ],
    },
    uploadsAsset: (assetType?, assetId?) =>
      compact(['learnpaths', 'uploads', assetType, assetId]),
    assigned: (learnpathId?: string) =>
      compact(['learnpaths', 'assigned', learnpathId]),
    assignedElements: (learnpathId: string) => [
      ...QueryKeys.learnpaths.assigned(learnpathId),
      'elements',
    ],
    assignedElement: (learnpathId: string, elementId?: string) =>
      compact([
        ...QueryKeys.learnpaths.assignedElements(learnpathId),
        elementId,
      ]),
  },
  users: {
    groups: () => ['users', 'groups'],
    search: (query: string) => ['users', 'search', query],
  },
  glossary: {
    list: () => ['glossary'],
  },
  scorm: {
    state: (packageId: string) => ['scorm', 'state', packageId],
  },
  events: {
    all: ['events'],
    lists: () => [...QueryKeys.events.all, 'list'],
    list: (query: Record<string, any> = {}) => [
      ...QueryKeys.events.lists(),
      query,
    ],
    details: () => [...QueryKeys.events.all, 'detail'],
    detail: (eventId: string) => [...QueryKeys.events.details(), eventId],
    attendees: (eventId: string) => [
      ...QueryKeys.events.detail(eventId),
      'attendees',
    ],
  },
  extended: {
    youtubeVideo: (videoId: string) => ['youtube_video', videoId],
  },
}
