import {
  every,
  filter,
  findIndex,
  first,
  fromPairs,
  get,
  getOr,
  identity,
  includes,
  isNumber,
  keys,
  last,
  map,
  mapValues,
  merge,
  min,
  negate,
  omit,
  pickBy,
  pipe,
  rest,
  spread,
  values,
} from 'lodash/fp'
import { createSelector } from 'reselect'

import { passProps } from '@masterplandev/utils'

import { URL_SUFFIX_QUIZ } from '@/core/constants/constants'
import buildUniversalLectureApiUrl from '@/lecture/utils/buildUniversalLectureApiUrl'

export const quizSelector = ({ quiz }, { match }) => {
  return getOr(
    {},
    buildUniversalLectureApiUrl(match.params, { suffix: URL_SUFFIX_QUIZ }),
    quiz,
  )
}

export const metaSelector = createSelector(
  quizSelector,
  omit(['data', 'result']),
)
export const dataSelector = createSelector(quizSelector, getOr({}, 'data'))
export const nextDataSelector = createSelector(
  quizSelector,
  getOr({}, 'next_data'),
)
export const colorSelector = createSelector(dataSelector, get('color'))

export const quizIdSelector = createSelector(dataSelector, get('id'))

export const progressSelector = createSelector(
  dataSelector,
  getOr({}, 'progress'),
)

export const completedSelector = createSelector(
  progressSelector,
  get('completed'),
)
export const questionHintSelector = createSelector(
  progressSelector,
  get('current_question_hint'),
)
export const allAnswersHintSelector = createSelector(
  progressSelector,
  get('current_answers_hints'),
)
export const answerHintSelector = createSelector(
  [allAnswersHintSelector, passProps],
  (hints, { id }) => get(id, hints),
)

export const previousDataSelector = createSelector(
  quizSelector,
  getOr({}, 'previous_data'),
)
export const previousProgressSelector = createSelector(
  previousDataSelector,
  getOr({}, 'progress'),
)
export const previousQuestionSelector = createSelector(
  previousProgressSelector,
  getOr({}, 'current_question'),
)

export const currentOrPreviousProgressSelector = createSelector(
  [progressSelector, previousProgressSelector],
  (progress, previous) => (progress.current_question ? progress : previous),
)

export const currentQuestionSelector = createSelector(
  currentOrPreviousProgressSelector,
  getOr({}, 'current_question'),
)
export const currentQuestionIdSelector = createSelector(
  currentQuestionSelector,
  get('id'),
)
export const canSkipCurrentQuestionSelector = createSelector(
  progressSelector,
  getOr(false, 'can_skip_current_question'),
)
export const currentAttemptSelector = createSelector(
  currentOrPreviousProgressSelector,
  get('current_attempt'),
)

export const displayQuestionSelector = createSelector(
  [completedSelector, previousQuestionSelector, currentQuestionSelector],
  (completed, previous, current) => (completed ? previous : current),
)

export const scoringSelector = createSelector(
  currentQuestionSelector,
  getOr([], 'scoring'),
)

export const currentPointsSelector = createSelector(
  [currentAttemptSelector, scoringSelector],
  (attempt, scoring) => getOr(last(scoring), attempt, scoring),
)

export const lastPointsSelector = createSelector(scoringSelector, last)

export const previousResultQuestionSelector = createSelector(
  quizSelector,
  get('previous_result'),
)
export const resultQuestionSelector = createSelector(
  quizSelector,
  get('result'),
)

export const displayResultQuestionSelector = createSelector(
  [completedSelector, previousResultQuestionSelector, resultQuestionSelector],
  (completed, previous, current) => (completed ? previous : current),
)

export const questionStepsSelector = createSelector(quizSelector, get('steps'))

export const quizTotalScoreSelector = createSelector(
  [dataSelector, nextDataSelector],
  rest(pipe([spread(merge), getOr(0, 'progress.total_score')])),
)

export const completedStepsAnswersSelector = createSelector(
  questionStepsSelector,
  pipe([pickBy(get('completed')), keys]),
)

export const questionStepAnswerSelector = createSelector(
  [currentQuestionSelector, completedStepsAnswersSelector],
  (question, completedSteps) => {
    const answers = get('answers', question)
    const answersToDo = filter(
      ({ id }) => !includes(id, completedSteps),
      answers,
    )
    const answer = first(answersToDo)
    if (!answer) {
      return null
    }

    return {
      ...answer,
      // @ts-ignore Not sure if it works, but not to change anything I leave it as it is.
      index: findIndex(answer, answers) + 1,
    }
  },
)

export const questionStepSelector = createSelector(
  questionStepsSelector,
  pipe([filter(negate(get('completed'))), first]),
)

export const questionStepRequestSelector = createSelector(
  questionStepSelector,
  get('request'),
)
export const questionStepResponseSelector = createSelector(
  questionStepSelector,
  get('response'),
)

export const resultStepsQuestionSelector = createSelector(
  questionStepsSelector,
  mapValues(get('response.correct')),
)

export const questionCombineStepsSelector = createSelector(
  questionStepsSelector,
  pipe([
    values,
    map(get('request')),
    filter({ answer_value: true }),
    map(get('answer_id')),
  ]),
)

export const totalQuestionsNoSelector = createSelector(
  dataSelector,
  get('num_questions'),
)

export const currentQuestionNoSelector = createSelector(
  progressSelector,
  totalQuestionsNoSelector,
  ({ num_answered_questions: x }, questionsNumber) =>
    min([isNumber(x) ? x + 1 : x, questionsNumber]),
)

export const requiresFetchSelector = createSelector(
  [metaSelector, dataSelector, passProps],
  ({ fetched, failed, fetching }, { slug }, { match: { params } }) =>
    every(identity, [!fetching, !fetched, !failed, slug !== params.lecture]),
)

export const fetchingSelector = createSelector(
  metaSelector,
  getOr(false, 'fetching'),
)

export const initialValuesSelector = createSelector(
  passProps,
  pipe([get('answers'), map(({ id }) => [id, false]), fromPairs]),
)
