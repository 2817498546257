import { handleActions } from 'redux-actions'

import assignedReducer from './reducers/assigned.reducer'
import joinReducer from './reducers/join.reducer'
import lecturesReducer from './reducers/lectures.reducer'
import progressReducer from './reducers/progress.reducer'

export default handleActions<any>(
  {
    ...lecturesReducer,
    ...assignedReducer,
    ...progressReducer,
    ...joinReducer,
  },
  {},
)
