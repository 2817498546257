import { mapValues, omit } from 'lodash/fp'

import { mapValuesWithKey } from '@masterplandev/utils'

import {
  FETCHING,
  REDUCER_FORM_KEY,
  REDUCER_SIGNUP_KEY,
} from '../../constants/constants'

/**
 * Function makes sure that redux store retrieved from localStorage doesn't have
 * temporary state keys as `submitting` or `fetching` set to true.
 *
 * @param {Function} next - next function to call from middlewares
 *
 * @returns {object} Final redux state
 *
 * @example
 * const enhancer = composeWithDevTools(
 *   applyMiddleware(...middlewares),
 *   validateReduxState,
 * )
 */
export const validateReduxState =
  (next) => (reducer, initialState, enhancer) => {
    const validState = mapValuesWithKey((state, key) => {
      if (key === REDUCER_FORM_KEY) {
        return mapValues(omit('submitting'), state)
      }

      if (key === REDUCER_SIGNUP_KEY) {
        return omit(FETCHING, state)
      }

      return state
    })(initialState)

    return next(reducer, validState, enhancer)
  }

export default validateReduxState
