import { PropsWithChildren } from 'react'

import useShallowEqualSelector from '@/core/hooks/useShallowEqualSelector'
import { FeatureProvider } from '@/core/utils/features/FeatureProvider'
import { featuresSelector } from '@/user/selectors'

export function FeaturesProvider({ children }: PropsWithChildren) {
  const features = useShallowEqualSelector(featuresSelector)

  return <FeatureProvider features={features}>{children}</FeatureProvider>
}
