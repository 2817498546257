import { useKeycloak } from '@react-keycloak/web'

import { createBrowserSessionUrl } from '../utils/browserSessionService'

type UseAccessTokenParams = {
  access: string
  refresh: string
}

/**
 * Use access token in url to authenticate users.
 */
export function useAccessToken(
  params: UseAccessTokenParams = {
    access: 'access_token',
    refresh: 'refresh_token',
  },
) {
  const { keycloak } = useKeycloak()

  const currentUrl = new URL(window.location.href)
  const accessToken = currentUrl.searchParams.get(params.access)
  const refreshToken = currentUrl.searchParams.get(params.refresh)

  return {
    accessToken,
    refreshToken,
    createLoginUrl: (token: string) => {
      currentUrl.searchParams.delete(params.access)
      currentUrl.searchParams.delete(params.refresh)

      const loginUrl = keycloak.createLoginUrl({
        redirectUri: currentUrl.toString(),
      })

      return createBrowserSessionUrl(keycloak, token, loginUrl)
    },
  }
}
