import { createActions } from 'redux-actions'

const actions = createActions({
  CORE: {
    SCROLL_LISTENER: {
      SET_THRESHOLD: (name, threshold) => ({
        name,
        threshold,
      }),
    },
  },
})

export default actions.core
