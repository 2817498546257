import { handleActions } from 'redux-actions'

import lecturesReducer from '@/lecture/reducers/lectures.reducer'

import addPlatformTopicReducer from './reducers/addPlatformTopic.reducer'
import addUsersReducer from './reducers/addUsers.reducer'
import overviewReducer from './reducers/overview.reducer'
import userGroups from './reducers/userGroups.reducer'

export default handleActions(
  {
    ...lecturesReducer,
    ...addPlatformTopicReducer,
    ...addUsersReducer,
    ...overviewReducer,
    ...userGroups,
  },
  {},
)
