import { useRef, useState } from 'react'
import { useIntersection, useLocation, useWindowScroll } from 'react-use'

import { EVENT_PAGE_BOTTOM_REACHED, eventTracker } from '@masterplandev/utils'

import urlToPagename from '../../utils/urlToPagename'

export const PageBottomSensor = () => {
  const scrollPosition = useWindowScroll()
  const [hasIntersectedOnce, setHasIntersectedOnce] = useState(false)
  const intersectionRef = useRef(null)
  const { pathname } = useLocation()

  const intersection = useIntersection(intersectionRef, {
    threshold: 1,
    root: null,
    rootMargin: '10px',
  })

  if (scrollPosition.y > 0 && !hasIntersectedOnce) {
    if (intersection?.isIntersecting) {
      eventTracker.track(EVENT_PAGE_BOTTOM_REACHED, {
        origin_page: urlToPagename(pathname),
      })
      setHasIntersectedOnce(true)
    }

    return <div id="page bottom sensor" ref={intersectionRef} />
  }

  return null
}

export default PageBottomSensor
