import { curry, isUndefined } from 'lodash/fp'
import { createSelectorCreator, defaultMemoize } from 'reselect'

import { getWithFallback } from '@masterplandev/utils'

/**
 * Selector creator used to compare input values equality by their inner
 * properties.
 *
 * @param {object} options - Options to configure creator.
 * @param {Array} options.identifiers - List of the properties by which the
 * input values will be compared.
 *
 * @returns {boolean}
 * Boolean value indicating whether the input value is equal to the old one.
 *
 * @example
 * return createMemoizeByIdentifierSelector({
 *    identifiers: ['id', 'fallbackId'],
 *  })(someDataSelector, (someData) => ({
 *      complexField: someData.complexField,
 *  })
 */
export default curry(({ identifiers }) => {
  return createSelectorCreator(defaultMemoize, (prev, curr) => {
    const prevIdentifier = getWithFallback(identifiers, prev)
    const currIdentifier = getWithFallback(identifiers, curr)

    if (isUndefined(prevIdentifier) || isUndefined(currIdentifier)) {
      return prev === curr
    }

    return prevIdentifier === currIdentifier
  })
})
