import { useQuery } from '@tanstack/react-query'

import { useAuth } from '@/auth/hooks/useAuth'
import {
  FEATURE_SIGNUP_PRIVACY_POLICY,
  FEATURE_SIGNUP_PRIVACY_POLICY_VOLKSWAGEN,
} from '@/core/constants/features'
import useFeatures from '@/core/utils/features/useFeatures'
import { usersQueries } from '@/http/api/users'

export function useCurrentUserPrivacyPolicyStatus() {
  const { isAuthenticated } = useAuth()
  const hasPrivacyPolicyFeature = useFeatures(FEATURE_SIGNUP_PRIVACY_POLICY)
  const hasPrivacyPolicyVolkswagenFeature = useFeatures(
    FEATURE_SIGNUP_PRIVACY_POLICY_VOLKSWAGEN,
  )

  return useQuery({
    ...usersQueries.usersCurrentDetails(),
    enabled: isAuthenticated,
    select: (data) => {
      if (data.extra_data?.signup?.privacy_policy_accepted === true) {
        return 'accepted'
      }

      if (hasPrivacyPolicyFeature || hasPrivacyPolicyVolkswagenFeature) {
        return 'required'
      }

      return 'not-required'
    },
  })
}
