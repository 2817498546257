export const isLocalStorageEnabled = () => {
  const key = 'test'
  try {
    localStorage.setItem(key, key)
    localStorage.removeItem(key)
    return true
  } catch (e) {
    return false
  }
}

export default isLocalStorageEnabled
