import {
  find,
  get,
  getOr,
  includes,
  intersection,
  isEmpty,
  map,
  negate,
  pipe,
  size,
  toArray,
  toNumber,
} from 'lodash/fp'
import createCachedSelector from 're-reselect'
import { Selector, createSelector } from 'reselect'

import { passProps } from '@masterplandev/utils'

import { UserGroup } from '@/api/generated-api-and-types'
import { USER_ROLES } from '@/core/constants/constants'
import {
  FEATURE_LEARNPATH,
  FEATURE_LEARNPATH_STATS_DEFAULT_COMPANY_ADMIN,
  FEATURE_LEARNPATH_STATS_DEFAULT_MANAGER,
  FEATURE_LEARNPATH_STATS_DEFAULT_OWNER,
  FEATURE_LEARNPATH_STATS_MANDATORY_COMPANY_ADMIN,
  FEATURE_LEARNPATH_STATS_MANDATORY_MANAGER,
  FEATURE_LEARNPATH_STATS_MANDATORY_OWNER,
} from '@/core/constants/features'
import { generateBasicSelectors } from '@/core/selectors'
import cachedKeyCreator from '@/core/utils/redux/cachedKeyCreator'
import { createIsFeatureSelector, rolesSelector } from '@/user/selectors'

import { FILTER_USE_ALL, FILTER_USE_SELECTED } from '../constants'
import { LearnpathsState } from '../types/LearnpathsState'
import * as buildApiUrl from '../utils/buildApiUrl'

export const rootSelector: Selector<
  LearnpathsState,
  LearnpathsState['learnpaths']
> = get('learnpaths')

export const isLearnpathFeature = createIsFeatureSelector(FEATURE_LEARNPATH)

export const isLearnpathStatsDefaultOwnerFeature = createIsFeatureSelector(
  FEATURE_LEARNPATH_STATS_DEFAULT_OWNER,
)
export const isLearnpathStatsDefaultManagerFeature = createIsFeatureSelector(
  FEATURE_LEARNPATH_STATS_DEFAULT_MANAGER,
)
export const isLearnpathStatsDefaultCompanyAdminFeature =
  createIsFeatureSelector(FEATURE_LEARNPATH_STATS_DEFAULT_COMPANY_ADMIN)

export const isLearnpathStatsMandatoryOwnerFeature = createIsFeatureSelector(
  FEATURE_LEARNPATH_STATS_MANDATORY_OWNER,
)
export const isLearnpathStatsMandatoryManagerFeature = createIsFeatureSelector(
  FEATURE_LEARNPATH_STATS_MANDATORY_MANAGER,
)
export const isLearnpathStatsMandatoryCompanyAdminFeature =
  createIsFeatureSelector(FEATURE_LEARNPATH_STATS_MANDATORY_COMPANY_ADMIN)

export const isLearnpathCreatorAvailableSelectors = createSelector(
  [rolesSelector, isLearnpathFeature],
  (roles, isFeature) =>
    isFeature &&
    pipe(
      intersection([
        USER_ROLES.owner,
        USER_ROLES.manager,
        USER_ROLES.admin,
        USER_ROLES.company_administrator,
      ]),
      negate(isEmpty),
    )(roles),
)

export const isLearnpathStatsDefaultDownloadAvailableSelector = createSelector(
  [
    rolesSelector,
    isLearnpathStatsDefaultOwnerFeature,
    isLearnpathStatsDefaultManagerFeature,
    isLearnpathStatsDefaultCompanyAdminFeature,
  ],
  (
    roles,
    statsForDefaultOwner,
    statsForDefaultManager,
    statsForDefaultCompanyAdmin,
  ) =>
    (includes(USER_ROLES.owner, roles) && statsForDefaultOwner) ||
    (includes(USER_ROLES.manager, roles) && statsForDefaultManager) ||
    (includes(USER_ROLES.company_administrator, roles) &&
      statsForDefaultCompanyAdmin) ||
    includes(USER_ROLES.admin, roles),
)

export const isLearnpathStatsMandatoryDownloadAvailableSelector =
  createSelector(
    [
      rolesSelector,
      isLearnpathStatsMandatoryOwnerFeature,
      isLearnpathStatsMandatoryManagerFeature,
      isLearnpathStatsMandatoryCompanyAdminFeature,
    ],
    (
      roles,
      statsForMandatoryOwner,
      statsForMandatoryManager,
      statsForMandatoryCompanyAdmin,
    ) =>
      (includes(USER_ROLES.owner, roles) && statsForMandatoryOwner) ||
      (includes(USER_ROLES.manager, roles) && statsForMandatoryManager) ||
      (includes(USER_ROLES.company_administrator, roles) &&
        statsForMandatoryCompanyAdmin) ||
      includes(USER_ROLES.admin, roles),
  )

export const learnpathSelector = createCachedSelector(
  [rootSelector, passProps],
  (root, { learnpathId }) => root[buildApiUrl.managed(learnpathId)] ?? {},
)(cachedKeyCreator)

export const isLearnpathModifiedSelector = createSelector(
  learnpathSelector,
  getOr(false, 'modified'),
)

export const learnpathFiltersSelector = createSelector(
  learnpathSelector,
  getOr([], 'filters'),
)

export const isFetchAllUsersFilterOnSelector = createSelector(
  learnpathFiltersSelector,
  includes(FILTER_USE_ALL),
)

export const isSelectedUsersFilterOnSelector = createSelector(
  learnpathFiltersSelector,
  includes(FILTER_USE_SELECTED),
)

export const learnpathMembersSelectors = generateBasicSelectors(
  createCachedSelector(
    [rootSelector, passProps],
    (root, { learnpathId }: { learnpathId: string }) =>
      getOr({}, buildApiUrl.members(learnpathId), root),
  )((_, { learnpathId }) => learnpathId),
)

export const learnpathMembersListSelector = createSelector(
  learnpathMembersSelectors.data,
  getOr([], 'members'),
)

export const learnpathMembersIdsSelector = createSelector(
  learnpathMembersListSelector,
  map('id'),
)

export const candidatesSelectors = generateBasicSelectors(
  createCachedSelector(
    [rootSelector, passProps],
    (root, { learnpathId }: { learnpathId: string }) =>
      get(buildApiUrl.candidates(learnpathId), root),
  )(cachedKeyCreator),
)

export const possibleCandidatesCountSelector = createSelector(
  candidatesSelectors.data,
  getOr(0, 'num_all_students'),
)

export const proposedCandidatesSelector = createSelector(
  candidatesSelectors.data,
  get('candidates'),
)

export const proposedCandidatesCountSelector = createSelector(
  proposedCandidatesSelector,
  size,
)

export const matchingCandidatesSelector = createSelector(
  candidatesSelectors.data,
  get('members'),
)

export const selectedCandidatesSelector = createSelector(
  candidatesSelectors.root,
  getOr([], 'selected'),
)

export const selectedCandidatesIdsSelector = createSelector(
  selectedCandidatesSelector,
  map('id'),
)

export const selectedCandidatesCountSelector = createSelector(
  selectedCandidatesSelector,
  size,
)

export const filteredCandidatesSelector = createSelector(
  proposedCandidatesSelector,
  selectedCandidatesSelector,
  matchingCandidatesSelector,
  learnpathFiltersSelector,
  (proposed, selected, matching, filters) => {
    if (includes(FILTER_USE_SELECTED, filters)) {
      return selected
    }

    return [...toArray(proposed), ...toArray(matching)]
  },
)

export const platformTopicsRootSelectors = generateBasicSelectors(
  createSelector(
    [rootSelector, passProps],
    (root, { learnpathId }: { learnpathId: string }) =>
      getOr({}, buildApiUrl.platformTopics(learnpathId), root),
  ),
)

export const platformTopicsSelector = createSelector(
  platformTopicsRootSelectors.data,
  getOr([], 'topics'),
)

export const platformTopicsCountSelector = createSelector(
  platformTopicsRootSelectors.data,
  getOr(0, 'total_count'),
)

export const platformTopicsFiltersSelector = createSelector(
  platformTopicsRootSelectors.data,
  getOr({}, 'filters'),
)

export const platformTopicsIdsSelector = createSelector(
  platformTopicsSelector,
  map('id'),
)

export const platformTopicSelector = createSelector(
  [platformTopicsSelector, passProps],
  (topics, { topicId }) => find(['id', toNumber(topicId)], topics),
)

export const selectedPlatformTopicsSelector = createSelector(
  platformTopicsRootSelectors.root,
  getOr([], 'selected'),
)

export const platformTopicsLoadingSelector = createSelector(
  platformTopicsRootSelectors.root,
  get('fetching'),
)

export const selectedPlatformTopicLecturesIdsSelector = createSelector(
  [selectedPlatformTopicsSelector, passProps],
  (topics, { topicId }) =>
    pipe([find(['id', topicId]), get('selectedLectures'), map('id')])(topics),
)

export const selectedPlatformTopicLecturesCountSelector = createSelector(
  [selectedPlatformTopicsSelector, passProps],
  (topics, { topicId }) =>
    pipe([find(['id', topicId]), get('selectedLectures'), size])(topics),
)

export const platformTopicCatalogLectureSelector = createSelector(
  [platformTopicSelector, passProps],
  (topic, { lectureId }) =>
    pipe([get('lectures'), find(['id', toNumber(lectureId)])])(topic),
)

export const learnpathUserGroupsSelectors = generateBasicSelectors(
  createCachedSelector(
    [rootSelector, passProps],
    (root, { learnpathId }: { learnpathId: string }) =>
      getOr({}, buildApiUrl.userGroups(learnpathId), root),
  )((_, { learnpathId }) => learnpathId),
)

export const learnpathUserGroupsListSelector = createSelector(
  learnpathUserGroupsSelectors.data,
  getOr([], 'user_groups'),
)

export const learnpathUserGroupItemSelector = createSelector(
  [learnpathUserGroupsListSelector, passProps],
  (groups, { userGroupId }) => find(['id', userGroupId], groups),
)

export const learnpathUserGroupIsAssignedSelector = createSelector(
  learnpathUserGroupItemSelector,
  negate(isEmpty),
)

export const learnpathUserGroupsCounterSelector = createSelector(
  learnpathUserGroupsListSelector,
  size,
)

export const learnpathUserGroupsSelected = createSelector(
  rootSelector,
  getOr({} as Record<string, UserGroup>, 'userGroups'),
)

export const learnpathUserGroupsSelectedIdsSelector = createSelector(
  [learnpathUserGroupsSelected, passProps],
  (learnpaths, { learnpathId }) => learnpaths[learnpathId],
)

export const learnpathUserGroupsSelectedCountSelector = createSelector(
  learnpathUserGroupsSelectedIdsSelector,
  size,
)

export const learnpathUserGroupsSelectedHasId = createSelector(
  [learnpathUserGroupsSelectedIdsSelector, passProps],
  (userGroupIds, { userGroupId }) => includes(userGroupId, userGroupIds),
)
