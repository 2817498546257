import {
  add,
  filter,
  getOr,
  has,
  map,
  max,
  merge,
  pipe,
  values,
} from 'lodash/fp'
import { combineActions, handleActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import actions from '@/actions'
import {
  API_URL_LEARNGROUP,
  API_URL_LEARNGROUP_LEADERBOARD,
  API_URL_LEARNGROUP_SCORING,
} from '@/core/constants/constants'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

export const [GET_REQUEST, GET_SUCCESS, GET_ERROR] = getActionTypes({
  type: actions.learngroup.get,
})

export const [POST_REQUEST, POST_SUCCESS, POST_ERROR] = getActionTypes({
  type: actions.learngroup.post,
})

export const [PUT_REQUEST, PUT_SUCCESS, PUT_ERROR] = getActionTypes({
  type: actions.learngroup.put,
})

export const [_, PUT_MEMBERSHIP_SUCCESS] = getActionTypes({
  type: actions.learngroup.membership.put,
})

export const [DELETE_MEMBERSHIP_REQUEST, DELETE_MEMBERSHIP_SUCCESS] =
  getActionTypes({
    type: actions.learngroup.membership.delete,
  })

export const [
  RESEND_MEMBERSHIP_REQUEST,
  RESEND_MEMBERSHIP_SUCCESS,
  RESEND_MEMBERSHIP_ERROR,
] = getActionTypes({
  type: actions.learngroup.membership.resend,
})

export const [LEADERBOARD_REQUEST, LEADERBOARD_SUCCESS, LEADERBOARD_ERROR] =
  getActionTypes({
    type: actions.learngroup.leaderboard.get,
  })

export const [SCORING_REQUEST, SCORING_SUCCESS, SCORING_ERROR] = getActionTypes(
  {
    type: actions.learngroup.scoring.get,
  },
)

const REQUEST_ACTIONS = combineActions(
  GET_REQUEST,
  POST_REQUEST,
  PUT_REQUEST,
  LEADERBOARD_REQUEST,
  SCORING_REQUEST,
  RESEND_MEMBERSHIP_REQUEST,
)
const SUCCESS_ACTIONS = combineActions(
  GET_SUCCESS,
  POST_SUCCESS,
  PUT_SUCCESS,
  LEADERBOARD_SUCCESS,
  SCORING_SUCCESS,
  RESEND_MEMBERSHIP_SUCCESS,
)
const ERROR_ACTIONS = combineActions(
  GET_ERROR,
  POST_ERROR,
  PUT_ERROR,
  LEADERBOARD_ERROR,
  SCORING_ERROR,
  RESEND_MEMBERSHIP_ERROR,
)

const CLEAR_LEARNGROUP_ACTIONS = combineActions(
  PUT_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_SUCCESS,
)

const CLEAR_LEADERBOARD_ACTIONS = combineActions(
  actions.learngroup.leaderboard.clear,
  actions.quiz.clearResult,
)

const CLEAR_LEARNGROUP_SCORING_ACTIONS = combineActions(
  actions.learngroup.scoring.clear,
  actions.quiz.clearResult,
  POST_SUCCESS,
  PUT_MEMBERSHIP_SUCCESS,
)

const RESEND_MEMBERSHIP_FINISHED = combineActions(
  RESEND_MEMBERSHIP_SUCCESS,
  RESEND_MEMBERSHIP_ERROR,
)

const initialState = {}

export default handleActions(
  {
    [REQUEST_ACTIONS]: mergeRequestAction,
    [SUCCESS_ACTIONS]: mergeSuccessAction,
    [ERROR_ACTIONS]: mergeErrorAction,
    [CLEAR_LEARNGROUP_ACTIONS]: (state) =>
      merge(state, {
        [API_URL_LEARNGROUP]: { fetched: false, failed: false },
      }),
    [CLEAR_LEADERBOARD_ACTIONS]: (state) =>
      merge(state, {
        [API_URL_LEARNGROUP_LEADERBOARD]: { fetched: false, failed: false },
      }),
    [CLEAR_LEARNGROUP_SCORING_ACTIONS]: (state) =>
      merge(state, {
        [API_URL_LEARNGROUP_SCORING]: { fetched: false, failed: false },
      }),
    [DELETE_MEMBERSHIP_REQUEST]: (state) =>
      merge(state, {
        [API_URL_LEARNGROUP]: { fetching: true },
        [API_URL_LEARNGROUP_SCORING]: { fetching: true },
      }),
    [DELETE_MEMBERSHIP_SUCCESS]: (state) =>
      merge(state, {
        [API_URL_LEARNGROUP]: { fetching: false, fetched: false, data: null },
        [API_URL_LEARNGROUP_SCORING]: {
          fetching: false,
          fetched: false,
          data: null,
        },
      }),
    [RESEND_MEMBERSHIP_REQUEST]: (state, { payload: { request, meta } }) =>
      merge(state, {
        [request.url]: { resendMembershipId: meta.id },
      }),
    [RESEND_MEMBERSHIP_FINISHED]: (state, { meta }) =>
      merge(state, {
        [meta.previousAction.payload.request.url]: {
          order: pipe([
            values,
            filter(has('resendMembershipId')),
            map(getOr(0, 'order')),
            max,
            add(1),
          ])(state),
        },
      }),
  },
  initialState,
)
