import { curry, isPlainObject, mergeAllWith } from 'lodash/fp'

export const keepRefCustomizer = (targetValue, sourceValue) => {
  if (isPlainObject(targetValue) && isPlainObject(sourceValue)) {
    return mergeAllWith(keepRefCustomizer, [{}, targetValue, sourceValue])
  }

  return sourceValue
}

/* This merge function is designed specifically to be used with redux store
 * as it dos not change references of unmodified objects and as a consequence
 * re-renders are not triggered. */
const mergeState = curry((targetObject, sourceObject) => {
  return mergeAllWith(keepRefCustomizer, [{}, targetObject, sourceObject])
})

export default mergeState
