import { PropsWithChildren } from 'react'

import { Center } from '@masterplandev/ui'

import { AuthProvider } from '@/auth/components/AuthProvider/AuthProvider'
import Notification, {
  NOTIFICATION_ERROR,
} from '@/core/components/Notification'
import Loader from '@/core/components/ui/Loader/Loader'
import { inIframe } from '@/core/utils/inIframe'

import useScormAuth from '../../hooks/useScormAuth'
import { ScormIframeWarning } from '../ScormIframeWarning/ScormIframeWarning'

export function ScormAuthProvider({ children }: PropsWithChildren<object>) {
  const scormAuth = useScormAuth()

  // scorm can only be launched in an iframe
  if (!inIframe()) {
    return <ScormIframeWarning />
  }

  if (scormAuth.error) {
    return (
      <Notification
        type={NOTIFICATION_ERROR}
        id={scormAuth.error.message || 'scorm.error.empty'}
      />
    )
  }

  if (!scormAuth.fetched) {
    return (
      <Center position="fixed" inset="0">
        <Loader />
      </Center>
    )
  }

  return (
    <AuthProvider
      initOptions={{
        token: scormAuth.data?.access_token,
        refreshToken: scormAuth.data?.refresh_token,
        checkLoginIframe: false,
      }}>
      {children}
    </AuthProvider>
  )
}
