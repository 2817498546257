import { fill } from '@cloudinary/url-gen/actions/resize'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import { identity, sortBy } from 'lodash/fp'
import queryString from 'query-string'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Box, Flex, Icon } from '@masterplandev/ui'

import { CloudinaryImage } from '@/cloudinary/CloudinaryImage'
import { cloudinary } from '@/cloudinary/cloudinary'
import { localeSelector, supportedLocaleSelector } from '@/locale/selectors'

import { URL_LOGIN } from '../../constants/constants'
import Dropdown from '../Dropdown'
import styles from './AnonymousPageNavigation.module.css'
import Logo from './assets/masterplan-logo.svg'

export function AnonymousPageNavigation() {
  const dispatch = useDispatch()
  const locale = useSelector(localeSelector)
  const supportedLocale = useSelector(supportedLocaleSelector)

  const handleLocaleChange = (newLocale: string) => {
    if (newLocale !== locale) {
      dispatch(push({ search: queryString.stringify({ lang: newLocale }) }))
    }
  }

  return (
    <Flex
      as="nav"
      w="full"
      margin="auto"
      h="78px"
      alignItems="center"
      justifyContent="space-between"
      px={{ base: '24px', md: '30px' }}
      maxW={{ base: '', md: '1440px' }}>
      <Link to="/">
        <Logo />
      </Link>
      <Flex>
        <Box
          lineHeight="23px"
          mr={5}
          fontWeight="bold"
          as="a"
          href={URL_LOGIN}
          transition="color 400ms"
          _hover={{ color: 'mpPrimary.base' }}>
          <FormattedMessage id="core.header.login" />
        </Box>
        <Dropdown
          dropdownClassName={styles.localesDropdown}
          contentClassName={styles.localesDropdownContent}
          trigger={
            <div className={styles.localesTrigger}>
              <CloudinaryImage
                className={styles.localesTriggerIcon}
                alt={`selected-${locale}`}
                image={cloudinary
                  .image(`system/languages/${locale}.png`)
                  .resize(fill(100, 100))}
              />
              <Icon symbol="arrows-chevron-down" ml={2} />
            </div>
          }>
          {sortBy(identity, supportedLocale).map((localeItem) => (
            <button
              key={localeItem}
              className={styles.localesButton}
              type="button"
              onClick={() => handleLocaleChange(localeItem)}>
              <CloudinaryImage
                className={classNames(styles.localesIcon, {
                  [styles.localesIconActive]: localeItem === locale,
                })}
                alt={localeItem}
                image={cloudinary
                  .image(`/system/languages/${localeItem}.png`)
                  .resize(fill(100, 100))}
              />
            </button>
          ))}
        </Dropdown>
      </Flex>
    </Flex>
  )
}
