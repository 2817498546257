import {
  cond,
  curry,
  find,
  get,
  identity,
  includes,
  isNil,
  pipe,
  placeholder,
  stubTrue,
} from 'lodash/fp'

import { takeAllAfter } from '@masterplandev/utils'

import { STATUS_STARTED, STATUS_UNLOCKED } from '@/core/constants/constants'

/**
 * Takes the list of lectures and returns the first unlocked or started,
 * which lie after the lecture with specified slug or id.
 *
 * @param {object} lecture - object containing slug, id or both
 * @param {Array} list - list of lectures
 *
 * @example
 * getFirstUnlockedOrStartedAfterElement({ slug: 'slug', id: 'id' }, [
 * { slug: 'lecture-1', progress: { status: STATUS_UNLOCKED } },
 * { slug: 'lecture-2' },
 * { slug: 'lecture-3' },
 * ]),
 * // => { slug: 'lecture-1', progress: { status: STATUS_UNLOCKED } }
 */
export default curry(({ slug, id }, list) => {
  const element = find({ slug }, list) ?? find({ id }, list)

  const isUnlockedOrStarted = pipe(
    get('progress.status'),
    includes(placeholder, [STATUS_UNLOCKED, STATUS_STARTED]),
  )

  return pipe(
    cond([
      [() => isNil(element), identity],
      [stubTrue, takeAllAfter(element)],
    ]),
    find(isUnlockedOrStarted),
  )(list)
})
