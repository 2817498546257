import { combineActions, handleActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import actions from '@/actions'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

const [GET_REQUEST, GET_SUCCESS, GET_ERROR] = getActionTypes({
  type: actions.channels.get,
})

const [GET_PERSONAL_REQUEST, GET_PERSONAL_SUCCESS, GET_PERSONAL_ERROR] =
  getActionTypes({
    type: actions.channels.getPersonal,
  })

const [GET_INTERNAL_REQUEST, GET_INTERNAL_SUCCESS, GET_INTERNAL_ERROR] =
  getActionTypes({
    type: actions.channels.getInternal,
  })

const [GET_NEW_TOPICS_REQUEST, GET_NEW_TOPICS_SUCCESS, GET_NEW_TOPICS_ERROR] =
  getActionTypes({
    type: actions.channels.getNewTopics,
  })

const [
  GET_BEST_TOPICS_REQUEST,
  GET_BEST_TOPICS_SUCCESS,
  GET_BEST_TOPICS_ERROR,
] = getActionTypes({
  type: actions.channels.getBestTopics,
})

const [
  GET_HIGHLIGHTED_TOPIC_REQUEST,
  GET_HIGHLIGHTED_TOPIC_SUCCESS,
  GET_HIGHLIGHTED_TOPIC_ERROR,
] = getActionTypes({
  type: actions.channels.getHighlightedTopic,
})

const initialState = {}

export default handleActions(
  {
    [combineActions(
      GET_REQUEST,
      GET_PERSONAL_REQUEST,
      GET_INTERNAL_REQUEST,
      GET_NEW_TOPICS_REQUEST,
      GET_BEST_TOPICS_REQUEST,
      GET_HIGHLIGHTED_TOPIC_REQUEST,
    )]: mergeRequestAction,
    [combineActions(
      GET_ERROR,
      GET_PERSONAL_ERROR,
      GET_INTERNAL_ERROR,
      GET_NEW_TOPICS_ERROR,
      GET_BEST_TOPICS_ERROR,
      GET_HIGHLIGHTED_TOPIC_ERROR,
    )]: mergeErrorAction,
    [combineActions(
      GET_SUCCESS,
      GET_PERSONAL_SUCCESS,
      GET_INTERNAL_SUCCESS,
      GET_NEW_TOPICS_SUCCESS,
      GET_BEST_TOPICS_SUCCESS,
      GET_HIGHLIGHTED_TOPIC_SUCCESS,
    )]: mergeSuccessAction,
    [actions.channels.clearAll]: () => initialState,
  },
  initialState,
)
