import { createActions } from 'redux-actions'

import buildS3FormData from './buildS3FormData'

const actions = createActions({
  CORE: {
    S3: {
      // action in cancelVideoUploadMiddleware
      POST: [
        ({ url, data, onUploadProgress, cancelToken }) => ({
          request: {
            url,
            method: 'POST',
            data: buildS3FormData(data),
            onUploadProgress,
            cancelToken: cancelToken.token,
          },
        }),
        ({ fileId, cancelToken }) => ({ fileId, cancelToken }),
      ],
      // action in cancelVideoUploadMiddleware
      CANCEL: (fileId) => fileId,
      PROGRESS: ({ fileId, progress, type }) => ({
        fileId,
        progress,
        type,
      }),
    },
  },
})

export default actions.core
