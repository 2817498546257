import { createActions } from 'redux-actions'

import * as buildApiUrl from '../utils/buildApiUrl'

const actions = createActions({
  LEARNPATHS: {
    MANAGED: {
      PLATFORM_TOPICS: {
        GET: (learnpathId, queryParams) => ({
          options: {
            returnRejectedPromiseOnError: false,
            url: buildApiUrl.platformTopics(learnpathId),
          },
          request: {
            url: buildApiUrl.platformTopics(learnpathId, queryParams),
          },
          queryParams,
        }),
        SELECT_LECTURE: (data, learnpathId) => ({
          data,
          options: {
            url: buildApiUrl.platformTopics(learnpathId),
          },
        }),
        SELECT_TOPIC_ALL_LECTURES: (data, learnpathId) => ({
          data,
          options: {
            url: buildApiUrl.platformTopics(learnpathId),
          },
        }),
        UNSELECT_LECTURE: (data, learnpathId) => ({
          data,
          options: {
            url: buildApiUrl.platformTopics(learnpathId),
          },
        }),
        UNSELECT_TOPIC_ALL_LECTURES: (data, learnpathId) => ({
          data,
          options: {
            url: buildApiUrl.platformTopics(learnpathId),
          },
        }),
        UNSELECT_ALL: (learnpathId) => ({
          options: {
            url: buildApiUrl.platformTopics(learnpathId),
          },
        }),
      },
    },
  },
})

export default actions.learnpaths
