import { useKeycloak } from '@react-keycloak/web'
import { camelCase, mapKeys } from 'lodash/fp'
import { Redirect } from 'react-router-dom'

import { URL_DASHBOARD } from '@/core/constants/constants'
import useSearchParams from '@/core/hooks/useSearchParams'

export function KeycloakLogin() {
  const { keycloak } = useKeycloak()
  const params = useSearchParams()

  const defaultRedirectUri = `${window?.location?.origin}${URL_DASHBOARD}`
  const {
    idpHint,
    redirectUri = defaultRedirectUri,
    locale,
  } = mapKeys(camelCase, params)

  if (keycloak.authenticated) {
    return <Redirect to={redirectUri} />
  }

  keycloak.login({ idpHint, redirectUri, locale })
  return null
}
