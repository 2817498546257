export const KEY_CODE_ENTER = 13
export const KEY_CODE_SPACE = 32

export const FILE_UPLOAD_MAX_FILE_SIZE_VIDEO = 1073741824 // 1GB

export const FILE_EXTENSION_PDF = 'pdf'
export const FILE_EXTENSION_DOC = 'doc'
export const FILE_EXTENSION_DOCX = 'docx'
export const FILE_EXTENSION_TXT = 'txt'
export const FILE_EXTENSION_XLS = 'xls'
export const FILE_EXTENSION_XLSX = 'xlsx'
export const FILE_EXTENSION_XLSM = 'xlsm'
export const FILE_EXTENSION_CSV = 'csv'
export const FILE_EXTENSION_PPT = 'ppt'
export const FILE_EXTENSION_PPTX = 'pptx'
export const FILE_EXTENSION_MP3 = 'mp3'
export const FILE_EXTENSION_WMA = 'wma'
export const FILE_EXTENSION_AAC = 'aac'
export const FILE_EXTENSION_MP4 = 'mp4'
export const FILE_EXTENSION_AVI = 'avi'
export const FILE_EXTENSION_WMV = 'wmv'
export const FILE_EXTENSION_MPG = 'mpg'
export const FILE_EXTENSION_MPEG = 'mpeg'
export const FILE_EXTENSION_MOV = 'mov'
export const FILE_EXTENSION_GIF = 'gif'
export const FILE_EXTENSION_RAR = 'rar'
export const FILE_EXTENSION_ZIP = 'zip'
export const FILE_EXTENSION_JPG = 'jpg'
export const FILE_EXTENSION_JPEG = 'jpeg'
export const FILE_EXTENSION_PNG = 'png'

export const FILE_MIME_TYPE_PDF = 'application/pdf'
export const FILE_MIME_TYPE_DOC = 'application/msword'
export const FILE_MIME_TYPE_DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const FILE_MIME_TYPE_TXT = 'text/plain'
export const FILE_MIME_TYPE_XLS = 'application/vnd.ms-excel'
export const FILE_MIME_TYPE_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const FILE_MIME_TYPE_XLSM =
  'application/vnd.ms-excel.sheet.macroEnabled.12'
export const FILE_MIME_TYPE_CSV = 'text/csv'
export const FILE_MIME_TYPE_PPT = 'application/vnd.ms-powerpoint'
export const FILE_MIME_TYPE_PPTX =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
export const FILE_MIME_TYPE_MP3 = 'audio/mpeg'
export const FILE_MIME_TYPE_WMA = 'audio/x-ms-wma'
export const FILE_MIME_TYPE_AAC = 'audio/aac'
export const FILE_MIME_TYPE_MP4 = 'video/mp4'
export const FILE_MIME_TYPE_AVI = 'video/x-msvideo'
export const FILE_MIME_TYPE_WMV = 'video/x-ms-wmv'
export const FILE_MIME_TYPE_MPG = 'video/mpeg'
export const FILE_MIME_TYPE_MPEG = 'video/mpeg'
export const FILE_MIME_TYPE_MOV = 'video/quicktime'
export const FILE_MIME_TYPE_GIF = 'image/gif'
export const FILE_MIME_TYPE_RAR = 'application/vnd.rar'
export const FILE_MIME_TYPE_ZIP = 'application/zip'
export const FILE_MIME_TYPE_X_ZIP_COMPRESSED = 'application/x-zip-compressed'
export const FILE_MIME_TYPE_JPG = 'image/jpeg'
export const FILE_MIME_TYPE_JPEG = 'image/jpeg'
export const FILE_MIME_TYPE_PNG = 'image/png'

export const FILE_TYPES_VIDEO = {
  [FILE_EXTENSION_AVI]: FILE_MIME_TYPE_AVI,
  [FILE_EXTENSION_MPEG]: FILE_MIME_TYPE_MPEG,
  [FILE_EXTENSION_MOV]: FILE_MIME_TYPE_MOV,
  [FILE_EXTENSION_MP4]: FILE_MIME_TYPE_MP4,
}

export const FILE_TYPES_ASSET = {
  ...FILE_TYPES_VIDEO,
  [FILE_EXTENSION_PDF]: FILE_MIME_TYPE_PDF,
  [FILE_EXTENSION_DOC]: FILE_MIME_TYPE_DOC,
  [FILE_EXTENSION_DOCX]: FILE_MIME_TYPE_DOCX,
  [FILE_EXTENSION_TXT]: FILE_MIME_TYPE_TXT,
  [FILE_EXTENSION_XLS]: FILE_MIME_TYPE_XLS,
  [FILE_EXTENSION_XLSX]: FILE_MIME_TYPE_XLSX,
  [FILE_EXTENSION_XLSM]: FILE_MIME_TYPE_XLSM,
  [FILE_EXTENSION_CSV]: FILE_MIME_TYPE_CSV,
  [FILE_EXTENSION_PPT]: FILE_MIME_TYPE_PPT,
  [FILE_EXTENSION_PPTX]: FILE_MIME_TYPE_PPTX,
  [FILE_EXTENSION_MP3]: FILE_MIME_TYPE_MP3,
  [FILE_EXTENSION_WMA]: FILE_MIME_TYPE_WMA,
  [FILE_EXTENSION_AAC]: FILE_MIME_TYPE_AAC,
  [FILE_EXTENSION_WMV]: FILE_MIME_TYPE_WMV,
  [FILE_EXTENSION_MPG]: FILE_MIME_TYPE_MPG,
  [FILE_EXTENSION_GIF]: FILE_MIME_TYPE_GIF,
  [FILE_EXTENSION_RAR]: FILE_MIME_TYPE_RAR,
  [FILE_EXTENSION_ZIP]: FILE_MIME_TYPE_ZIP,
  [FILE_EXTENSION_JPG]: FILE_MIME_TYPE_JPG,
  [FILE_EXTENSION_JPEG]: FILE_MIME_TYPE_JPEG,
  [FILE_EXTENSION_PNG]: FILE_MIME_TYPE_PNG,
}
