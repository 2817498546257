import mergeState from './mergeState'

export const mergeRequestAction = (
  state,
  { payload: { request, options: actionOptions = {} }, options = {} },
  { url } = {},
) => {
  const urlToUse = url ?? options.url ?? actionOptions.url ?? request.url

  return mergeState(state, {
    [urlToUse]: {
      fetching: !options.ignoreResponse,
    },
  })
}

export default mergeRequestAction
