import * as React from 'react'
import type { ComponentType } from 'react'

function importErrorHandler(error: string): {
  default: ComponentType<any>
} {
  // Get the last reload time from local storage and the current time
  const storedTime = sessionStorage.getItem('last-reload')
  const time = storedTime ? Number(storedTime) : null
  const now = Date.now()

  // If the last reload time is more than 10 seconds ago, reload the page
  const msSinceLastCheck = 10_000
  const isReloading = !time || time + msSinceLastCheck < now
  if (isReloading) {
    console.info('New version for this module found. Reloading ...')
    sessionStorage.setItem('last-reload', String(now))
    window.location.reload()
    return { default: () => null }
  }

  // We let ErrorBoundary handle the error
  throw new Error(error)
}

/**
 * When our React application is compiled for production, packaging tools (Vite in this case) often add hashes to the filename to handle caching.
 * These hashes change whenever the content of the file changes. This ensures that users get the most recent version of the file, since a change in the hash will force the browser to download the new file instead of serving a cached version.
 *
 * If a user has your application open and you make a new deployment, the file names of the dynamically loaded modules may change (due to the new hashes).
 * And then, if the user navigates to a part of your application that requires loading a new module, their browser will attempt to load the module using the old file name (which is in the previously loaded code), which will result in an error as that file no longer exists on the server.
 *
 * The solution:
 * A wrap for React.Lazy that reloads the page if the module is not found.
 * If before 10 seconds, the error appears again, we assume it is not an error related to this issue and we throw the error up, letting
 * React Error Boundaries manage it.
 *
 * Based on: https://gist.github.com/ivanbtrujillo/005db52e4a8ef8fd1dc8f2db3b442d45
 *
 * @example
 * const StudentApp = lazyLoad(async () => ({
 *   default: (await import('./StudentApp')).StudentApp,
 * }))
 */
export const lazyLoad = (
  factory: () => Promise<{ default: ComponentType<any> }>,
) => {
  return React.lazy(() => factory().catch(importErrorHandler))
}
