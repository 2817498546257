import { KnockProvider } from '@knocklabs/react'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { useActivityKnockUserTokenDetails } from '@/activity/queries/useActivityKnockUserTokenDetails'
import { useAuth } from '@/auth/hooks/useAuth'
import { env } from '@/env'

import { idSelector } from '../../../selectors'

interface NotificationsProps {
  children: ReactNode
}

export function NotificationsProvider({ children }: NotificationsProps) {
  const authUser = useAuth()
  const userId = useSelector(idSelector)
  const { data } = useActivityKnockUserTokenDetails({
    enabled: authUser.isAuthenticated === true,
  })

  if (!data) {
    return children
  }

  const { REACT_APP_KNOCK_PUBLIC_API_KEY = 'default_key' } = env

  return (
    <KnockProvider
      apiKey={REACT_APP_KNOCK_PUBLIC_API_KEY}
      userToken={data.knock_user_token}
      userId={userId}>
      <>{children}</>
    </KnockProvider>
  )
}
