import actions from '../actions/overview.actions'
import * as buildApiUrl from '../utils/buildApiUrl'

export default {
  [actions.managed.actions.markModification]: (
    state,
    { payload: { learnpathId, status = true } },
  ) => ({
    ...state,
    [buildApiUrl.managed(learnpathId)]: {
      modified: status,
    },
  }),
}
