import { get, isEmpty } from 'lodash/fp'
import queryString from 'query-string'

import { buildLink, compactObject } from '@masterplandev/utils'

const base = '/learnpaths'

export function feedback() {
  return buildLink(base, 'feedback')
}

export function managed(learnpathId): `/learnpaths/managed/${string}` {
  return `${base}/managed/${learnpathId}`
}

export function members(learnpathId) {
  return buildLink(managed(learnpathId), 'members')
}

export function elements(learnpathId) {
  return buildLink(managed(learnpathId), 'elements')
}

export function element({ learnpathId, elementId, elementType }) {
  return buildLink(elements(learnpathId), elementType, elementId)
}

export function scormElement({
  learnpathId,
  elementId,
}: {
  learnpathId
  elementId?
}) {
  return buildLink(elements(learnpathId), 'scorm', elementId)
}

export function candidates(learnpathId, lookFor?) {
  return `${buildLink(managed(learnpathId), 'candidates')}${
    lookFor ? `?${queryString.stringify({ query: lookFor })}` : ''
  }`
}

export function join(sharingLinkId) {
  return buildLink(base, 'join', sharingLinkId)
}

export function platformTopics(learnpathId, queryParams?) {
  if (!isEmpty(queryParams)) {
    const queryMappedParams = compactObject({
      q: get('searchPhrase', queryParams),
      order: get('sortOrder', queryParams),
      content_type: get('contentTypeFilter', queryParams),
      locale: get('localeFilter', queryParams),
      tags: get('tagsFilter', queryParams),
      offset: get('offset', queryParams),
    })

    if (isEmpty(queryMappedParams)) {
      return buildLink(managed(learnpathId), 'catalog/platform-topics')
    }

    return `${buildLink(
      managed(learnpathId),
      'catalog/platform-topics',
    )}?${queryString.stringify(queryMappedParams)}`
  }

  return buildLink(managed(learnpathId), 'catalog/platform-topics')
}

export function userGroups(learnpathId) {
  return buildLink(managed(learnpathId), 'user_groups')
}
