import { useQuery } from '@tanstack/react-query'

import { activityQueries } from '@/http/api/activity'

export function useActivityKnockUserTokenDetails(options: {
  enabled: boolean
}) {
  return useQuery({
    ...activityQueries.knockUserTokenDetails(),
    enabled: options.enabled,
  })
}
