import { useQuery } from '@tanstack/react-query'

import { company } from '@/http/api/company'

interface UseNoEmailUsersGetProps {
  ssoSourceId: string
}

export function useNoEmailUsersGet({ ssoSourceId }: UseNoEmailUsersGetProps) {
  return useQuery({
    queryKey: ['company', 'no-email-users', ssoSourceId],
    queryFn: () => company.noEmailUsers.details({ params: { ssoSourceId } }),
  })
}
