import { compact, mapKeys, pipe, split } from 'lodash/fp'
import queryString from 'query-string'

import { compactObject } from '@masterplandev/utils'

export const trackPathnameParams = ({ pathname, search, hash }) => {
  const path = pipe([split('/'), compact, mapKeys((key) => `path_${key}`)])(
    pathname,
  )

  const search_params = mapKeys(
    (key) => `search_${key}`,
    queryString.parse(search),
  )

  return compactObject(
    mapKeys((key) => `location_${key}`, {
      ...path,
      pathname,
      ...search_params,
      search,
      hash,
    }),
  )
}

export default trackPathnameParams
