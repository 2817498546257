import { join, pipe, replace, slice, split } from 'lodash/fp'

import { URL_COMPANY } from '../constants/constants'

export function urlToPagename(url: string | undefined) {
  const COMPANY_SPACE = 'Company space'
  const LEARNING_SPACE = 'Learning space'

  const formatString: () => string = () => {
    return pipe([
      replace(URL_COMPANY, ''),
      replace(/\//g, ' '),
      split(' '),
      slice(1, 2),
      join(' '),
    ])(url)
  }

  if (url === '/') {
    return `${LEARNING_SPACE}`
  }

  if (url?.includes(URL_COMPANY)) {
    return `${COMPANY_SPACE}: ${formatString()}`
  }

  return `${LEARNING_SPACE}: ${formatString()}`
}

export default urlToPagename
