import {
  concat,
  differenceBy,
  eq,
  filter,
  get,
  getOr,
  isEmpty,
  map,
  pipe,
  unionBy,
} from 'lodash/fp'
import { getActionTypes } from 'redux-axios-middleware'

import { modifyObjectOrAdd } from '@masterplandev/utils'

import aggregateActions from '@/core/utils/redux/aggregateActions'
import initialiseReducerState from '@/core/utils/redux/initialiseReducerState'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeState from '@/core/utils/redux/mergeState'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

import actions from '../actions'

const { platformTopics } = actions.managed
const [REQUEST_ACTIONS, SUCCESS_ACTIONS, FAILURE_ACTIONS] = aggregateActions(
  platformTopics.get,
)

const [, PLATFORM_TOPICS_GET_SUCCESS] = getActionTypes({
  type: platformTopics.get,
})

export default {
  [REQUEST_ACTIONS]: mergeRequestAction,
  [SUCCESS_ACTIONS]: mergeSuccessAction,
  [FAILURE_ACTIONS]: mergeErrorAction,

  [PLATFORM_TOPICS_GET_SUCCESS]: (state, { meta, payload: { data } }) => {
    const resourceUrl = get('previousAction.payload.options.url', meta)
    const queryPramsOffset = get(
      'previousAction.payload.queryParams.offset',
      meta,
    )
    const currTopics = getOr([], `${resourceUrl}.data.topics`, state)
    const newTopics = getOr([], 'topics', data)

    return mergeState(state, {
      [resourceUrl]: {
        ...initialiseReducerState(),
        data: {
          ...data,
          topics:
            queryPramsOffset > 0 ? concat(currTopics, newTopics) : newTopics,
        },
        fetched: true,
      },
    })
  },

  [platformTopics.selectLecture]: (
    state,
    {
      payload: {
        data: { topic, lecture },
        options,
      },
    },
  ) => {
    const topics = getOr([], `${options.url}.selected`, state)
    const newTopic = {
      ...topic,
      selectedLectures: [lecture],
    }
    const modifier = (sourceTopic) => ({
      ...topic,
      selectedLectures: unionBy('id', sourceTopic.selectedLectures, [lecture]),
    })

    return mergeState(state, {
      [options.url]: {
        selected: modifyObjectOrAdd(topics, newTopic, modifier, 'id'),
      },
    })
  },

  [platformTopics.unselectLecture]: (
    state,
    {
      payload: {
        data: { topic, lecture },
        options,
      },
    },
  ) =>
    mergeState(state, {
      [options.url]: {
        selected: pipe([
          getOr([], `${options.url}.selected`),
          map((currTopic) =>
            currTopic.id === topic.id
              ? {
                  ...topic,
                  selectedLectures: differenceBy(
                    'id',
                    currTopic.selectedLectures,
                    [lecture],
                  ),
                }
              : currTopic,
          ),
          filter(({ selectedLectures }) => !isEmpty(selectedLectures)),
        ])(state),
      },
    }),

  [platformTopics.selectTopicAllLectures]: (
    state,
    {
      payload: {
        data: { topic },
        options,
      },
    },
  ) => {
    const topics = getOr([], `${options.url}.selected`, state)
    const newTopic = { ...topic, selectedLectures: topic.lectures }
    const modifier = () => ({
      ...topic,
      selectedLectures: topic.lectures,
    })

    return mergeState(state, {
      [options.url]: {
        selected: modifyObjectOrAdd(topics, newTopic, modifier, 'id'),
      },
    })
  },

  [platformTopics.unselectTopicAllLectures]: (
    state,
    {
      payload: {
        data: { topic },
        options,
      },
    },
  ) =>
    mergeState(state, {
      [options.url]: {
        selected: filter(
          ({ id }) => !eq(id, topic.id),
          getOr([], `${options.url}.selected`, state),
        ),
      },
    }),

  [platformTopics.unselectAll]: (state, { payload: { options } }) =>
    mergeState(state, {
      [options.url]: {
        selected: [],
      },
    }),
}
