import { ReactKeycloakProvider } from '@react-keycloak/web'
import * as Sentry from '@sentry/react'
import jwtDecode from 'jwt-decode'
import { KeycloakInitOptions } from 'keycloak-js'
import { ComponentProps, PropsWithChildren } from 'react'
import { useDispatch } from 'react-redux'

import { Center } from '@masterplandev/ui'

import actions from '@/actions'
import Loader from '@/core/components/ui/Loader/Loader'
import { SENTRY_SECTIONS } from '@/core/constants/constants'
import keycloak from '@/core/keycloak'
import setInternalCookie from '@/core/utils/internal-cookie/setInternalCookie'

type onEvent = ComponentProps<typeof ReactKeycloakProvider>['onEvent']
type onTokens = ComponentProps<typeof ReactKeycloakProvider>['onTokens']

interface AuthProviderProps {
  initOptions?: KeycloakInitOptions
}

export function AuthProvider({
  children,
  initOptions,
}: PropsWithChildren<AuthProviderProps>) {
  const dispatch = useDispatch()

  const handleEvent: onEvent = (event, error) => {
    if (error) {
      Sentry.captureException(error, {
        level: 'fatal',
        tags: { section: SENTRY_SECTIONS.keycloak },
      })
    }
    if (event === 'onAuthSuccess') {
      setInternalCookie(keycloak.tokenParsed)
    }
  }

  const handleTokens: onTokens = (tokens) => {
    try {
      const tokenString = tokens.token
      if (tokenString) {
        const decodedData = jwtDecode(tokenString)
        dispatch(actions.user.updateAccount(decodedData))
      }
    } catch (e) {
      dispatch(actions.user.logout())
    }
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        silentCheckSsoRedirectUri: `${window.location.origin}/static/html/silent-check-sso.html`,
        ...initOptions,
      }}
      LoadingComponent={
        <Center position="fixed" inset="0">
          <Loader />
        </Center>
      }
      onEvent={handleEvent}
      onTokens={handleTokens}>
      {children}
    </ReactKeycloakProvider>
  )
}
