import { some, startsWith } from 'lodash/fp'
import { ReactNode, useCallback } from 'react'
import { generatePath, useLocation } from 'react-router-dom'

import { URL_SIGNUP, URL_TOPIC } from '@/core/constants/constants'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import useTopicCompletionProgress from '@/topic/hooks/useTopicCompletionProgress'

import { AppManagerScormConfigShared } from './AppManagerScormConfigShared'

interface Props {
  children: ReactNode
  lockContent?: boolean
  sendMessage: (type: string, payload: any) => void
  topicToShow: string
}

export function AppManagerScormConfigTopic({
  children,
  lockContent,
  sendMessage,
  topicToShow,
}: Props) {
  const location = useLocation()
  const progress = useTopicCompletionProgress(topicToShow)
  const redirect = useCallback(() => {
    if (lockContent) {
      const target = generatePath(URL_TOPIC, { topic: topicToShow })
      const validUrls = [
        URL_SIGNUP,
        target,
        buildLectureLink({ topic: topicToShow }),
      ]

      // Allows only valid URLS. Restrict others.
      if (!some((url) => startsWith(url, location.pathname), validUrls)) {
        return target
      }
    }

    return null
  }, [topicToShow, lockContent, location])

  return (
    <AppManagerScormConfigShared
      progress={progress}
      redirect={redirect}
      sendMessage={sendMessage}>
      {children}
    </AppManagerScormConfigShared>
  )
}
