import { ReactNode } from 'react'

import { UIProvider as Provider } from '@masterplandev/ui'

interface UIProviderProps {
  children: ReactNode
}
export function UIProvider({ children }: UIProviderProps) {
  return (
    <Provider config={{ iconUrl: '/static/icons.svg' }}>{children}</Provider>
  )
}
