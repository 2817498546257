import { get, omit, pipe } from 'lodash/fp'
import { getActionTypes } from 'redux-axios-middleware'

import { compactObject } from '@masterplandev/utils'

import actions from '@/actions'
import { STATUS_IN_PROGRESS } from '@/core/constants/constants'
import mergeState from '@/core/utils/redux/mergeState'

const [PROGRESS_PUT_REQUEST, PROGRESS_PUT_SUCCESS, PROGRESS_PUT_FAILURE] =
  getActionTypes({
    type: actions.lecture.progressPut,
  })

export default {
  [PROGRESS_PUT_REQUEST]: (state, { payload }) => {
    const { params } = payload
    if (params.learnpathId && !params.topic) {
      return state
    }

    // Progress endpoint is not tracked separately, but there's a need to do so to synchronise certain
    // components like LectureNextButton.
    return mergeState(state, {
      [`${get('options.url', payload)}/progress`]: {
        fetching: true,
      },
    })
  },
  [PROGRESS_PUT_FAILURE]: (state, { meta }) => {
    const params = get('previousAction.payload.params', meta)
    if (params.learnpathId && !params.topic) {
      return state
    }

    // Progress endpoint is not tracked separately, but there's a need to do so to synchronise certain
    // components like LectureNextButton.
    return mergeState(state, {
      [`${get('previousAction.payload.options.url', meta)}/progress`]: {
        fetching: false,
      },
    })
  },
  [PROGRESS_PUT_SUCCESS]: (state, { meta, payload: { data } }) => {
    const params = get('previousAction.payload.params', meta)

    // Ignore this action if it was performed on the PTE itself.
    if (params?.learnpathId && params?.elementId && !params?.topic) {
      return state
    }

    const resourceUrl = get('previousAction.payload.options.url', meta)
    const progressUrl = `${resourceUrl}/progress`

    // Store progress meta will be always more up to date than PUT response data coming from the server,
    // that cannot be easily synchronised.
    return mergeState(state, {
      [resourceUrl]: {
        data: { progress: pipe(omit('meta'), compactObject)(data) },
      },
      [progressUrl]: {
        fetching: false,
      },
    })
  },
  [actions.lecture.progressUpdateStore]: (
    state,
    { payload: { url, progress, params } },
  ) => {
    if (params.learnpathId) {
      return state
    }

    return mergeState(state, {
      [url]: {
        data: {
          progress: omit('status', progress),
        },
        activeStatus: progress.status,
      },
    })
  },
  [actions.lecture.setActiveStatus]: (state, { payload: { url, params } }) => {
    if (params.learnpathId) {
      return state
    }

    return mergeState(state, {
      [url]: { activeStatus: STATUS_IN_PROGRESS },
    })
  },
  [actions.lecture.endActiveStatus]: (state, { payload: { url, params } }) => {
    if (params.learnpathId) {
      return state
    }

    return mergeState(state, {
      [url]: { activeStatus: null },
    })
  },
  [actions.lecture.playVideo]: (state, { payload: { url, params } }) => {
    if (params.learnpathId) {
      return state
    }

    return mergeState(state, {
      [url]: { video_playing: true },
    })
  },
  [actions.lecture.stopVideo]: (state, { payload: { url, params } }) => {
    if (params.learnpathId) {
      return state
    }

    return mergeState(state, {
      [url]: { video_playing: false },
    })
  },
}
