import { get, isEmpty } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import actions from '@/actions'

import {
  scormConfigSelector,
  scormLaunchIdSelector,
} from '../selectors/scorm.selectors'
import { ScormConfiguration } from '../types/ScormConfiguration'
import { ScormConfigurationExtended } from '../types/ScormConfigurationExtended'
import isScormEnabled from '../utils/isScormEnabled'

/**
 * @example
 * Various cases for locking content (additional lockContent flag) are supported.
 * 1) config: {} -> false
 * 2) config: { surpassLock: true } -> false
 * 3) config: { topicToShow } -> true
 * 4) config: { learnpathId } -> true
 * 5) config: { topicToShow, surpassLock: true } -> false
 * 6) config: { learnpathId, surpassLock: true } -> false
 */
export default function useScormConfiguration(): ScormConfigurationExtended {
  const config: ScormConfiguration = useSelector(scormConfigSelector)
  const dispatch = useDispatch()
  const launchId = useSelector(scormLaunchIdSelector)
  const scormEnabled = isScormEnabled()
  const [assignedLearnpathId, setAssignedLearnpathId] = useState()
  const sharingLinkId = config?.learnpath_external_id ? launchId : null

  useEffect(() => {
    if (scormEnabled) {
      if (!isEmpty(sharingLinkId)) {
        const action = actions.learnpathsAssigned.join.post({
          sharingLinkId,
          scormEnabled,
        })
        dispatch(action)
          .then((response) => {
            setAssignedLearnpathId(get('payload.data.id', response))
          })
          .catch((error) => {
            // If someone has been already assigned to the learnpath, we still want to display it in a SCORM view for them,
            // that is why we get learnpath_id from the error response and pass it
            setAssignedLearnpathId(
              get('error.response.data.learnpath_id', error),
            )
          })
      }
    }
  }, [dispatch, scormEnabled, sharingLinkId])

  if (scormEnabled) {
    const topicToShow = config.course_external_id
      ? launchId
      : config.topicToShow

    const hasContentToLock =
      !!config.learnpathId ||
      !!config.topicToShow ||
      !!config.course_external_id ||
      !!config.learnpath_external_id

    return {
      ...config,
      topicToShow,
      learnpathId: config.learnpathId ?? assignedLearnpathId,
      lockContent: hasContentToLock && !config.surpassLock,
    }
  }

  return {}
}
