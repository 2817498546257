import { createActions } from 'redux-actions'

import {
  API_URL_INVITES,
  API_URL_SELF_INVITE,
} from '@/core/constants/constants'

const actions = createActions({
  SIGNUP: {
    GET: (uid) => ({
      request: {
        url: `${API_URL_INVITES}/${uid}/signup-details`,
      },
    }),
    SELF_INVITE: {
      GET: (slug) => ({
        request: { url: `${API_URL_SELF_INVITE}/links/${slug}` },
      }),
      POST: (data) => ({
        request: {
          method: 'POST',
          url: API_URL_SELF_INVITE,
          data,
        },
      }),
    },
  },
})

export default actions.signup
