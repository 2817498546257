import { includes, isEmpty } from 'lodash/fp'

const IGNORE_IDS = ['student-id', 'student_id', 'learner-id', 'learner_id']

/**
 * Returns a valid scorm user ID from a given object
 *
 * @param {object} payload - scorm payload
 * @returns {(string|null)} scorm user ID, if valid, otherwise it will return null
 *
 * @example
 * detectStudentId({learner_id: 'test@masterplan.com'})
 */
export default function detectStudentId(payload) {
  if (isEmpty(payload)) {
    return null
  }

  const { student_id, learner_id } = payload

  const isValidId = (id) => !!id && !includes(id, IGNORE_IDS)

  if (isValidId(student_id)) {
    return student_id
  }

  if (isValidId(learner_id)) {
    return learner_id
  }

  return null
}
