import { useKeycloak } from '@react-keycloak/web'
import { noop } from 'lodash/fp'
import { useRef } from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  useToast,
} from '@masterplandev/ui'

import { TOKEN_MIN_VALIDITY_VALUES } from '@/auth/constants'
import useTranslation from '@/core/hooks/useTranslation'
import { p, strong } from '@/core/richTextElements'

export function VerifyEmail() {
  const { translate } = useTranslation('auth-verify-email')
  const continueRef = useRef(null)

  const toast = useToast()
  const { keycloak } = useKeycloak()

  const handleContinue = async () => {
    toast.promise(
      keycloak.updateToken(
        TOKEN_MIN_VALIDITY_VALUES.SKIP_CHECKING_EXPIRATION_DATE,
      ),
      {
        loading: {
          title: translate('toast.loading.title'),
        },
        success: () => {
          if (keycloak.tokenParsed?.email_verified === false) {
            return {
              status: 'info',
              title: translate('toast.info.title'),
              description: translate('toast.info.description'),
            }
          }
          return {
            status: 'success',
            title: translate('toast.success.title'),
            description: translate('toast.success.description'),
          }
        },
        error: {
          title: translate('toast.error.title'),
          description: translate('toast.error.description'),
        },
      },
    )
  }

  return (
    <AlertDialog
      size="xl"
      variant="alert"
      isCentered
      isOpen
      leastDestructiveRef={continueRef}
      onClose={noop}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{translate('title')}</AlertDialogHeader>
          <AlertDialogBody>
            {translate('description', {
              email: keycloak.tokenParsed?.email,
              strong,
              p,
            })}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={continueRef}
              leftIcon={<Icon size="md" symbol="general-check" />}
              onClick={() => {
                handleContinue()
              }}>
              {translate('continue')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
