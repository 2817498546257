import { get, isEmpty } from 'lodash/fp'
import { ReactNode, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMount, useUnmount } from 'react-use'

import actions from '@/actions'
import { scormOriginSelector } from '@/scorm/selectors/scorm.selectors'
import isScormEnabled from '@/scorm/utils/isScormEnabled'

import { AppManagerScormConfig } from './AppManagerScormConfig'

interface Props {
  children: ReactNode
}

function AppManagerScormEnabled({ children }: Props) {
  const dispatch = useDispatch()
  const scormAuthParentOrigin: string = useSelector(scormOriginSelector)

  // Processes messages from Masterplan SCORM package.
  const processMessage = useCallback(
    ({ origin, data }: any) => {
      if (
        origin === scormAuthParentOrigin &&
        origin !== window.location.origin
      ) {
        const config = get('payload.config', data)

        if (!isEmpty(config)) {
          dispatch(actions.scorm.setConfig(config))
        }
      }
    },
    [dispatch, scormAuthParentOrigin],
  )

  // Sends message to Masterplan SCORM package, but do this only to previously authorized origin.
  const sendMessage = useCallback(
    (type: any, payload: any) => {
      if (window.parent && scormAuthParentOrigin) {
        window.parent.postMessage({ type, payload }, scormAuthParentOrigin)
      }
    },
    [scormAuthParentOrigin],
  )

  // Registers communication between webapp and Masterplan SCORM package.
  useMount(() => {
    window.addEventListener('message', processMessage)
  })

  // Clears up communication between webapp and Masterplan SCORM package.
  useUnmount(() => {
    window.removeEventListener('message', processMessage)
  })

  return (
    <AppManagerScormConfig sendMessage={sendMessage}>
      {children}
    </AppManagerScormConfig>
  )
}

export function AppManagerScorm({ children }: Props) {
  if (isScormEnabled()) {
    return <AppManagerScormEnabled>{children}</AppManagerScormEnabled>
  }

  return children as JSX.Element
}
