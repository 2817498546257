import { compact, join } from 'lodash/fp'
import { Component, ReactNode } from 'react'
import { IntlShape, injectIntl } from 'react-intl'

interface Props {
  intl: IntlShape
  children: ReactNode
}

export class AppManagerTitleNoIntl extends Component<Props> {
  constructor(props) {
    super(props)
    this.overrideDocumentProperties()
  }

  componentWillUnmount() {
    // @ts-ignore
    delete document.setTitle
    // @ts-ignore
    delete document.clearTitle
  }

  setTitle = (id) => {
    const { formatMessage } = this.props.intl
    const prefix = formatMessage({ id: 'core.title.prefix' })
    const title = formatMessage({ id: `core.title.${id}` })
    document.title = join(' | ', compact([prefix, title]))
  }

  overrideDocumentProperties = () => {
    // @ts-ignore
    document.setTitle = this.setTitle
    // @ts-ignore
    document.clearTitle = this.clearTitle
  }

  clearTitle = () => {
    document.title = this.props.intl.formatMessage({ id: 'core.title.prefix' })
  }

  render() {
    return this.props.children
  }
}

export const AppManagerTitle = injectIntl(AppManagerTitleNoIntl)
