import { get, map, merge, pipe, replace, uniq } from 'lodash/fp'
import { handleActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import { mergeReplaceArray } from '@masterplandev/utils'

import actions from '@/actions'

import { APP_LOCALE } from './constants'

const [REQUEST, SUCCESS, ERROR] = getActionTypes({ type: actions.locale.fetch })

export const detectNavigatorLanguages = pipe([
  get('navigator.languages'),
  map(replace(/-.+/, '')),
  uniq,
])

const initialState = {
  fetched: false,
  fetching: false,
  failed: false,
  data: {
    supported: APP_LOCALE,
    user: null,
    browser: null,
    navigator: detectNavigatorLanguages(window),
  },
}

export default handleActions(
  {
    [REQUEST]: (state) => merge(state, { fetching: true }),
    [ERROR]: (state) => merge(state, { fetching: false, failed: true }),
    [SUCCESS]: (state, { payload: { data } }) =>
      mergeReplaceArray(state, {
        fetching: false,
        fetched: true,
        data,
      }),
  },
  initialState,
)
