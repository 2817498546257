import {
  castArray,
  fromPairs,
  get,
  isArray,
  isNull,
  isObject,
  map,
  omitBy,
  pipe,
  toNumber,
  trim,
} from 'lodash/fp'

import { keysIfTrue, smartJoin, valuesIfTrue } from '@masterplandev/utils'

import {
  POLL_QUESTION_TYPE_BOOL,
  POLL_QUESTION_TYPE_FREE_TEXT,
  POLL_QUESTION_TYPE_RATE,
} from '@/core/constants/constants'

export const POLL_FORM_NAME = 'poll'

export const buildUrl = ({ type, param }) =>
  smartJoin('/', [' ', 'polls', type, param])

export const formatAnswer = (value, type) => {
  if (get('skip', value)) {
    return null
  }

  if (type === POLL_QUESTION_TYPE_FREE_TEXT) {
    const text = trim(get('text', value))
    return text.length > 0 ? text : null
  }

  if (type === POLL_QUESTION_TYPE_RATE) {
    return toNumber(value)
  }

  if (type === POLL_QUESTION_TYPE_BOOL) {
    return value === 'true'
  }

  if (isArray(value)) {
    return valuesIfTrue(value)
  }

  if (isObject(value)) {
    return keysIfTrue(value)
  }

  return castArray(value)
}

export const formatAnswers = (values, questions) =>
  pipe([
    map(({ id, type }) => [id, formatAnswer(values[id], type)]),
    fromPairs,
    omitBy(isNull),
  ])(questions)
