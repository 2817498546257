import { difference, differenceBy, get, getOr, union, unionBy } from 'lodash/fp'
import { combineActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import { QueryKeys } from '@/api/queryKeys'
import { queryClient } from '@/core/utils/react-query/queryClient'
import aggregateActions from '@/core/utils/redux/aggregateActions'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeState from '@/core/utils/redux/mergeState'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

import actions from '../actions'
import { candidates as buildCandidatesUrl } from '../utils/buildApiUrl'

const { members, candidates, selectFilter, unmarkFilter } = actions.managed

const [REQUEST_ACTIONS, SUCCESS_ACTIONS, FAILURE_ACTIONS] = aggregateActions(
  candidates.get,
  members.delete,
  members.post,
  members.get,
)

const [, MEMBERS_GET_SUCCESS] = getActionTypes({ type: members.get })

const [, MEMBERS_DELETE_SUCCESS] = getActionTypes({ type: members.post })
const [, MEMBERS_POST_SUCCESS] = getActionTypes({ type: members.delete })
const MEMBERS_MODIFIED = combineActions(
  MEMBERS_DELETE_SUCCESS,
  MEMBERS_POST_SUCCESS,
)

const [, CANDIDATES_GET_SUCCESS] = getActionTypes({
  type: actions.managed.candidates.get,
})

export default {
  [REQUEST_ACTIONS]: mergeRequestAction,
  [SUCCESS_ACTIONS]: mergeSuccessAction,
  [FAILURE_ACTIONS]: mergeErrorAction,

  /* When candidates request action did not have search term provided nullify
  its data candidates response as if it was never fetched as the request
  was made to fetch assigned_for_all_students number. */
  [CANDIDATES_GET_SUCCESS]: (state, action) => {
    const { meta } = action
    const resourceUrl = get('previousAction.payload.request.url', meta)
    const lookForTerm = get('previousAction.payload.lookForTerm', meta)

    if (!lookForTerm) {
      return mergeState(state, {
        [resourceUrl]: {
          data: {
            candidates: null,
          },
        },
      })
    }

    return mergeSuccessAction(state, action)
  },

  [MEMBERS_GET_SUCCESS]: (state, action) => {
    const {
      meta,
      payload: { data },
    } = action
    const learnpathId = get('previousAction.payload.learnpathId', meta)
    const resourceUrl = get('previousAction.payload.request.url', meta)
    const candidatesUrl = buildCandidatesUrl(learnpathId)
    return mergeState(state, {
      [resourceUrl]: {
        data,
      },
      [candidatesUrl]: {
        selected: unionBy(
          'id',
          state[candidatesUrl].selected,
          getOr([], 'members', data),
        ),
      },
    })
  },

  [members.move]: (state, { payload: { sourceUrl, moveToUrl } }) =>
    mergeState(state, {
      [moveToUrl]: {
        selected: getOr([], 'data.members', state[sourceUrl]),
      },
    }),
  [candidates.select]: (state, { payload: { data, options } }) =>
    mergeState(state, {
      [options.url]: {
        selected: unionBy('id', state[options.url].selected, [data]),
      },
    }),
  [candidates.unmark]: (state, { payload: { data, options } }) =>
    mergeState(state, {
      [options.url]: {
        selected: differenceBy('id', state[options.url].selected, [data]),
      },
    }),
  [selectFilter]: (state, { payload: { data, options } }) =>
    mergeState(state, {
      [options.url]: {
        filters: union(state[options.url]?.filters, [data]),
      },
    }),
  [unmarkFilter]: (state, { payload: { data, options } }) =>
    mergeState(state, {
      [options.url]: {
        filters: difference(state[options.url]?.filters, [data]),
      },
    }),

  /* Normalise data between redux-store and react-query cache. */
  [MEMBERS_MODIFIED]: (state, action) => {
    const learnpathId = get('meta.previousAction.payload.learnpathId', action)
    const membersSize = get('payload.data.members.length', action)

    const key = QueryKeys.learnpaths.managed.detail(learnpathId)
    queryClient.setQueryData(key, {
      ...queryClient.getQueryData(key),
      num_members: membersSize,
    })
    return state
  },
}
