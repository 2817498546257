import { get } from 'lodash/fp'

import { eventTracker } from '@masterplandev/utils'

import { TRACK_EXIT } from '@/core/constants/constants'

export function trackExit() {
  if (eventTracker && eventTracker.persistence) {
    const { persistence } = eventTracker
    const { apiUrl } = eventTracker.get_config()

    const startTime = get(['timers', TRACK_EXIT], persistence)
    const timeDiff = new Date().getTime() - startTime
    const $duration = parseFloat((timeDiff / 1000).toFixed(3))

    const data = eventTracker.get_event(TRACK_EXIT, {
      $duration,
    })

    navigator.sendBeacon(`${apiUrl}/track?data=${btoa(JSON.stringify(data))}`)
  }
}

export default trackExit
