import { curry, get, merge, set } from 'lodash/fp'

const REQUEST_URL_PATH = 'payload.request.url'
const RESPONSE_URL_PATH = 'meta.previousAction.payload.request.url'

/**
 * Modifies payload.url value before passing whole payload to wrapped reducer function.
 *
 * @param {Function} func - A function returning final payload.url to use in reducer function
 * @param {Function} next - reducer function
 * @returns {Function} modified reducer function
 *
 * @example
 *
 * export default handleActions({
 *   ...
 *   [GET_REQUEST]: modifyRequestUrl(replace('/invite/', '/signup/'), mergeRequestAction),
 *   ...
 * })
 */
export const modifyRequestUrl = curry(
  (urlTransformer, actionHandler) => (state, action) => {
    const isResponse = !!get(RESPONSE_URL_PATH, action)
    const modifyPath = isResponse ? RESPONSE_URL_PATH : REQUEST_URL_PATH

    const oldUrl = get(modifyPath, action)
    const newUrl = urlTransformer(oldUrl, action)

    return actionHandler(state, merge(action, set(modifyPath, newUrl, action)))
  },
)

export default modifyRequestUrl
