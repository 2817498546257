import { some, startsWith } from 'lodash/fp'
import { ReactNode, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { URL_LEARNPATHS, URL_SIGNUP } from '@/core/constants/constants'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import useAssignedLearnpathCompletionProgress from '@/learnpaths-assigned/hooks/useAssignedLearnpathCompletionProgress'

import { AppManagerScormConfigShared } from './AppManagerScormConfigShared'

interface Props {
  children: ReactNode
  learnpathId: string
  lockContent?: boolean
  sendMessage: (type: string, payload: any) => void
}

export function AppManagerScormConfigLearnpathId({
  children,
  learnpathId,
  lockContent,
  sendMessage,
}: Props) {
  const location = useLocation()
  const progress = useAssignedLearnpathCompletionProgress({ learnpathId })
  const redirect = useCallback(() => {
    if (lockContent) {
      const params = { learnpathId }
      const target = URL_LEARNPATHS
      const validUrls = [URL_SIGNUP, target, buildLectureLink(params)]

      // Allows only valid URLS. Restrict others.
      if (!some((url) => startsWith(url, location.pathname), validUrls)) {
        return target
      }
    }

    return null
  }, [learnpathId, lockContent, location])

  return (
    <AppManagerScormConfigShared
      progress={progress}
      redirect={redirect}
      sendMessage={sendMessage}>
      {children}
    </AppManagerScormConfigShared>
  )
}
