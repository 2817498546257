import aggregateActions from '@/core/utils/redux/aggregateActions'
import mergeFailureAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

import bookmarked from '../actions'

const [REQUEST_ACTIONS, SUCCESS_ACTIONS, FAILURE_ACTIONS] = aggregateActions(
  bookmarked.lectures.delete,
  bookmarked.lectures.get,
  bookmarked.lectures.post,
)

export default {
  [REQUEST_ACTIONS]: mergeRequestAction,
  [SUCCESS_ACTIONS]: mergeSuccessAction,
  [FAILURE_ACTIONS]: mergeFailureAction,
}
