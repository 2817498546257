import { TRACK_NAVIGATION } from '@/core/constants/constants'
import scrollToElement from '@/core/utils/scroll/scrollToElement'
import track from '@/core/utils/tracking/track'
import trackPathnameParams from '@/core/utils/tracking/trackPathnameParams'
import updateMixpanelLastLogin from '@/core/utils/tracking/updateMixpanelLastLogin'

export function historyListener(location, action) {
  const { hash } = location
  const params = trackPathnameParams(location)
  if (action !== 'REPLACE') {
    // We push tracking call at the end of the event loop
    // to collect necessary properties from matched Route components
    setTimeout(() => {
      updateMixpanelLastLogin()
      track(TRACK_NAVIGATION, {
        ...params,
        url: window.location.toString(),
      })
    }, 0)
  }

  const NAVIGATION_HEIGHT_PLUS_OFFSET = 80

  if (hash) {
    try {
      window.requestAnimationFrame(() => {
        scrollToElement(hash, { offset: -NAVIGATION_HEIGHT_PLUS_OFFSET })
      })
    } catch (exception) {
      if (exception instanceof DOMException) {
        // We should not throw an error when the passed selector was an invalid and the element couldn't be found.
      } else {
        throw exception
      }
    }
  }
}
