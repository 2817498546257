export default function initialiseReducerState(...apiUrls) {
  if (apiUrls.length > 0) {
    return apiUrls.reduce(
      (state, apiUrl) => ({
        ...state,
        [apiUrl]: {
          data: {},
          error: null,
          error_status: null,
          failed: false,
          fetched: false,
          fetching: false,
        },
      }),
      {},
    )
  }

  return {
    data: {},
    error: null,
    error_status: null,
    failed: false,
    fetched: false,
    fetching: false,
  }
}
