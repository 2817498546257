import { concat, get, getOr, includes, map } from 'lodash/fp'
import { getActionTypes } from 'redux-axios-middleware'

import aggregateActions from '@/core/utils/redux/aggregateActions'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeState from '@/core/utils/redux/mergeState'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

import actions from '../actions/assigned.actions'
import buildApiUrl from '../utils/buildApiUrl'
import stateNormalise from '../utils/stateNormalise'

const [REQUEST_ACTIONS, SUCCESS_ACTIONS, FAILURE_ACTIONS] = aggregateActions(
  actions.assigned.element.get,
)

const [ASSIGNED_GET_REQUEST, ASSIGNED_GET_SUCCESS, ASSIGNED_GET_FAILURE] =
  getActionTypes({ type: actions.assigned.get })

export default {
  [REQUEST_ACTIONS]: mergeRequestAction,
  [SUCCESS_ACTIONS]: mergeSuccessAction,
  [FAILURE_ACTIONS]: mergeErrorAction,

  [ASSIGNED_GET_REQUEST]: mergeRequestAction,
  [ASSIGNED_GET_SUCCESS]: (state, action) => {
    const learnpathId = get('meta.previousAction.payload.learnpathId', action)

    // If request was called with learnpathId it means we are looking for a
    // specific learnpath. In that case merge response in a classic way.
    if (learnpathId) {
      const listUrl = buildApiUrl.assigned()
      const idsList = getOr([], [listUrl, 'data'], state)

      const learnpath = get('payload.data', action)

      if (includes(learnpathId, idsList)) {
        return stateNormalise.normaliseAndMergeLearnpaths(state, [learnpath])
      }

      return stateNormalise.normaliseAndMergeLearnpaths(
        mergeState(state, {
          [listUrl]: {
            data: concat(idsList, learnpathId),
          },
        }),
        [learnpath],
      )
    }

    const learnpaths = get('payload.data.learnpaths', action)

    // Fill original url's data with learnpaths' ids only.
    return mergeSuccessAction(
      stateNormalise.normaliseAndMergeLearnpaths(state, learnpaths),
      action,
      {
        data: map('id', learnpaths),
      },
    )
  },
  [ASSIGNED_GET_FAILURE]: mergeErrorAction,
}
