import { QuizQuestionType } from './types/QuizQuestionType'

export const API_ERROR_LEARNPATHS_DUPLICATE_LEARNPATH_NAME =
  'learnpath.error.duplicate-learnpath-name'
export const API_ERROR_LEARNPATHS_VALID_URL =
  'api.error.field-is-not-a-valid-url'
export const API_ERROR_LEARNPATHS_ELEMENTS_NOT_READY =
  'learnpath.error.learnpath-elements-not-ready'
export const API_ERROR_LEARNPATHS_COMPLETE_UNTIL_FROM_PAST =
  'api.error.date-is-from-the-past'

export const IMAGE_UPLOAD_MAX_FILE_SIZE = 10485760 // 10MB

export const FILTER_USE_SELECTED = 'use-selected'
export const FILTER_USE_ALL = 'use-all'

export const ADD_MEMBER_ERROR_MIN_CHARACTERS =
  'learnpaths.add-members.error.min-characters-not-reached'

export const MINIMAL_NUMBER_OF_CHARACTERS = 3

export const CREATOR_STEP_GENERAL_INFORMATION = 'general-information'
export const CREATOR_STEP_GENERAL_INFORMATION_COURSE =
  'general-information-course'
export const CREATOR_STEP_ELEMENTS = 'elements'
export const CREATOR_STEP_COMPLETION_TIME = 'completion-time'
export const CREATOR_STEP_LEARNERS = 'learners'

export const CREATOR_STEPS_LEARNPATH = [
  CREATOR_STEP_GENERAL_INFORMATION,
  CREATOR_STEP_ELEMENTS,
  CREATOR_STEP_COMPLETION_TIME,
  CREATOR_STEP_LEARNERS,
]
export const CREATOR_STEPS_ALL = [
  CREATOR_STEP_GENERAL_INFORMATION_COURSE,
  ...CREATOR_STEPS_LEARNPATH,
]

export const LEARNPATH_THUMBNAIL_ACCEPTED_FILE_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
]
export const LEARNPATH_ASSET_MAX_FILE_SIZE = 1024 * 1024 * 1024 // 1GB
export const LEARNPATH_THUMBNAIL_MAX_FILE_SIZE = 2 * 1024 * 1024 // 2MB

export const LEARNPATH_CREATOR_POINTS_MAX = 100

export const LEARNPATH_FILE_UPLOAD_URL = '/learnpaths/uploads/file'
export const LEARNPATH_VIDEO_UPLOAD_URL = '/learnpaths/uploads/video'

/* Thumbnail sizes */
/* LEARNPATH_THUMBNAIL_SIZE_${ELEMENT_TYPE}_${SIZE_TYPE}_${SIZE_DIRECTION} */
export const LEARNPATH_THUMBNAIL_SIZE_MINIMAL_WIDTH = 858
export const LEARNPATH_THUMBNAIL_SIZE_MINIMAL_HEIGHT = 456
export const LEARNPATH_THUMBNAIL_SIZE_RECOMMENDED_WIDTH = 1430
export const LEARNPATH_THUMBNAIL_SIZE_RECOMMENDED_HEIGHT = 760

export const LEARNPATH_THUMBNAIL_SIZE_GENERIC_MINIMAL_WIDTH = 580
export const LEARNPATH_THUMBNAIL_SIZE_GENERIC_MINIMAL_HEIGHT = 360
export const LEARNPATH_THUMBNAIL_SIZE_GENERIC_RECOMMENDED_WIDTH = 1160
export const LEARNPATH_THUMBNAIL_SIZE_GENERIC_RECOMMENDED_HEIGHT = 720

export const LEARNPATH_THUMBNAIL_SIZE_QUIZ_MINIMAL_WIDTH = 300
export const LEARNPATH_THUMBNAIL_SIZE_QUIZ_MINIMAL_HEIGHT = 360
export const LEARNPATH_THUMBNAIL_SIZE_QUIZ_RECOMMENDED_WIDTH = 600
export const LEARNPATH_THUMBNAIL_SIZE_QUIZ_RECOMMENDED_HEIGHT = 720

export const ELEMENT_TYPE_LINK = 'link'
export const ELEMENT_TYPE_PLATFORM_TOPIC = 'platform_topic'
export const ELEMENT_TYPE_QUIZ = 'quiz'
export const ELEMENT_TYPE_SCORM = 'scorm'
export const ELEMENT_TYPE_TEXT = 'text'
export const ELEMENT_TYPE_FILE = 'file'
export const ELEMENT_TYPE_VIDEO = 'video'
export const ELEMENT_TYPE_EMBEDDED_VIDEO = 'embedded_video'

export const ELEMENT_LIST_ERROR_EMPTY = 'learnpaths-add-elements-error.empty'
export const ELEMENT_LIST_ERROR_STATUS = 'learnpaths-add-elements-error.status'

export const PLATFORM_TOPIC_ORDER_TOP_RATED = 'top_rated'
export const PLATFORM_TOPIC_ORDER_RELEVANCE = 'relevance'
export const PLATFORM_TOPIC_ORDER_MOST_VIEWED = 'most_viewed'
export const PLATFORM_TOPIC_ORDER_MOST_RECENT = 'most_recent'
export const PLATFORM_TOPIC_ORDER = [
  PLATFORM_TOPIC_ORDER_RELEVANCE,
  PLATFORM_TOPIC_ORDER_TOP_RATED,
  PLATFORM_TOPIC_ORDER_MOST_VIEWED,
  PLATFORM_TOPIC_ORDER_MOST_RECENT,
] as const

export const LEARNPATH_QUIZ_QUESTION_TYPES = [
  'single_choice',
  'multiple_choice',
] satisfies QuizQuestionType[]

export const LEARNPATH_ASSIGNMENT_METHOD_LINK = 'link'

export const DEFAULT_PREVIEW_LEARNPATH = 'system/media-upload/default-thumbnail'
