import { get, getOr, last, pipe, split } from 'lodash/fp'
import { Selector, createSelector } from 'reselect'

import { generateBasicSelectors } from '@/core/selectors'

import { ScormState } from '../types/ScormState'
import buildApiUrl from '../utils/buildApiUrl'

type State = {
  scorm: ScormState
}

export const rootSelector: Selector<State, ScormState> = get('scorm')

export const scormOriginSelector = createSelector(
  rootSelector,
  getOr('*', 'origin'),
)

export const scormConfigSelector = createSelector(
  rootSelector,
  getOr({}, 'config'),
)

export const scormAuthSelectors = generateBasicSelectors(
  createSelector(rootSelector, getOr({}, buildApiUrl.auth() as '/scorm/auth')),
)

export const scormLaunchSelectors = generateBasicSelectors(
  createSelector(
    rootSelector,
    getOr({}, buildApiUrl.launch() as '/scorm/launch'),
  ),
)

export const scormLaunchIdSelector = createSelector(
  scormConfigSelector,
  scormLaunchSelectors.fetched,
  scormLaunchSelectors.data,
  (scormConfig, fetched, data: string) => {
    if (fetched && 'course_external_id' in scormConfig) {
      return pipe([get('launch_url'), split('/'), last])(data)
    }
    if (fetched && 'learnpath_external_id' in scormConfig) {
      return pipe([get('launch_url'), split('learningpath-invite='), last])(
        data,
      )
    }

    return null
  },
)
