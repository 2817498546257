import { concat, flatMap, forEach, map, omit, reduce } from 'lodash/fp'

import { compactObject } from '@masterplandev/utils'

import { AssignedLearnpath } from '@/api/generated-api-and-types'
import { QueryKeys } from '@/api/queryKeys'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import { queryClient } from '@/core/utils/react-query/queryClient'
import initialiseReducerState from '@/core/utils/redux/initialiseReducerState'
import mergeStateAll from '@/core/utils/redux/mergeStateAll'
import { ELEMENT_TYPE_PLATFORM_TOPIC } from '@/learnpaths/constants'

import buildApiUrl from './buildApiUrl'

function normaliseAndMergeLearnpaths(
  stateToMergeWith,
  learnpaths: AssignedLearnpath[],
) {
  // Data seeding and syncing between what is inside redux and react-query
  // (react query is used inside learnpaths-assigned-element module).
  // The logic is executed from reducers and since redux is being removed in favour of
  // react-query inside learnpaths-assigned-element that kind of syncing is required.
  // The invocations are made:
  // frontend/src/lecture/actions/progress.actions.ts
  // frontend/src/learnpaths-assigned/utils/stateNormalise.ts
  // frontend/src/learnpaths-assigned/reducers/lectures.reducer.ts

  forEach((lp) => {
    forEach((element) => {
      queryClient.setQueryData(
        QueryKeys.learnpaths.assignedElement(lp?.id ?? '', element.id),
        element,
      )
    }, lp.elements)
  }, learnpaths)

  return mergeStateAll(
    stateToMergeWith,
    // Convert each into learnpath entry and list of element entries.
    flatMap(
      (learnpath) =>
        concat(
          // Learnpath entry with normalized list of elements.
          {
            [buildApiUrl.assigned(learnpath.id)]: {
              ...initialiseReducerState(),
              data: {
                ...omit('elements', learnpath),
                // There's some optimization made behind feature flag that makes /assigned
                // endpoint not to return elements for any learnpath. If such case, don't
                // attempt to store elements' ids.
                ...(learnpath.elements && {
                  elements: map('id', learnpath.elements),
                }),
              },
              fetched: true,
            },
          },
          // Elements mapped into element entries. normal object is
          // used not to mark resource as fetched because element from
          // learnpath API does not have all information about itself.
          map((element) => {
            const { assignedElement } = buildApiUrl
            const elementUrl = assignedElement(learnpath?.id ?? '', element.id)

            // Initialise state with common properties
            // but do not override them.
            return {
              [elementUrl]: mergeStateAll(initialiseReducerState(), [
                stateToMergeWith[elementUrl],
                {
                  data: element,
                },
              ]),
              ...(element.type === ELEMENT_TYPE_PLATFORM_TOPIC &&
                reduce(
                  (state, lecture) => {
                    const lectureUrl = buildLectureLink({
                      topic: element.topic.slug,
                      lecture: lecture.slug,
                      learnpathId: learnpath.id,
                      elementId: element.id,
                    })

                    return {
                      ...state,
                      [lectureUrl]: mergeStateAll({}, [
                        stateToMergeWith[lectureUrl],
                        {
                          data: {
                            progress: compactObject(lecture.progress),
                          },
                        },
                      ]),
                    }
                  },
                  {},
                  element.topic.lectures,
                )),
            }
          }, learnpath.elements),
        ),
      learnpaths,
    ),
  )
}

export default { normaliseAndMergeLearnpaths }
