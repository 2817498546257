import { rest } from 'lodash/fp'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { Center } from '@masterplandev/ui'
import { someTrue } from '@masterplandev/utils'

import actions from '@/actions'
import { useAuth } from '@/auth/hooks/useAuth'
import {
  companyLoadingSelector,
  companyRequiresFetchSelector,
} from '@/company/selectors'
import Loader from '@/core/components/ui/Loader/Loader'
import {
  scoreLoadingSelector,
  scoreRequiresFetchSelector,
} from '@/dashboard/selectors'
import {
  injectPollTypeProp,
  loadingSelector as pollLoadingSelector,
  requiresFetchSelector as pollRequiresFetchSelector,
} from '@/poll/selectors'
import {
  loadingSelector as userLoadingSelector,
  requiresFetchSelector as userRequiresFetchSelector,
} from '@/user/selectors'

interface Props {
  children: React.ReactNode
}

const loadingSelector = createSelector(
  userLoadingSelector,
  companyLoadingSelector,
  injectPollTypeProp('signup', pollLoadingSelector),
  scoreLoadingSelector,
  rest(someTrue),
)

export function AppManagerFetch(props: Props) {
  const { isAuthenticated } = useAuth()
  const dispatch = useDispatch()
  const userRequiresFetch = useSelector(userRequiresFetchSelector)
  const scoreRequiresFetch = useSelector(scoreRequiresFetchSelector)
  const signupPollRequiresFetch = useSelector(
    injectPollTypeProp('signup', pollRequiresFetchSelector),
  )
  const companyRequiresFetch = useSelector(companyRequiresFetchSelector)
  const loading = useSelector(loadingSelector)

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    if (userRequiresFetch) {
      dispatch(actions.user.get())
    }

    if (companyRequiresFetch) {
      dispatch(actions.company.get())
    }

    if (signupPollRequiresFetch) {
      dispatch(actions.poll.get({ type: 'signup' }))
    }

    if (scoreRequiresFetch) {
      dispatch(actions.dashboard.getScore())
    }
  }, [
    userRequiresFetch,
    isAuthenticated,
    companyRequiresFetch,
    signupPollRequiresFetch,
    scoreRequiresFetch,
    dispatch,
  ])

  const isLoading = () => (isAuthenticated ? loading : false)

  if (isLoading()) {
    return (
      <Center position="fixed" inset="0">
        <Loader />
      </Center>
    )
  }

  return <>{props.children}</>
}
