import { get, getOr, negate, omit, some } from 'lodash/fp'
import { createSelector, createStructuredSelector } from 'reselect'

import {
  API_URL_INVITES,
  API_URL_SELF_INVITE,
  SIGNUP_ROLE_MANAGER,
  SIGNUP_STEPS_COUNT_MANAGER,
  SIGNUP_STEPS_COUNT_STUDENT,
} from '@/core/constants/constants'
import {
  createRouteParamSelector,
  debugSelector,
  generateBasicSelectors,
} from '@/core/selectors'
import { dataSelector as userSelector } from '@/user/selectors'

export const rootSelector = get('signup')

export const signupRootSelector = createSelector(
  rootSelector,
  getOr({}, API_URL_INVITES),
)
export const metaSelector = createSelector(signupRootSelector, omit(['data']))
export const dataSelector = createSelector(signupRootSelector, get('data'))
export const emailSelector = createSelector(dataSelector, get('email'))
export const idSelector = createSelector(dataSelector, get('id'))
export const roleSelector = createSelector(dataSelector, get('role'))
export const errorSelector = createSelector(metaSelector, get('error'))
export const requiresFetchSelector = createSelector(
  metaSelector,
  // @ts-ignore Not sure if this line is correct, while enabling TS leaving this as is.
  negate(some(null)),
)
export const fetchedSelector = createSelector(metaSelector, get('fetched'))

export const selfSignupRootSelector = createSelector(
  rootSelector,
  getOr({}, API_URL_SELF_INVITE),
)
export const {
  data: selfSignupDataSelector,
  meta: selfSignupMetaSelector,
  error: selfSignupErrorSelector,
  fetching: selfSignupFetchingSelector,
  fetched: selfSignupFetchedSelector,
  failed: selfSignupFailedSelector,
  loading: selfSignupLoadingSelector,
  requiresFetch: selfSignupRequiresFetchSelector,
} = generateBasicSelectors(selfSignupRootSelector)

export const uuidSelector = createSelector(
  createRouteParamSelector('uuid'),
  idSelector,
  (fromRoute, fromState) => fromRoute || fromState,
)

export const credentialsFormSelector = createStructuredSelector({
  email: emailSelector,
})

export const companySelector = createSelector(
  dataSelector,
  getOr({}, 'company'),
)

export const stepsSelector = createSelector([roleSelector], (role) => {
  const steps =
    role === SIGNUP_ROLE_MANAGER
      ? SIGNUP_STEPS_COUNT_MANAGER
      : SIGNUP_STEPS_COUNT_STUDENT
  return steps + 1
})

export const authenticatedSelector = createSelector(
  [
    userSelector,
    dataSelector,
    metaSelector,
    (state, { active }) => active,
    debugSelector,
  ],
  (
    user: { id: string; email: string },
    signup,
    { failed, fetched },
    active,
    debug,
  ) => {
    if (failed) {
      // Fetching invite data failed - invite id doesn't exist
      return false
    }

    if (!fetched || debug) {
      return true
    }

    if (get('active', signup)) {
      // Invite id has been used before, can't create another account
      return false
    }

    if (active === 2) {
      return !!user.email || !!signup.email
    }

    if (active === 3) {
      return !!user.id
    }

    return true
  },
)
