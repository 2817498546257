import { useKeycloak } from '@react-keycloak/web'
import { Redirect, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import Loader from '@/core/components/ui/Loader/Loader'
import { URL_DASHBOARD } from '@/core/constants/constants'

import { useNoEmailUsersGet } from '../../hooks/useNoEmailUsersGet'

export function NoEmailRegisterRedirect() {
  const { keycloak } = useKeycloak()
  const { ssoSourceId } = useParams<{ ssoSourceId }>()
  const noEmailUsersGet = useNoEmailUsersGet({ ssoSourceId })
  const locale = useSearchParam('locale') || undefined

  if (keycloak.authenticated) {
    return <Redirect to={URL_DASHBOARD} />
  }

  if (noEmailUsersGet.isPending) {
    return <Loader />
  }

  if (noEmailUsersGet.isSuccess && keycloak.authServerUrl) {
    const url = new URL(
      `auth/realms/${keycloak.realm}/no-email-users/registration`,
      keycloak.authServerUrl,
    )
    url.searchParams.set('sso_source_id', ssoSourceId)
    if (locale) {
      url.searchParams.set('lang', locale)
    }
    window.location.replace(url.toString())
    return null
  }

  const url = keycloak.createRegisterUrl({ locale })
  window.location.replace(url.toString())
  return null
}
