import { get } from 'lodash/fp'

import mergeState from './mergeState'

export const mergeErrorAction = (state, { meta, error }, { url } = {}) => {
  const previousOptionsUrl = get('previousAction.payload.options.url', meta)
  const previousRequestUrl = get('previousAction.payload.request.url', meta)
  const urlToUse = url ?? previousOptionsUrl ?? previousRequestUrl

  return mergeState(state, {
    [urlToUse]: {
      fetching: false,
      failed: true,
      error: get('response.data', error),
      error_status: get('response.status', error),
    },
  })
}

export default mergeErrorAction
