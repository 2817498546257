import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'

import { topicsQueries } from '@/http/api/topics'

interface Args {
  enabled?: boolean
  slug?: string
}

export function useTopicDetails({ slug = '', enabled = true }: Args) {
  const dispatch = useDispatch()

  return useQuery({
    enabled: enabled && !!slug,
    ...topicsQueries.details(slug, dispatch),
  })
}
