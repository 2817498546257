/* eslint-disable @typescript-eslint/dot-notation */

/* eslint-disable dot-notation */
import { dpr } from '@cloudinary/url-gen/actions/delivery'
import { CloudinaryImage } from '@cloudinary/url-gen/assets/CloudinaryImage'

import { cloudinary } from './cloudinary'

/**
 * Converts cloudinary image to list of
 * image file URLs, along with dpr descriptions
 *
 * @param {CloudinaryImage} image - cloudinary image
 * @param {number[]} dprList - list of dpr values
 */
export function dprSourceSet(
  image: CloudinaryImage,
  dprList: number[] = [1, 2, 3],
) {
  return dprList
    .map((dprValue) => {
      const src = cloudinary
        .image(image['publicID'])
        .addTransformation(image['transformation'])
        .delivery(dpr(dprValue))
        .toURL()

      return `${src} ${dprValue}x`
    })
    .join()
}
