export const APP_LOCALE_FEATURED = ['es', 'fr', 'it'] as const
export const APP_LOCALE_FALLBACK = 'en'
export const APP_LOCALE = ['de', 'en', ...APP_LOCALE_FEATURED] as const

export const CONTENT_LOCALE = [
  ...APP_LOCALE,
  'cs',
  'hu',
  'pl',
  'pt',
  'ru',
  'nl',
] as const
