import {
  cond,
  entries,
  filter,
  isObject,
  last,
  negate,
  pipe,
  reduce,
  size,
  stubTrue,
  toString,
} from 'lodash/fp'

/*
 * This function is only one level deep for the sake of clarity and simplicity.
 * It means that we should call cached selectors with plain value like
 * string, number or boolean or object that does not consist of other object.
 * The function will have no effect for more complex structured resulting in
 * no optimisation boost whatsoever.
 *
 * @ at the beginning of the key is for the case when empty object is passed at
 * all, but the key has to generated anyway.
 * @ is returned if no parameter was passed to cache such a 'default' selector.
 */
export default function cachedKeyCreator(...args) {
  if (size(args) === 1) {
    return '@'
  }

  if (typeof last(args) === 'function') {
    return '@'
  }

  return pipe(
    last,
    cond([
      [
        isObject,
        pipe(
          entries,
          filter(pipe([last, negate(isObject)])),
          reduce((acc, [key, val]) => `${acc}:${key}-${val}`, '@'),
        ),
      ],
      [stubTrue, toString],
    ]),
  )(args)
}
