import { concat, get, getOr, includes } from 'lodash/fp'
import { combineActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeState from '@/core/utils/redux/mergeState'

import actions from '../actions/join.actions'
import buildApiUrl from '../utils/buildApiUrl'
import stateNormalise from '../utils/stateNormalise'

const [, JOIN_POST_SUCCESS, JOIN_POST_FAILURE] = getActionTypes({
  type: actions.join.post,
})

const FAILURE_ACTIONS = combineActions(JOIN_POST_FAILURE)

export default {
  [JOIN_POST_SUCCESS]: (state, action) => {
    const learnpathId = get('payload.data.id', action)

    if (learnpathId) {
      const listUrl = buildApiUrl.assigned()
      const idsList = getOr([], [listUrl, 'data'], state)

      const learnpath = get('payload.data', action)

      if (includes(learnpathId, idsList)) {
        return stateNormalise.normaliseAndMergeLearnpaths(state, [learnpath])
      }

      return stateNormalise.normaliseAndMergeLearnpaths(
        mergeState(state, {
          [listUrl]: {
            data: concat(idsList, learnpathId),
          },
        }),
        [learnpath],
      )
    }

    return state
  },
  [FAILURE_ACTIONS]: mergeErrorAction,
}
