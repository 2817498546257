import { mergeAll } from 'lodash/fp'

import addPlatformTopicActions from './actions/addPlatformTopic.actions'
import addUsersActions from './actions/addUsers.actions'
import overviewActions from './actions/overview.actions'
import userGroups from './actions/userGroups.actions'

export default mergeAll([
  addPlatformTopicActions,
  addUsersActions,
  overviewActions,
  userGroups,
])
