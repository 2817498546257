import {
  defaults,
  extendAllWith,
  flatMap,
  fromPairs,
  get,
  identity,
  isEmpty,
  map,
  mapValues,
  pickBy,
  pipe,
  toPairs,
} from 'lodash/fp'

import { mapAdaptive, mapValuesWithKey } from '@masterplandev/utils'

/**
 * @param {object} params - function params
 * @param {Function} params.pickPredicate -
 * function returning array of properties to use for recursive execution
 * @param {Function} params.storeSumResultKey -
 * function returning property name to store aggregate results
 *
 * @returns {Function}
 * function which recursively goes through complex object and stores count of detected descendants
 *
 * @example
 *
 * createDeepAggregate({
 *   pickPredicate: includesKey([ 'children', 'nodes' ]),
 *   storeSumResultKey: key => `num_${key}`,
 * })(data)
 */
export const createDeepAggregate = ({ pickPredicate, storeSumResultKey }) => {
  function deepAggregate(data, key) {
    const setStoreKey = key
      ? defaults({ [storeSumResultKey(key)]: 1 })
      : identity

    const pickedForProcessing = pickBy(pickPredicate, data)
    if (isEmpty(pickedForProcessing)) {
      return { data, sums: setStoreKey({}) }
    }

    const processed = mapValuesWithKey(
      (subdata, subkey) =>
        mapAdaptive((child) => deepAggregate(child, subkey), subdata),
      pickedForProcessing,
    )

    const recursiveResults = mapValues(mapAdaptive(get('data')), processed)

    const aggregateInitials = pipe([
      toPairs,
      map(([subkey]) => [storeSumResultKey(subkey), 0]),
      fromPairs,
    ])(recursiveResults)

    const aggregateResults = pipe([
      flatMap(map(get('sums'))),
      extendAllWith((a, b) => (a || 0) + (b || 0)),
      defaults(aggregateInitials),
    ])(processed)

    return {
      data: {
        ...data,
        ...recursiveResults,
        ...aggregateResults,
      },
      sums: setStoreKey(aggregateResults),
    }
  }

  return pipe([
    deepAggregate,
    (result) => ({
      ...result.data,
      ...result.sums,
    }),
  ])
}

export default createDeepAggregate
