import { flatten, intersection } from 'lodash/fp'
import { useContext } from 'react'

import { FeatureContext } from './FeatureContext'
import { FeatureName } from './FeatureName'

// Temporary solution with string array.
export default function useFeatures(
  ...requested: (FeatureName | FeatureName[])[]
) {
  const features = useContext(FeatureContext)
  const required = flatten(requested)

  return intersection(features, required).length === required.length
}
