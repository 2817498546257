import { RecursiveActionMap, createActions } from 'redux-actions'

import buildApiUrl from '../utils/buildApiUrl'

const actions = createActions({
  LEARNPATHS: {
    JOIN: {
      POST: ({ sharingLinkId, scormEnabled }) => ({
        request: {
          method: 'POST',
          url: buildApiUrl.join(sharingLinkId),
          params: {
            assignment_method: scormEnabled ? 'scorm' : 'link',
          },
        },
        sharingLinkId,
      }),
    },
  },
}) as RecursiveActionMap

export default actions.learnpaths
