export function urlParamsToObject(urlParams: URLSearchParams) {
  const paramsObject = {}

  urlParams.forEach((value, key) => {
    if (paramsObject[key]) {
      // If the key already exists, convert it to an array and push the new value
      if (Array.isArray(paramsObject[key])) {
        paramsObject[key].push(value)
      } else {
        paramsObject[key] = [paramsObject[key], value]
      }
    } else {
      paramsObject[key] = value
    }
  })

  return paramsObject
}
