import { Promise } from 'bluebird'
import { get, includes } from 'lodash/fp'
import { createActions } from 'redux-actions'

import createRequestAction from '@/core/utils/redux/createRequestAction'

import {
  BOOKMARKS_DOWNLOAD_ENDPOINT,
  BOOKMARKS_ENDPOINT,
  COURSE_ENDPOINT,
  CURRENT_LECTURE_ENDPOINT,
  SCORE_ENDPOINT,
} from './constants'
import {
  courseRequiresFetchSelector,
  scoreRequiresFetchSelector,
} from './selectors'

const actions = createActions({
  DASHBOARD: {
    GET_BOOKMARKS: createRequestAction(BOOKMARKS_ENDPOINT),
    GET_BOOKMARKS_DOWNLOAD: createRequestAction(BOOKMARKS_DOWNLOAD_ENDPOINT),
    POST_BOOKMARKS_DOWNLOAD: createRequestAction(
      BOOKMARKS_DOWNLOAD_ENDPOINT,
      'POST',
    ),
    GET_COURSE: createRequestAction(COURSE_ENDPOINT),
    GET_SCORE: () => ({
      options: { returnRejectedPromiseOnError: false },
      request: { url: SCORE_ENDPOINT },
    }),
    GET_CURRENT_LECTURE: () => ({
      options: { returnRejectedPromiseOnError: false },
      request: { url: CURRENT_LECTURE_ENDPOINT },
    }),
    CLEAR_ALL: () => ({}),
  },
})

function downloadBookmarks(contentCreator) {
  return (dispatch) =>
    dispatch(actions.dashboard.getBookmarksDownload())
      .then((response) => {
        const { url } = response.payload.data
        window.location = url
        return Promise.resolve({ url })
      })
      .catch(({ error }) => {
        const message = get('response.data.message', error)
        const triggerGet = [
          'bookmark.error.user-bookmarks-download-not-available',
        ]
        const triggerPost = [
          'bookmark.error.user-bookmarks-download-not-found',
          'bookmark.error.user-bookmarks-download-not-up-to-date',
        ]
        if (includes(message, triggerGet)) {
          return Promise.delay(1000).then(
            () => dispatch(downloadBookmarks(contentCreator)),
            1000,
          )
        }
        if (includes(message, triggerPost)) {
          const content = contentCreator()
          return dispatch(actions.dashboard.postBookmarksDownload({ content }))
            .then(Promise.delay(1000))
            .then(() => dispatch(downloadBookmarks(contentCreator)))
        }
        return Promise.reject()
      })
}

const getCourseIfRequired = () => (dispatch, getState) =>
  courseRequiresFetchSelector(getState())
    ? dispatch(actions.dashboard.getCourse())
    : Promise.resolve()

const getScoreIfRequired = () => (dispatch, getState) =>
  scoreRequiresFetchSelector(getState())
    ? dispatch(actions.dashboard.getScore())
    : Promise.resolve()

export default {
  ...actions.dashboard,
  getScoreIfRequired,
  getCourseIfRequired,
  downloadBookmarks,
}
