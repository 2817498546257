import { createActions } from 'redux-actions'

import {
  learnpathUserGroupsSelectedIdsSelector,
  learnpathUserGroupsSelectors,
} from '../selectors/selectors'
import * as buildApiUrl from '../utils/buildApiUrl'

const actions = createActions({
  LEARNPATHS: {
    MANAGED: {
      USER_GROUPS: {
        GET: ({ learnpathId }) => ({
          options: { returnRejectedPromiseOnError: false },
          request: { url: buildApiUrl.userGroups(learnpathId) },
          learnpathId,
        }),
        PUT: ({ learnpathId, userGroupIds }) => ({
          request: {
            url: buildApiUrl.userGroups(learnpathId),
            method: 'PUT',
            data: { user_groups_ids: userGroupIds },
          },
        }),
        SELECTED: {
          TOGGLE: ({ learnpathId, userGroupId }) => ({
            learnpathId,
            userGroupId,
          }),
        },
      },
    },
  },
})

interface GetIRequiredProps {
  learnpathId: string
}

actions.learnpaths.managed.userGroups.getIfRequired =
  ({ learnpathId }: GetIRequiredProps) =>
  (dispatch, getState) => {
    if (
      learnpathUserGroupsSelectors.requiresFetch(getState(), { learnpathId })
    ) {
      return dispatch(
        actions.learnpaths.managed.userGroups.get({ learnpathId }),
      )
    }

    return Promise.resolve()
  }

interface UpdateProps {
  learnpathId: string
}

actions.learnpaths.managed.userGroups.update =
  ({ learnpathId }: UpdateProps) =>
  async (dispatch, getState) => {
    const userGroupIds = learnpathUserGroupsSelectedIdsSelector(getState(), {
      learnpathId,
    })

    return dispatch(
      actions.learnpaths.managed.userGroups.put({ learnpathId, userGroupIds }),
    )
  }

export default actions.learnpaths
