import { debounce } from 'lodash/fp'

import { eventTracker } from '@masterplandev/utils'

const MIXPANEL_DEBOUNCE_TIME = 5000

export const updateMixpanelLastLogin = debounce(MIXPANEL_DEBOUNCE_TIME, () => {
  eventTracker.people.set('Last Login', Date.now())
})

export default updateMixpanelLastLogin
