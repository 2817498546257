import { handleActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import actions from '@/actions'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

const [GET_REQUEST, GET_SUCCESS, GET_ERROR] = getActionTypes({
  type: actions.poll.get,
})

const initialState = {}

export default handleActions(
  {
    [GET_REQUEST]: mergeRequestAction,
    [GET_SUCCESS]: mergeSuccessAction,
    [GET_ERROR]: mergeErrorAction,
  },
  initialState,
)
