import { VerifyEmail } from '@/auth/components/VerifyEmail/VerifyEmail'
import { useAccessToken } from '@/auth/hooks/useAccessToken'
import { useAuth } from '@/auth/hooks/useAuth'

import { AppRouter } from './AppRouter'

export function App() {
  const { user } = useAuth()
  const { accessToken, createLoginUrl } = useAccessToken()

  if (accessToken) {
    window.location.replace(createLoginUrl(accessToken))
    return null
  }

  if (user?.email_verified === false) {
    return <VerifyEmail />
  }

  return <AppRouter />
}
