import { HOUR, MINUTE } from '@/core/constants/constants'

import { createApiMethod, type } from './utils/createApiMethod'

export type Activity =
  | LpAssignedToLearner
  | LpStoppedForLearner
  | LpFinishedByLearner
  | LpAssignedToLearnerForManager
  | LpUnassignedToLearnerForManager
  | LpReactivatedForLearner

interface ActivityBase {
  entity_type: 'user'
  timestamp: string
  entity_id: string
  id: string
}

type LearnerPayload = {
  lp_id: string
  lp_title: string
  lp_mandatory: boolean
  actor_id: string
}

type MangerPayload = {
  lp_id: string
  lp_title: string
  lp_mandatory: boolean
  actor_id: string
  user_id: string
  username: string
}

interface LpAssignedToLearner extends ActivityBase {
  activity_type: 'LearnpathAssignedToLearner'
  payload: LearnerPayload
}

interface LpStoppedForLearner extends ActivityBase {
  activity_type: 'LearnpathStoppedForLearner'
  payload: LearnerPayload
}

interface LpFinishedByLearner extends ActivityBase {
  activity_type: 'LearnpathFinishedByLearner'
  payload: LearnerPayload
}

interface LpReactivatedForLearner extends ActivityBase {
  activity_type: 'LearnpathReactivatedForLearner'
  payload: LearnerPayload
}

interface LpAssignedToLearnerForManager extends ActivityBase {
  activity_type: 'LearnpathAssignedToLearnerForManager'
  payload: MangerPayload
}

interface LpUnassignedToLearnerForManager extends ActivityBase {
  activity_type: 'LearnpathUnassignedToLearnerForManager'
  payload: MangerPayload
}

export type ActivityListResponse = {
  activities: Activity[]
  total: number
}
export type ActivityListSort = 'timestamp' | 'activity_type'
export type ActivityListOrder = 'asc' | 'desc'
export interface ActivityListQuery {
  size: number
  page: number
  activity_type?: Activity['activity_type']
  order: ActivityListOrder
  sort: ActivityListSort
}

export const activity = {
  student: {
    list: createApiMethod({
      method: 'GET',
      path: '/activity/student',
      response: type<ActivityListResponse>(),
      query: type<ActivityListQuery>(),
    }),
  },

  manager: {
    list: createApiMethod({
      method: 'GET',
      path: '/activity/manager',
      response: type<ActivityListResponse>(),
      query: type<ActivityListQuery>(),
    }),
  },

  knockUserToken: {
    details: createApiMethod({
      method: 'GET',
      path: '/activity/knock-user-token',
      response: type<{
        knock_user_token: string
      }>(),
    }),
  },
}

export const activityQueries = {
  all: () => ['activity'] as const,
  managersLists: () => [...activityQueries.all(), 'managers-list'] as const,
  managersList: (params: ActivityListQuery) => ({
    queryKey: [...activityQueries.managersLists(), params] as const,
    queryFn: () => activity.manager.list({ query: params }),
    staleTime: 5 * MINUTE,
  }),
  studentsLists: () => [...activityQueries.all(), 'student-list'] as const,
  studentsList: (params: ActivityListQuery) => ({
    queryKey: [...activityQueries.studentsLists(), params] as const,
    queryFn: () => activity.student.list({ query: params }),
    staleTime: 5 * MINUTE,
  }),
  knockUserTokenDetails: () => ({
    queryKey: [...activityQueries.all(), 'knock-user-token-detail'] as const,
    queryFn: () => activity.knockUserToken.details(),
    staleTime: 12 * HOUR, // token is valid for 24 hours
  }),
}
