import { curryN, pickBy, pipe } from 'lodash/fp'

import { clearFalsy, mapAdaptive, mapValuesWithKey } from '@masterplandev/utils'

import nulledEmptyResult from './nulledEmptyResult'

/**
 * @param {object} params - function params
 * @param {Function} params.predicate - function determining if record should be kept
 * @param {Function} params.pickPredicate -
 * function returning array of properties to use for recursive execution
 *
 * @returns {Function}
 * function which can be used inside a selector to filter out records user is searching for
 *
 * @example
 * createDeepFilter({
 *   predicate: _.get('keep'),
 *   pickPredicate: includesKey([ 'children', 'nodes' ]),
 * })(data)
 */
export const createDeepFilter = ({ predicate, pickPredicate }) => {
  function deepFilter(parentDecision, parentKey, data) {
    const decision = predicate(data, parentKey, parentDecision)

    const recursiveResults = pipe([
      pickBy(pickPredicate),
      mapValuesWithKey((pickedData, key) =>
        pipe([
          mapAdaptive((child) => deepFilter(decision, key, child)),
          clearFalsy,
        ])(pickedData),
      ),
    ])(data)

    return nulledEmptyResult({
      ...(decision ? data : null),
      ...recursiveResults,
    })
  }

  return curryN(3, deepFilter)(null, null)
}

export default createDeepFilter
