import { includes } from 'lodash/fp'

export const requestSanitizer = (request) => {
  if (includes('/auth/', request.url)) {
    return {
      ...request,
      body: null,
    }
  }

  if (includes('/chatbot/lecture', request.url)) {
    return {
      ...request,
      body: null,
    }
  }

  return request
}

export default requestSanitizer
