import { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnce } from 'react-use'

import actions from '@/actions'
import useScormConfiguration from '@/scorm/hooks/useScormConfiguration'

import { AppManagerScormConfigLearnpathId } from './AppManagerScormConfigLearnpathId'
import { AppManagerScormConfigTopic } from './AppManagerScormConfigTopic'

interface Props {
  children: ReactNode
  sendMessage: (type: string, payload: any) => void
}

export function AppManagerScormConfig({ children, sendMessage }: Props) {
  const dispatch = useDispatch()
  const config = useScormConfiguration()
  const {
    learnpathId,
    topicToShow,
    course_external_id,
    lockContent,
    learnpath_external_id,
  } = config

  useEffectOnce(() => {
    if (course_external_id || learnpath_external_id) {
      dispatch(
        actions.scorm.external.launch.get({
          courseExternalId: course_external_id,
          learnpathExternalId: learnpath_external_id,
        }),
      )
    }
  })

  if (topicToShow) {
    return (
      <AppManagerScormConfigTopic
        lockContent={lockContent}
        sendMessage={sendMessage}
        topicToShow={topicToShow}>
        {children}
      </AppManagerScormConfigTopic>
    )
  }

  if (learnpathId) {
    return (
      <AppManagerScormConfigLearnpathId
        learnpathId={learnpathId}
        lockContent={lockContent}
        sendMessage={sendMessage}>
        {children}
      </AppManagerScormConfigLearnpathId>
    )
  }

  return children as JSX.Element
}
