import { useKeycloak } from '@react-keycloak/web'
import { get } from 'lodash/fp'
import { ReactElement, useEffect } from 'react'
import { useSearchParam } from 'react-use'

import useRedirectOmitParams from '@/core/hooks/useRedirectOmitParams'

interface Props {
  children?: ReactElement | null
}

const REDIRECT_URI_PARAM = 'redirect_uri'

export function AppManagerSSO({ children = null }: Props) {
  const { keycloak } = useKeycloak()
  const { redirect } = useRedirectOmitParams(REDIRECT_URI_PARAM)

  const redirectUri = useSearchParam(REDIRECT_URI_PARAM)

  useEffect(() => {
    // We should be able to redirect only to same origins uris.
    if (keycloak.authenticated && redirectUri) {
      try {
        if (get('location.origin', window) === new URL(redirectUri).origin) {
          // If redirection_uri is detected and it's safe, go to that state.
          window.location.replace(redirectUri)
        } else {
          redirect()
        }
      } catch (error) {
        // Clear wrong redirect_uri from the url.
        redirect()
      }
    }
  })

  if (keycloak.authenticated && redirectUri) {
    return null
  }

  return children
}
