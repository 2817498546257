import { createActions } from 'redux-actions'

import buildUniversalLectureApiUrl from '@/lecture/utils/buildUniversalLectureApiUrl'

import {
  currentQuestionIdSelector,
  questionCombineStepsSelector,
  questionStepAnswerSelector,
} from './selectors'

const actions = createActions({
  QUIZ: {
    GET: (params) => ({
      options: {
        url: buildUniversalLectureApiUrl(params, { suffix: 'quiz' }),
      },
      request: {
        url: buildUniversalLectureApiUrl(params, {
          suffix: 'quiz',
          withContext: true,
        }),
      },
    }),
    PUT: (params, data) => ({
      options: {
        url: buildUniversalLectureApiUrl(params, { suffix: 'quiz' }),
      },
      request: {
        url: buildUniversalLectureApiUrl(params, {
          suffix: 'quiz',
          withContext: true,
        }),
        method: 'put',
        data,
      },
    }),
    SKIP_QUESTION: (params, data) => ({
      options: {
        url: buildUniversalLectureApiUrl(params, { suffix: 'quiz' }),
      },
      request: {
        url: buildUniversalLectureApiUrl(params, {
          suffix: 'quiz',
          withContext: true,
        }),
        method: 'put',
        data: { ...data, skip: true },
      },
    }),
    STEP: (params, data) => ({
      options: {
        url: buildUniversalLectureApiUrl(params, { suffix: 'quiz' }),
      },
      request: {
        url: buildUniversalLectureApiUrl(params, {
          suffix: 'quiz/step',
          withContext: true,
        }),
        method: 'put',
        data,
      },
    }),
    CLEAR_RESULT: (params) => ({
      url: buildUniversalLectureApiUrl(params, { suffix: 'quiz' }),
    }),
    COMPLETE_STEP: ({ params, answer_id }) => ({
      url: buildUniversalLectureApiUrl(params, { suffix: 'quiz' }),
      answer_id,
    }),
    MOCK_SUCCESS: (params) => ({
      url: buildUniversalLectureApiUrl(params, { suffix: 'quiz' }),
    }),
    MOCK_FAILURE: (params) => ({
      url: buildUniversalLectureApiUrl(params, { suffix: 'quiz' }),
    }),
    CLEAR_ALL: () => ({}),
  },
})

const nextStep = (props) => (dispatch, getState) => {
  const {
    match: { params },
  } = props
  const currentStep = questionStepAnswerSelector(getState(), props)
  dispatch(actions.quiz.completeStep({ params, answer_id: currentStep.id }))
  const hasNextStep = questionStepAnswerSelector(getState(), props)
  if (!hasNextStep) {
    const question_id = currentQuestionIdSelector(getState(), props)
    const answers_ids = questionCombineStepsSelector(getState(), props)

    dispatch(actions.quiz.put(params, { question_id, answers_ids }))
  }
}

export default {
  ...actions.quiz,
  nextStep,
}
