import { get, size } from 'lodash/fp'

import { getPercentage } from '@masterplandev/utils'

import { useTopicDetails } from '../queries/useTopicDetails'

export default function useTopicCompletionProgress(slug: string) {
  const { data: topic } = useTopicDetails({ slug })

  return (
    (getPercentage(
      get('progress.num_completed_lectures', topic),
      size(topic?.lectures),
    ) || 0) / 100
  )
}
