import { isEmpty, map } from 'lodash/fp'

import { everyTrue } from '@masterplandev/utils'

export const nulledEmptyResult = (value) => {
  const empty = isEmpty(value)
  const containsOnlyEmpty = everyTrue(map(isEmpty, value))
  if (empty || containsOnlyEmpty) {
    return null
  }
  return value
}

export default nulledEmptyResult
