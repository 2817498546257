import classNames from 'classnames'
import { get, toNumber } from 'lodash/fp'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { Icon } from '@masterplandev/ui'

import styles from './Notification.module.css'

export const NOTIFICATION_SUCCESS = 'success'
export const NOTIFICATION_WARN = 'warn'
export const NOTIFICATION_ERROR = 'error'

export default class Notification extends Component {
  ref = React.createRef()

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    inner: PropTypes.bool,
    adjustWrapper: PropTypes.bool,
    fixed: PropTypes.bool,
    timeout: PropTypes.number,
    type: PropTypes.oneOf([
      NOTIFICATION_SUCCESS,
      NOTIFICATION_ERROR,
      NOTIFICATION_WARN,
    ]),
  }

  static defaultProps = {
    className: null,
    children: null,
    type: null,
    inner: false,
    fixed: false,
    timeout: 0,
    adjustWrapper: true,
  }

  constructor(props) {
    super(props)
    this.timer = null
    this.state = { height: 0, show: true }
  }

  componentDidMount() {
    const { timeout } = this.props
    this.rootDOM = document.getElementById('root')
    window.addEventListener('resize', this.updateHeight)
    this.updateHeight()
    if (timeout > 0) {
      this.timer = setTimeout(() => {
        this.setState({ show: false, height: 0 })
      }, timeout)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateRootMarginTop(prevState.height, this.state.height)
  }

  componentWillUnmount() {
    this.updateRootMarginTop(this.state.height, 0)
    window.removeEventListener('resize', this.updateHeight)
    clearTimeout(this.timer)
  }

  getRefHeight = () => get('ref.current.offsetHeight', this)

  updateHeight = () => {
    const { adjustWrapper, inner } = this.props
    if (adjustWrapper && !inner) {
      this.setState({ height: this.getRefHeight() })
    }
  }

  updateRootMarginTop = (prevHeight, newHeight) => {
    if (this.rootDOM) {
      const { style } = this.rootDOM
      const currentMarginTop = toNumber(style.marginTop.replace('px', ''))
      style.marginTop = `${currentMarginTop - prevHeight + newHeight}px`
    }
  }

  render() {
    const { children, type, fixed } = this.props
    const { height, show } = this.state
    const className = classNames(
      styles.notification,
      get(type, styles),
      { [styles.fixed]: fixed },
      this.props.className,
    )

    if (!show) {
      return null
    }

    const symbol = {
      success: 'general-check-circle',
      warn: 'general-info-circle',
      error: 'alerts-alert-circle',
    }

    return (
      <div className={className} ref={this.ref}>
        <Icon className={styles.icon} symbol={symbol[type]} />
        {children || <FormattedMessage {...this.props} />}
        {height > 0 && <style>{`.fixed-flexible { top: ${height}px; }`}</style>}
      </div>
    )
  }
}
