import { get, merge } from 'lodash/fp'
import { handleActions } from 'redux-actions'

import actions from './actions'
import S3 from './utils/s3/s3.reducer'
import ScrollListener from './utils/scroll/ScrollListener/ScrollListener.reducer'

const initialState = {
  error: null,
  visible_tooltips: {},
}

export default handleActions(
  {
    [actions.error]: (state, action) => ({
      ...state,
      error: get('payload.type', action),
    }),
    [actions.tooltips.show]: (state, { payload: { groupName, tooltipId } }) =>
      merge(state, { visible_tooltips: { [groupName]: tooltipId } }),
    [actions.tooltips.hide]: (state, { payload: { groupName } }) =>
      merge(state, { visible_tooltips: { [groupName]: null } }),
    ...S3,
    ...ScrollListener,
  },
  initialState,
)
