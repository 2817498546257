import { isEmpty, last, map, pick, pipe } from 'lodash/fp'

import { someTrue } from '@masterplandev/utils'

import createDeepAggregate from './createDeepAggregate'
import createDeepFilter from './createDeepFilter'
import includesKey from './includesKey'
import isPhraseInText from './isPhraseInText'

/**
 * Creates function to filter complex object checking if given properties contain searched text.
 *
 * @param {object} params - function params
 * @param {string} params.search - text which user is searching for
 * @param {string[]} params.searchIn - list of property names which should be used for text search
 * @param {string[]} params.path -
 * list of property names for which function should be run recursively
 *
 * @returns {Function}
 * function which can be used for reducing object to elements containing searched text
 *
 * @example
 * createDeepTextSearch({
 *   search,
 *   searchIn: [ 'title', 'question', 'answer' ],
 *   path: [ 'topics', 'lectures', 'questions' ],
 * })(data)
 */
export const createDeepTextSearch = (params) => {
  const { search, searchIn, path } = params

  const SEARCH_FAILED = 0
  const SEARCH_SKIPPED = 1
  const SEARCH_SUCCEEDED = 2

  const predicate = (data, parentKey, parentDecision) => {
    if (parentDecision === SEARCH_SUCCEEDED) {
      return parentDecision
    }

    const activeFields = pick(searchIn, data)

    if (!isEmpty(activeFields)) {
      const searchResult = pipe([map(isPhraseInText(search)), someTrue])(
        activeFields,
      )

      if (searchResult) {
        return SEARCH_SUCCEEDED
      }

      if (parentKey === last(path)) {
        return SEARCH_FAILED
      }
    }

    return SEARCH_SKIPPED
  }

  const pickPredicate = includesKey(path)

  const storeSumResultKey = (key) => `num_${key}`

  const resultFunction = pipe([
    createDeepFilter({ predicate, pickPredicate }),
    createDeepAggregate({ pickPredicate, storeSumResultKey }),
  ])

  // Exporting predicate function for easier testing
  resultFunction.predicate = predicate
  resultFunction.SEARCH_FAILED = SEARCH_FAILED
  resultFunction.SEARCH_SKIPPED = SEARCH_SKIPPED
  resultFunction.SEARCH_SUCCEEDED = SEARCH_SUCCEEDED

  return resultFunction
}

export default createDeepTextSearch
