import { createActions } from 'redux-actions'

import createRequestAction from '@/core/utils/redux/createRequestAction'

import {
  highlightedTopicSelectors,
  internalRequiresFetchSelector,
  personalRequiresFetchSelector,
} from './selectors'

const actions = createActions({
  CHANNELS: {
    GET: createRequestAction('/channels'),
    GET_PERSONAL: createRequestAction('/channels/personal'),
    GET_INTERNAL: createRequestAction('/channels/internal'),
    GET_NEW_TOPICS: createRequestAction('/channels/new_topics'),
    GET_BEST_TOPICS: createRequestAction('/channels/best_topics'),
    GET_HIGHLIGHTED_TOPIC: () => ({
      options: { returnRejectedPromiseOnError: false },
      request: {
        url: '/channels/highlighted_topic',
      },
    }),
    CLEAR_ALL: () => ({}),
  },
})

actions.channels.getPersonalIfRequired = () => (dispatch, getState) =>
  personalRequiresFetchSelector(getState())
    ? dispatch(actions.channels.getPersonal())
    : Promise.resolve()

actions.channels.getInternalIfRequired = () => (dispatch, getState) =>
  internalRequiresFetchSelector(getState())
    ? dispatch(actions.channels.getInternal())
    : Promise.resolve()

actions.channels.getHighlightedTopicIfRequired = () => (dispatch, getState) =>
  highlightedTopicSelectors.requiresFetch(getState())
    ? dispatch(actions.channels.getHighlightedTopic())
    : Promise.resolve()

export default {
  ...actions.channels,
}
