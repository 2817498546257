import { concat, findIndex, get, getOr, isEqual, omit } from 'lodash/fp'
import { combineActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import { buildLink, removeAt, replaceAt } from '@masterplandev/utils'

import actions from '@/actions'
import composeReducers from '@/core/utils/redux/composeReducers'
import mergeFailureAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeState from '@/core/utils/redux/mergeState'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'
import modifyRequestUrl from '@/core/utils/redux/modifyRequestUrl'

import buildApiUrl from '../utils/buildApiUrl'

const [, PACKAGE_SUCCESS_POST, PACKAGE_FAILURE_POST] = getActionTypes({
  type: actions.scorm.package.post,
})

const [PACKAGE_REQUEST_GET, PACKAGE_SUCCESS_GET, PACKAGE_FAILURE_GET] =
  getActionTypes({
    type: actions.scorm.package.get,
  })

const [PACKAGE_REQUEST_PUT, PACKAGE_SUCCESS_PUT, PACKAGE_FAILURE_PUT] =
  getActionTypes({
    type: actions.scorm.package.put,
  })

const [PACKAGE_REQUEST_DELETE, PACKAGE_SUCCESS_DELETE, PACKAGE_FAILURE_DELETE] =
  getActionTypes({
    type: actions.scorm.package.delete,
  })

const REQUEST_ACTIONS = [
  PACKAGE_REQUEST_GET,
  PACKAGE_REQUEST_PUT,
  PACKAGE_REQUEST_DELETE,
]
const SUCCESS_ACTIONS = [
  PACKAGE_SUCCESS_GET,
  PACKAGE_SUCCESS_DELETE,
  PACKAGE_SUCCESS_PUT,
]
const FAILURE_ACTIONS = [
  PACKAGE_FAILURE_GET,
  PACKAGE_FAILURE_PUT,
  PACKAGE_FAILURE_POST,
  PACKAGE_FAILURE_DELETE,
]

// State normalisation. Packages are kept being added to the main list under
// /scorm/packages but the selector communication remains intact.
export function appendScormToPackagesList(state, { payload, meta }) {
  // Filling default status if backend does not return any.
  const element = get('data', payload)
  const listUrl = buildApiUrl.packages()
  const allList = getOr([], [listUrl, 'data', 'packages'], state)
  const scormId = get('previousAction.payload.scormPackageId', meta)
  const idIndex = findIndex({ id: scormId || get('id', element) }, allList)

  const updatedWithNewElement = () => {
    if (idIndex === -1) {
      return concat(
        {
          ...allList[idIndex],
          ...element,
          bytes:
            allList[idIndex]?.bytes ??
            getOr(
              element.bytes,
              'previousAction.payload.request.data.bytes',
              meta,
            ),
        },
        allList,
      )
    }

    const put = get('config.method', payload) === 'put'

    // Backend does not return data back when PUT was made.
    return replaceAt(
      idIndex,
      {
        ...allList[idIndex],
        ...(put ? get('previousAction.payload.request.data', meta) : element),
      },
      allList,
    )
  }

  const updatedWithoutElement = () => {
    return removeAt(idIndex, allList)
  }

  if (
    get('config.method', payload) === 'get' &&
    isEqual(allList[idIndex], element)
  ) {
    return state
  }

  const updated =
    get('config.method', payload) === 'delete'
      ? updatedWithoutElement()
      : updatedWithNewElement()

  // Fill original url's data with learnpaths' ids.
  return mergeState(state, {
    [listUrl]: {
      data: {
        packages: updated,
      },
    },
  })
}

export function addDataId(url, { payload }) {
  return buildLink(url, get('data.id', payload))
}

export default {
  [combineActions(...REQUEST_ACTIONS)]: mergeRequestAction,
  [combineActions(...FAILURE_ACTIONS)]: mergeFailureAction,
  [combineActions(...SUCCESS_ACTIONS)]: composeReducers(
    appendScormToPackagesList,
    (state, action, config) =>
      mergeSuccessAction(state, omit('payload.data', action), config),
  ),
  [PACKAGE_SUCCESS_POST]: composeReducers(
    appendScormToPackagesList,
    modifyRequestUrl(addDataId, (state, action, config) =>
      mergeSuccessAction(state, omit('payload.data', action), config),
    ),
  ),
}
