import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import actions from '@/actions'

interface Args {
  learnpathId: string
  useExisting?: boolean
}

export default function useAssignedLearnpathFetchIfRequired({
  learnpathId,
}: Args) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (learnpathId) {
      dispatch(
        actions.learnpathsAssigned.assigned.getIfRequired({ learnpathId }),
      )
    }
  })
}
