export const LECTURE_VIDEO_PLAYBACK_OFFSET_IN_SECONDS = 5

export const LECTURE_TYPE_LINK = 'link'
export const LECTURE_TYPE_PLATFORM_TOPIC = 'platform_topic'
export const LECTURE_TYPE_QUIZ = 'quiz'
export const LECTURE_TYPE_SCORM = 'scorm'
export const LECTURE_TYPE_SLIDESHOW = 'slideshow'
export const LECTURE_TYPE_TEXT = 'text'
export const LECTURE_TYPE_FILE = 'file'
export const LECTURE_TYPE_VIDEO = 'video'
export const LECTURE_TYPE_EMBEDDED_VIDEO = 'embedded_video'
export const LECTURE_TYPES = [
  LECTURE_TYPE_FILE,
  LECTURE_TYPE_LINK,
  LECTURE_TYPE_PLATFORM_TOPIC,
  LECTURE_TYPE_QUIZ,
  LECTURE_TYPE_SCORM,
  LECTURE_TYPE_SLIDESHOW,
  LECTURE_TYPE_TEXT,
  LECTURE_TYPE_VIDEO,
  LECTURE_TYPE_EMBEDDED_VIDEO,
]

export const LECTURE_IMAGE_ID_LINK = 'system/lecture/background-link'
export const LECTURE_IMAGE_ID_FILE = 'system/lecture/background-file'
export const LECTURE_IMAGE_ID_SCORM = 'system/lecture/background-scorm'
export const LECTURE_IMAGE_ID_EVENT = 'system/lecture/background-event'
export const LECTURE_IMAGE_ID_PDF = 'system/lecture/background-pdf'

export const LECTURE_API_SUFFIX_PROGRESS = 'progress'
