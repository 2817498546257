import { buildLink } from '@masterplandev/utils'

const base = '/scorm'

export default {
  auth() {
    return buildLink(base, 'auth')
  },

  packages() {
    return buildLink(base, 'packages')
  },

  package(packageId?: string) {
    return buildLink(base, 'package', packageId)
  },

  launch() {
    return buildLink('/launch')
  },
}
