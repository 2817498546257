import { get, includes, map, union, without } from 'lodash/fp'
import { getActionTypes } from 'redux-axios-middleware'

import mergeFailureAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeState from '@/core/utils/redux/mergeState'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

import actions from '../actions'

export const [
  USER_GROUPS_GET_REQUEST,
  USER_GROUPS_GET_SUCCESS,
  USER_GROUPS_GET_ERROR,
] = getActionTypes({ type: actions.managed.userGroups.get })

export default {
  [USER_GROUPS_GET_REQUEST]: mergeRequestAction,
  [USER_GROUPS_GET_SUCCESS]: (state, action) => {
    const learnpathId = get('meta.previousAction.payload.learnpathId', action)
    const list = get('payload.data.user_groups', action)
    const selectedIds = state.userGroups ? state.userGroups[learnpathId] : null

    return mergeState(mergeSuccessAction(state, action), {
      userGroups: {
        [learnpathId]: selectedIds || map('id', list),
      },
    })
  },
  [USER_GROUPS_GET_ERROR]: mergeFailureAction,

  [actions.managed.userGroups.selected.toggle]: (state, action) => {
    const { learnpathId, userGroupId } = action.payload
    const selectedIds = state.userGroups[learnpathId] || []

    return mergeState(state, {
      userGroups: {
        [learnpathId]: includes(userGroupId, selectedIds)
          ? without([userGroupId], selectedIds)
          : union([userGroupId], selectedIds),
      },
    })
  },
}
