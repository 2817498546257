export type InsertParamsIntoPathOptions = {
  path: string
  params: Record<string, string>
}

/**
 * Replaces params in path
 *
 * @param  options - Options
 * @param  options.path - Path to the endpoint e.g. /posts/:id
 * @param  options.params - Path params e.g. `{ id: string }`
 * @returns {string} - URL with the params e.g. /posts/123
 *
 * @example
 *  insertParamsIntoPath({ path: '/posts/:id', params: { id: '123' } })
 *  returns '/posts/123'
 */
export const insertParamsIntoPath = ({
  path,
  params,
}: InsertParamsIntoPathOptions) => {
  return path
    .replace(/:([^/]+)/g, (_, p) => {
      return (params as any)[p] || ''
    })
    .replace(/\/\//g, '/')
}
