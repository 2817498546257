import { difference, isEmpty } from 'lodash/fp'
import { createActions } from 'redux-actions'

import {
  candidatesSelectors,
  learnpathMembersIdsSelector,
  learnpathMembersSelectors,
} from '../selectors/selectors'
import * as buildApiUrl from '../utils/buildApiUrl'

const actions = createActions({
  LEARNPATHS: {
    MANAGED: {
      SELECT_FILTER: (data, learnpathId) => ({
        data,
        options: {
          url: buildApiUrl.managed(learnpathId),
        },
      }),
      UNMARK_FILTER: (data, learnpathId) => ({
        data,
        options: {
          url: buildApiUrl.managed(learnpathId),
        },
      }),
      CANDIDATES: {
        SELECT: (data, learnpathId) => ({
          data,
          options: {
            url: buildApiUrl.candidates(learnpathId),
          },
        }),
        UNMARK: (data, learnpathId) => ({
          data,
          options: {
            url: buildApiUrl.candidates(learnpathId),
          },
        }),
        GET: ({ learnpathId, lookFor }) => ({
          options: {
            returnRejectedPromiseOnError: false,
            url: buildApiUrl.candidates(learnpathId),
          },
          request: {
            url: buildApiUrl.candidates(learnpathId, lookFor),
          },
          lookForTerm: !!lookFor,
        }),
      },
      MEMBERS: {
        MOVE: ({ learnpathId }) => ({
          sourceUrl: buildApiUrl.members(learnpathId),
          moveToUrl: buildApiUrl.candidates(learnpathId),
        }),
        GET: ({ learnpathId }) => ({
          options: { returnRejectedPromiseOnError: false },
          request: {
            url: buildApiUrl.members(learnpathId),
          },
          learnpathId,
        }),
        POST: ({ learnpathId, candidates }) => ({
          request: {
            url: buildApiUrl.members(learnpathId),
            method: 'POST',
            data: {
              users_ids: candidates,
            },
          },
          learnpathId,
        }),
        DELETE: ({ learnpathId, candidates }) => ({
          request: {
            url: buildApiUrl.members(learnpathId),
            method: 'DELETE',
            data: {
              users_ids: candidates,
            },
          },
          learnpathId,
        }),
      },
    },
  },
})

actions.learnpaths.managed.candidates.getIfRequired =
  ({ learnpathId }) =>
  (dispatch, getState) => {
    if (candidatesSelectors.requiresFetch(getState(), { learnpathId })) {
      return dispatch(
        actions.learnpaths.managed.candidates.get({ learnpathId }),
      )
    }

    return Promise.resolve()
  }

actions.learnpaths.managed.members.getIfRequired =
  ({ learnpathId }) =>
  (dispatch, getState) => {
    if (learnpathMembersSelectors.requiresFetch(getState(), { learnpathId })) {
      return dispatch(actions.learnpaths.managed.members.get({ learnpathId }))
    }

    return Promise.resolve()
  }

actions.learnpaths.managed.members.update =
  ({ learnpathId, candidates }) =>
  async (dispatch, getState) => {
    const membersIds = learnpathMembersIdsSelector(getState(), { learnpathId })

    const membersAdd = difference(candidates, membersIds)
    if (!isEmpty(membersAdd)) {
      await dispatch(
        actions.learnpaths.managed.members.post({
          learnpathId,
          candidates: membersAdd,
        }),
      )
    }

    const membersDel = difference(membersIds, candidates)
    if (!isEmpty(membersDel)) {
      await dispatch(
        actions.learnpaths.managed.members.delete({
          learnpathId,
          candidates: membersDel,
        }),
      )
    }

    return Promise.resolve()
  }

export default actions.learnpaths
