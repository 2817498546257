import { includes, isObject, reduce } from 'lodash/fp'

type MethodsOfParamsDelivery = 'json' | 'queryparam' | 'form'

interface ApiErrorBody {
  messages?: Record<
    string,
    Record<MethodsOfParamsDelivery | string, string | string[]> | string
  >
}

/**
 * Drops property and moves its value level up in an object
 *
 * @param inputObj - object to be processed
 * @param keysToMove - properties to be drop with values moved (if value is an obejct)
 * @example moveProperties({
 *    youStay: { sth: 33 },
 *    youShouldLeave: { example: true },
 *    youAreNotWelcomeHere: { toBeMoved: true },
 *    youWillStay: true // this one is not moved beacuse it is not an object
 *  }
 * , ['youShouldLeave', 'youAreNotWelcomeHere', 'youWillStay'])
 */
const moveProperties = (inputObj, keysToMove: string[]) => {
  // @ts-ignore
  const reduceWithKeys = reduce.convert({ cap: false })

  return reduceWithKeys((result, value, key) => {
    if (includes(key, keysToMove) && isObject(value)) {
      return {
        ...result,
        ...value,
      }
    }

    // eslint-disable-next-line no-param-reassign
    result[key] = value
    return result
  }, {})(inputObj)
}

/**
 * Remove delivery method param from API error
 *
 * @param apiErrorObj - standard API error body
 *
 * @example skipMethodOfParamsDelivery({
 *    "messages":{
 *       "method-of-params-delivery":{ // can be for example "form", "json", "querystring"
 *          "field-name":[
 *             "<list of stringified errors>"
 *          ]
 *       }
 *    }
 * })
 *
 * @returns {object} - object without supported method-of-params-delivery {
 *    "messages":{
 *          "field-name":[
 *             "<list of stringified errors>"
 *          ]
 *    }
 * }
 */
const skipMethodOfParamsDelivery = (apiErrorObj: ApiErrorBody = {}) => {
  const { messages } = apiErrorObj

  // nothing to do here
  // we are allowed to only process messages property
  if (!messages) {
    return apiErrorObj
  }

  const transformedMessages = moveProperties(messages, [
    'json',
    'queryparam',
    'form',
  ])

  return {
    ...apiErrorObj,
    messages: transformedMessages,
  }
}

export default skipMethodOfParamsDelivery
