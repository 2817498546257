import * as Sentry from '@sentry/react'
import { isCancel } from 'axios'
import { isRetryableError as isApiError } from 'axios-retry'
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router'
import { compact, includes, merge } from 'lodash/fp'
import LogRocket from 'logrocket'
import { applyMiddleware, compose, createStore } from 'redux'
import createAxiosMiddleware from 'redux-axios-middleware'
import { composeWithDevTools } from 'redux-devtools-extension'
import persistState, { mergePersistedState } from 'redux-localstorage'
import filter from 'redux-localstorage-filter'
import adapter from 'redux-localstorage/lib/adapters/localStorage'
import { createLogger } from 'redux-logger'
import createThrottleMiddleware from 'redux-throttle'
import thunkMiddleware from 'redux-thunk'

import actions from '@/actions'
import { ERROR_TYPE_API, LOCAL_STORAGE_ITEMS } from '@/core/constants/constants'
import { env } from '@/env'
import createRootReducer from '@/reducers'

import axios from './axios'
import isLocalStorageEnabled from './utils/localStorage/isLocalStorageEnabled'
import actionSanitizer from './utils/logrocket/actionSanitizer'
import stateSanitizer from './utils/logrocket/stateSanitizer'
import validateReduxState from './utils/redux/validateReduxState'
import createCancelAssetUploadMiddleware from './utils/s3/createCancelAssetUploadMiddleware'
import updateMixpanelLastLogin from './utils/tracking/updateMixpanelLastLogin'

const {
  REACT_APP_PERSIST_ALL,
  DEV,
  REACT_APP_LOGROCKET_KEY,
  REACT_APP_SENTRY_DSN,
  REACT_APP_REDUX_LOGGER_ENABLED,
} = env

const persistAll =
  REACT_APP_PERSIST_ALL || (isLocalStorageEnabled() && localStorage.persistAll)

export default function configureStore({ initialState = {}, history }) {
  const axiosMiddleware = createAxiosMiddleware(axios, {
    returnRejectedPromiseOnError: true,
    onComplete: updateMixpanelLastLogin,
    interceptors: {
      response: [
        {
          error: ({ dispatch }, error) => {
            if (isApiError(error) && !isCancel(error)) {
              dispatch(actions.core.error(ERROR_TYPE_API))
            }

            return Promise.reject(error)
          },
        },
      ],
    },
  })

  const routerMiddleware = createRouterMiddleware(history)

  const throttleMiddleware = createThrottleMiddleware(500, {
    leading: false,
    trailing: true,
  })

  const middlewares = [
    throttleMiddleware,
    axiosMiddleware,
    thunkMiddleware,
    routerMiddleware,
    createCancelAssetUploadMiddleware(),
  ]

  // Use this predicate for dev tools and logger to avoid spam.
  const predicate = (getState, action) =>
    !includes(action.type, [
      'CORE/REGIONS/ADD_REGION',
      'CORE/REGIONS/REMOVE_REGION',
    ])

  if (REACT_APP_REDUX_LOGGER_ENABLED) {
    middlewares.push(createLogger({ predicate, collapsed: true }))
  }

  if (REACT_APP_LOGROCKET_KEY) {
    middlewares.push(
      LogRocket.reduxMiddleware({ stateSanitizer, actionSanitizer }),
    )
  }

  const storage =
    isLocalStorageEnabled() &&
    compose(filter(persistAll ? null : ['form']))(adapter(window.localStorage))

  const enhancers = compact([
    applyMiddleware(...middlewares),
    storage ? persistState(storage, LOCAL_STORAGE_ITEMS.masterplan) : null,
    REACT_APP_SENTRY_DSN
      ? Sentry.createReduxEnhancer({
          actionTransformer: actionSanitizer,
          stateTransformer: stateSanitizer,
        })
      : null,
    validateReduxState,
  ])

  const enhancer = composeWithDevTools({ predicate })(...enhancers)

  const reducer = compose(
    mergePersistedState(merge),
    createRootReducer,
  )(history)

  const store = createStore(reducer, initialState, enhancer)

  // TODO: Failing test, it will be fixed in [MP-10691]
  // if (import.meta.hot) {
  //   import.meta.hot.accept('../reducers', () => {
  //     const nextRootReducer = require('../reducers')
  //     store.replaceReducer(nextRootReducer)
  //   })
  // }

  if (DEV) {
    window.store = store
    window.axios = axios
  }

  return store
}
