import { get, identity, omit } from 'lodash/fp'
import { createSelector } from 'reselect'

import { passProps } from '@masterplandev/utils'

import { MediaSummaryState } from '../types/MediaSummaryState'

const rootSelector = createSelector<MediaSummaryState, any, MediaSummaryState>(
  get('mediaSummary'),
  identity,
)
export const mediaSummaryPageIndexSelector = createSelector(
  rootSelector,
  (root) => root.pageIndex,
)
export const mediaSummaryPageSizeSelector = createSelector(
  rootSelector,
  (root) => root.pageSize,
)
export const mediaSummaryPageSortingSelector = createSelector(
  rootSelector,
  (root) => root.pageSorting,
)
export const mediaSummaryDetailsAnimationMediaIdSelector = createSelector(
  rootSelector,
  (root) => root.detailsAnimationMediaId,
)
export const mediaSummaryDialogSelector = createSelector(
  rootSelector,
  (root) => root.dialog,
)
export const mediaSummaryMediaIdSelector = createSelector(
  rootSelector,
  (root) => root.mediaId,
)
export const mediaSummarySelectedRowsSelector = createSelector(
  rootSelector,
  (root) => root.selectedRows,
)
export const mediaSummaryShowOnlySelectedSelector = createSelector(
  rootSelector,
  (root) => root.showOnlySelected,
)
export const mediaSummaryTabSelector = createSelector(
  rootSelector,
  (root) => root.tab,
)
export const mediaSummaryFilterSelector = createSelector(
  [rootSelector, passProps],
  (state, { filter }) => get(['filters', filter], state),
)
export const mediaSummaryOmitFiltersSelector = createSelector(
  [rootSelector, passProps],
  (state, props) => omit(props?.filters ?? [], state.filters),
)
