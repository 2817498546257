import { createActions } from 'redux-actions'

import { buildUrl } from './utils'

const actions = createActions({
  POLL: {
    GET: (params) => ({
      options: { returnRejectedPromiseOnError: false },
      request: {
        url: buildUrl(params),
      },
    }),
    POST: (poll_id, data) => ({
      request: {
        url: `/polls/${poll_id}/results`,
        method: 'post',
        data,
      },
    }),
  },
})

export default actions.poll
