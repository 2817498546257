import type { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { isObject } from 'lodash/fp'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'

export type Translate = {
  (
    messageIdSuffix: string,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    options?: { withPrefix?: string | null; defaultMessage?: string },
  ): string

  <T = any, R = T>(
    messageIdSuffix: string,
    values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T, R>>,
    options?: { withPrefix?: string | null; defaultMessage?: string },
  ): R

  getPrefix?: () => string | undefined
}

/**
 * A simple wrapper of `formatMessage` function in react-intl
 * to translate content with shorter notation
 *
 * @param messageIdPrefix - message ID prefix.
 *
 * @example
 * const { translate } = useTranslation('company.import-users')
 * <div>translate('title')</div>
 */
export default function useTranslation(messageIdPrefix?: string) {
  const { formatMessage } = useIntl()

  /**
   * The `translate` function wraps the` formatMessage` function
   * with a predefined message id prefix
   *
   * @param messageIdSuffix  - message ID suffix.
   * @param values - message values.
   *
   * @example translate('title')
   */
  const translate = useCallback<Translate>(
    (messageIdSuffix, values, options) => {
      // Do no insert initial dot if prefix was marked to be null.
      try {
        if (options?.withPrefix === null || !messageIdPrefix) {
          return formatMessage({ id: messageIdSuffix }, values)
        }

        return formatMessage(
          {
            id: `${options?.withPrefix ?? messageIdPrefix}.${messageIdSuffix}`,
            defaultMessage: options?.defaultMessage,
          },
          values,
        )
      } catch (error) {
        if (options?.defaultMessage) {
          return options.defaultMessage
        }

        console.error(
          `Missing translation ${
            isObject(error) && 'message' in error ? error.message : ''
          }`,
        )

        return ''
      }
    },
    [formatMessage, messageIdPrefix],
  )

  translate.getPrefix = () => messageIdPrefix
  return { translate }
}
