import { handleActions } from 'redux-actions'

import scorm from './scorm.reducer'
import scormPackage from './scormPackage.reducer'

const initialState = {
  config: {},
}

export default handleActions(
  {
    ...scorm,
    ...scormPackage,
  },
  initialState,
)
