import { Box } from '@masterplandev/ui'

import { env } from '@/env'

interface ErrorDetailsOverlayProps {
  error: any
}

export function ErrorDetailsOverlay({ error }: ErrorDetailsOverlayProps) {
  if (env.MODE !== 'development' || !error) {
    return null
  }

  return (
    <Box
      position="fixed"
      zIndex="tooltip"
      top="0"
      p="2"
      bg="mpError.700"
      color="mpWhite.700"
      w="full">
      <Box fontWeight="bold">{error.message}</Box>
      <details>
        <summary>Click for error details</summary>
        <Box
          as="pre"
          bg="mpBackgroundDark"
          borderRadius="lg"
          p="2"
          mt="1"
          overflowX="auto">
          {error.stack}
        </Box>
      </details>
    </Box>
  )
}
