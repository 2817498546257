import { useKeycloak } from '@react-keycloak/web'
import { Redirect, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import Loader from '@/core/components/ui/Loader/Loader'
import { URL_DASHBOARD } from '@/core/constants/constants'

import { useNoEmailUsersGet } from '../../hooks/useNoEmailUsersGet'

export function NoEmailLoginRedirect() {
  const { keycloak } = useKeycloak()
  const { ssoSourceId } = useParams<{ ssoSourceId }>()
  const noEmailUsersGet = useNoEmailUsersGet({ ssoSourceId })

  const loginHint = useSearchParam('login_hint') || undefined
  const redirectUri = useSearchParam('redirect_uri') || undefined
  const locale = useSearchParam('locale') || undefined

  if (keycloak.authenticated) {
    return <Redirect to={URL_DASHBOARD} />
  }

  if (noEmailUsersGet.isPending) {
    return <Loader />
  }

  const url = new URL(
    keycloak.createLoginUrl({ loginHint, redirectUri, locale }),
  )
  if (noEmailUsersGet.isSuccess) {
    url.searchParams.set('sso_source_id', ssoSourceId)
  }

  window.location.replace(url.toString())
  return null
}
