import { createActions } from 'redux-actions'

import createRequestAction from '@/core/utils/redux/createRequestAction'

import { BOOKMARKED_LECTURES_ENDPOINT } from '../constants'
import { bookmarkedLecturesSelectors } from '../selectors/bookmarked.selector'

const actions = createActions({
  BOOKMARKED: {
    LECTURES: {
      GET: createRequestAction(BOOKMARKED_LECTURES_ENDPOINT),
      POST: createRequestAction(BOOKMARKED_LECTURES_ENDPOINT, 'POST'),
      DELETE: createRequestAction(BOOKMARKED_LECTURES_ENDPOINT, 'DELETE'),
    },
  },
})

actions.bookmarked.lectures.getIfRequired = () => (dispatch, getState) =>
  bookmarkedLecturesSelectors.requiresFetch(getState())
    ? dispatch(actions.bookmarked.lectures.get())
    : Promise.resolve()

export default actions.bookmarked
