import {
  endsWith,
  filter,
  get,
  keys,
  map,
  negate,
  omit,
  pick,
  pipe,
} from 'lodash/fp'
import { getActionTypes } from 'redux-axios-middleware'

import { compactObject } from '@masterplandev/utils'

import actions from '@/actions'
import { QueryKeys } from '@/api/queryKeys'
import { STATUS_IN_PROGRESS } from '@/core/constants/constants'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import parseLectureLink from '@/core/utils/links/parseLectureLink'
import { queryClient } from '@/core/utils/react-query/queryClient'
import mergeState from '@/core/utils/redux/mergeState'
import mergeStateAll from '@/core/utils/redux/mergeStateAll'

import buildApiUrl from '../utils/buildApiUrl'

const [PROGRESS_PUT_REQUEST, PROGRESS_PUT_SUCCESS, PROGRESS_PUT_FAILURE] =
  getActionTypes({
    type: actions.lecture.progressPut,
  })

// /learnpaths/learnpathId/elementId/topic/lecture for PTE lecture
// /learnpaths/assigned/learnpathId/elements/elementId for LP element
const getUrlToUpdate = (params, url) =>
  params.topic && params.lecture
    ? buildLectureLink(
        pick(['learnpathId', 'elementId', 'topic', 'lecture'], params),
      )
    : url

export default {
  [PROGRESS_PUT_REQUEST]: (state, { payload }) => {
    const { params } = payload
    if (!params.learnpathId || params.topic) {
      return state
    }

    // Progress endpoint is not tracked separately, but there's a need to do so to synchronise certain
    // components like LectureNextButton.
    return mergeState(state, {
      [get('request.url', payload)]: {
        fetching: true,
      },
    })
  },
  [PROGRESS_PUT_FAILURE]: (state, { meta }) => {
    const params = get('previousAction.payload.params', meta)
    if (!params.learnpathId || params.topic) {
      return state
    }

    // Progress endpoint is not tracked separately, but there's a need to do so to synchronise certain
    // components like LectureNextButton.
    return mergeState(state, {
      [`${get('previousAction.payload.options.url', meta)}/progress`]: {
        fetching: false,
      },
    })
  },
  [PROGRESS_PUT_SUCCESS]: (state, { meta, payload }) => {
    const { data } = payload
    const params = get('previousAction.payload.params', meta)

    // If we are changing status of the topic lecture or PTE lecture we update all related entries.
    if (params.topic && params.lecture) {
      const isLectureFromLinearAndDifferentLp = (path) => {
        const match = parseLectureLink(path)
        const learnpathId = match?.params?.learnpathId

        // Learning path filtered is the one that's being altered.
        if (params.learnpathId === learnpathId) {
          return false
        }

        return state[buildApiUrl.assigned(learnpathId)]?.data?.linear
      }

      const pteLecturePathsToUpdate = pipe([
        filter(endsWith(`${params.topic}/${params.lecture}`)),
        filter(negate(isLectureFromLinearAndDifferentLp)),
      ])(keys(state))

      // Update status of the given lecture in every PTE.
      return mergeStateAll(
        state,
        map(
          (pteLecturePath) => ({
            [pteLecturePath]: {
              data: { progress: { status: data?.status } },
            },
          }),
          pteLecturePathsToUpdate,
        ),
      )
    }

    // Updating assigned learning path element progress.
    const urlToUpdate = get('previousAction.payload.options.url', meta)
    const progressUrl = `${urlToUpdate}/progress`

    // Data seeding and syncing between what is inside redux and react-query
    // (react query is used inside learnpaths-assigned-element module).
    // The logic is executed from reducers and since redux is being removed in favour of
    // react-query inside learnpaths-assigned-element that kind of syncing is required.
    // The invocations are made:
    // frontend/src/lecture/actions/progress.actions.ts
    // frontend/src/learnpaths-assigned/utils/stateNormalise.ts
    // frontend/src/learnpaths-assigned/reducers/lectures.reducer.ts
    if (!params.topic) {
      queryClient.setQueryData(
        QueryKeys.learnpaths.assignedElement(
          params.learnpathId,
          params.elementId,
        ),
        (element) => {
          return mergeState(element, {
            progress: pipe(omit('meta'), compactObject)(data),
          })
        },
      )
    }

    // Store progress meta will be always more up to date than PUT response data coming from the server,
    // that cannot be easily synchronised.
    return mergeState(state, {
      [urlToUpdate]: {
        data: {
          progress: pipe(omit('meta'), compactObject)(data),
        },
      },
      [progressUrl]: {
        fetching: false,
      },
    })
  },
  [actions.lecture.progressUpdateStore]: (state, { payload }) => {
    const { progress, params, url } = payload

    // No need to handle active status of the topic lecture.
    if (!params.learnpathId) {
      return state
    }

    // Data seeding and syncing between what is inside redux and react-query
    // (react query is used inside learnpaths-assigned-element module).
    // The logic is executed from reducers and since redux is being removed in favour of
    // react-query inside learnpaths-assigned-element that kind of syncing is required.
    // The invocations are made:
    // frontend/src/lecture/actions/progress.actions.ts
    // frontend/src/learnpaths-assigned/utils/stateNormalise.ts
    // frontend/src/learnpaths-assigned/reducers/lectures.reducer.ts
    if (!params.topic) {
      queryClient.setQueryData(
        QueryKeys.learnpaths.assignedElement(
          params.learnpathId,
          params.elementId,
        ),
        (element) => {
          return mergeState(element, {
            progress: omit('status', progress),
          })
        },
      )
    }

    return mergeState(state, {
      [getUrlToUpdate(params, url)]: {
        data: {
          progress: omit('status', progress),
        },
        activeStatus: progress.status,
      },
    })
  },
  [actions.lecture.setActiveStatus]: (state, { payload }) => {
    const { params, url } = payload

    if (!params.learnpathId) {
      return state
    }

    return mergeState(state, {
      [getUrlToUpdate(params, url)]: { activeStatus: STATUS_IN_PROGRESS },
    })
  },
  [actions.lecture.endActiveStatus]: (state, { payload }) => {
    const { params, url } = payload

    if (!params.learnpathId) {
      return state
    }

    return mergeState(state, {
      [getUrlToUpdate(params, url)]: { activeStatus: null },
    })
  },
  [actions.lecture.playVideo]: (state, { payload }) => {
    const { params, url } = payload

    if (!params.learnpathId) {
      return state
    }

    return mergeState(state, {
      [getUrlToUpdate(params, url)]: { video_playing: true },
    })
  },
  [actions.lecture.stopVideo]: (state, { payload }) => {
    const { params, url } = payload

    if (!params.learnpathId) {
      return state
    }

    return mergeState(state, {
      [getUrlToUpdate(params, url)]: { video_playing: false },
    })
  },
}
