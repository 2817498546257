import { buildLink } from '@masterplandev/utils'

const base = '/learnpaths'

function assigned(learnpathId?: string) {
  return buildLink(base, 'assigned', learnpathId)
}

function assignedCourses() {
  return `${buildLink(base, 'assigned')}?is_course=true`
}

function assignedElement(learnpathId: string, elementId?: string) {
  return buildLink(assigned(), learnpathId, 'elements', elementId)
}

function assignedQuizAnswerCheck(learnpathId, quizId, questionId) {
  return buildLink(
    assignedElement(learnpathId),
    'quiz',
    quizId,
    'check_question',
    questionId,
  )
}

function assignedElementProgress(learnpathId, elementId) {
  return buildLink(assignedElement(learnpathId, elementId), 'progress')
}

function assignedConfirmCompletion(learnpathId) {
  return buildLink(assigned(learnpathId), 'confirm-completion')
}

function join(sharingLinkId) {
  return buildLink(base, 'join', sharingLinkId)
}

export default {
  assigned,
  assignedConfirmCompletion,
  assignedCourses,
  assignedElement,
  assignedElementProgress,
  assignedQuizAnswerCheck,
  join,
}
