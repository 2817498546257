import * as Sentry from '@sentry/react'
import axios, { AxiosError, isAxiosError } from 'axios'
import axiosRetry, { isRetryableError as isApiError } from 'axios-retry'
import { startsWith } from 'lodash/fp'

import { TOKEN_MIN_VALIDITY_VALUES } from '@/auth/constants'
import { env } from '@/env'

import { REQUEST_RETRY_COUNT } from './constants/constants'
import keycloak from './keycloak'
import skipMethodOfParamsDelivery from './utils/api/skipMethodOfParamsDelivery'

const baseURL = `${window.location.origin}${env.REACT_APP_API_URL_PREFIX}`

const axiosClient = axios.create({
  baseURL,
  responseType: 'json',
})

// Add Authorization header for internal requests
axiosClient.interceptors.request.use(async (request) => {
  const isBaseApiRequest = startsWith('/', request.url || '')

  if (!isBaseApiRequest || !keycloak.token) {
    return request
  }

  const controller = new AbortController()

  await keycloak
    .updateToken(TOKEN_MIN_VALIDITY_VALUES.IF_ABOUT_TO_EXPIRE)
    .catch(() => {
      Sentry.metrics.increment('auth.failed.refresh.token', 1)
      Sentry.captureMessage('Failed to refresh token')
      controller.abort()
      keycloak.logout({
        redirectUri: keycloak.createLoginUrl(),
      })
    })

  if (request.headers) {
    request.headers.Authorization = `Bearer ${keycloak.token}`
  }

  return {
    ...request,
    signal: controller.signal,
  }
})

// Handles 401 Unauthorized errors
axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      isAxiosError(error) &&
      error.response?.data?.message === 'api.error.unauthorized'
    ) {
      Sentry.metrics.increment('auth.failed.response.unauthorized', 1)
      Sentry.captureMessage('Handles "api.error.unauthorized" response')
      keycloak.logout({
        redirectUri: keycloak.createLoginUrl(),
      })
    }

    return Promise.reject(error)
  },
)

// MP-9004: We modify the error data because of changes in API response
// but we want to stay compatible
axiosClient.interceptors.response.use(
  (response) => response,
  (originalError) => {
    if (isAxiosError(originalError)) {
      const { message, code, config, request, response } = originalError

      if (response) {
        const error = new AxiosError(message, code, config, request, {
          ...response,
          data: skipMethodOfParamsDelivery(originalError.response?.data),
        })

        return Promise.reject(error)
      }
    }

    return Promise.reject(originalError)
  },
)

axiosRetry(axiosClient, {
  retries: REQUEST_RETRY_COUNT,
  retryCondition: isApiError,
})

export default axiosClient
