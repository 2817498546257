import {
  compact,
  flatten,
  get,
  getOr,
  has,
  includes,
  intersection,
  isEmpty,
  lt,
  map,
  merge,
  omit,
  pipe,
  size,
  some,
  split,
  trim,
} from 'lodash/fp'
import { createSelector } from 'reselect'

import isLocalStorageEnabled from '@/core/utils/localStorage/isLocalStorageEnabled'
import { env } from '@/env'

export const rootSelector = get('user')

export const accountSelector = createSelector(
  rootSelector,
  getOr({}, 'account'),
)

export const metaSelector = createSelector(
  rootSelector,
  omit(['data', 'account']),
)
export const fetchedSelector = createSelector(
  metaSelector,
  getOr(false, 'fetched'),
)

export const fetchingSelector = createSelector(
  metaSelector,
  getOr(false, 'fetching'),
)

export const loadingSelector = createSelector(
  metaSelector,
  ({ fetched, failed }) => !fetched && !failed,
)

export const userDataSelector = createSelector(rootSelector, get('data'))

export const dataSelector = createSelector(
  userDataSelector,
  accountSelector,
  (data, account) => {
    const {
      department,
      given_name: first_name,
      family_name: last_name,
      phone,
      title,
      date_of_birth,
      job_title,
    } = account

    const email =
      has('identity.email', data) && isEmpty(data.identity.email)
        ? data.identity.email
        : account.email

    const userIdentity = merge(data?.identity, {
      department,
      date_of_birth,
      email,
      first_name,
      job_title,
      last_name,
      phone,
      title,
    })

    return omit('identity', { ...data, ...userIdentity })
  },
)

export const errorSelector = createSelector(metaSelector, get('error'))

export const requiresFetchSelector = createSelector(
  metaSelector,
  ({ fetching, fetched, failed, fresh }) =>
    // @ts-ignore Not sure if it works, while moving to TS leaving as is.
    !some(null, [fetching, fetched && fresh, failed && fresh]),
)

export const emailSelector = createSelector(dataSelector, get('email'))

export const rolesSelector = createSelector(dataSelector, getOr([], 'roles'))

export const userLangSelector = createSelector(dataSelector, get('lang'))

export const configFeaturesSelector = () =>
  pipe([split(','), map(trim), compact])(env.REACT_APP_FEATURES)

export const devFeaturesSelector = () =>
  pipe([get('features'), split(','), map(trim), compact])(
    env.DEV && isLocalStorageEnabled() ? localStorage : '',
  )

export const featuresSelector = createSelector(
  dataSelector,
  devFeaturesSelector,
  configFeaturesSelector,
  (data, devFeatures, configFeatures) =>
    flatten([getOr([], 'features', data), devFeatures, configFeatures]),
)

export const createIsFeatureSelector = (feature) =>
  createSelector(featuresSelector, includes(feature))

export const idSelector = createSelector(dataSelector, get('id'))

export const isStudentSelector = createSelector(
  rolesSelector,
  includes('student'),
)

export const isManagerSelector = createSelector(
  rolesSelector,
  includes('manager'),
)

export const isManagingRoleSelector = createSelector(
  rolesSelector,
  pipe([
    intersection(['owner', 'admin', 'manager', 'company_administrator']),
    size,
    lt(0),
  ]),
)

export const isAdminSelector = createSelector(rolesSelector, includes('admin'))

export const isOwnerSelector = createSelector(rolesSelector, includes('owner'))

export const hasCurrentSkillSelector = createSelector(
  dataSelector,
  getOr(false, 'current_skill_set'),
)
