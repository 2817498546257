import PropTypes from 'prop-types'
import { FormattedMessage as IntlMessage } from 'react-intl'

// By default <FormattedMessage /> wraps messages in <span /> element
// to avoid this import <FormattedMessage /> from @/core
export default function FormattedMessage({
  tagName: TagName,
  className,
  ...props
}) {
  if (TagName) {
    return (
      <TagName className={className}>
        <IntlMessage tagName={null} {...props} />
      </TagName>
    )
  }

  if (className) {
    return (
      <span className={className}>
        <IntlMessage tagName={null} {...props} />
      </span>
    )
  }

  return <IntlMessage tagName={null} {...props} />
}

FormattedMessage.propTypes = {
  className: PropTypes.string,
  tagName: PropTypes.string,
}

FormattedMessage.defaultProps = {
  className: null,
  tagName: null,
}
