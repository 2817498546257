import { startsWith } from 'lodash/fp'
import { matchPath } from 'react-router-dom'

/*
 * This function parses app url for both topic lectures and learnpath lectures
 * depending on the prefix used in path.
 */
export const parseLectureLink = (path) => {
  if (startsWith('/learnpaths', path)) {
    return matchPath(path, '/:prefix/:learnpathId/:elementId/:topic?/:lecture?')
  }

  if (startsWith('/lectures', path)) {
    return matchPath(path, '/:prefix/:topic/:lecture')
  }

  if (startsWith('/skill', path)) {
    return matchPath(path, '/:prefix/:skill/:topic/:lecture')
  }

  if (startsWith('/course-generic', path)) {
    return matchPath(path, '/:prefix/:course/:topic/:lecture')
  }

  return null
}

export default parseLectureLink
