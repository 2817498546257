import {
  concat,
  defaults,
  eq,
  filter,
  find,
  flatMap,
  get,
  getOr,
  gt,
  indexOf,
  map,
  negate,
  pipe,
  placeholder,
  size,
  sortBy,
  uniqBy,
} from 'lodash/fp'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { passProps } from '@masterplandev/utils'

import {
  CHANNEL_TOPICS_DISPLAY_ORDER,
  STATUS_COMPLETED,
  STATUS_LOCKED,
  STATUS_STARTED,
} from '@/core/constants/constants'
import { generateBasicSelectors } from '@/core/selectors'

export const rootSelector = get('channels')

export const mainRootSelector = createSelector(
  rootSelector,
  getOr({}, '/channels'),
)

export const {
  meta: metaSelector,
  fetching: fetchingSelector,
  fetched: fetchedSelector,
  failed: failedSelector,
  requiresFetch: requiresFetchSelector,
} = generateBasicSelectors(mainRootSelector)

export const dataSelector = createSelector(mainRootSelector, getOr({}, 'data'))

export const sortChannelTopics = (topics) =>
  sortBy(
    [
      (topic) =>
        indexOf(get('progress.status', topic), CHANNEL_TOPICS_DISPLAY_ORDER),
    ],
    topics,
  )

export const channelsSelector = createSelector([dataSelector], ({ channels }) =>
  map((channel) =>
    defaults(channel, {
      topics: pipe([get('topics'), sortChannelTopics])(channel),
    }),
  )(channels),
)

export const channelSelector = createSelector(
  [channelsSelector, passProps],
  (channels, { channelSlug }) => {
    return find({ slug: channelSlug }, channels)
  },
)

export const allTopicsSelector = createSelector(
  channelsSelector,
  pipe([flatMap(get('topics')), uniqBy(get('slug'))]),
)

export const personalRootSelector = createSelector(
  rootSelector,
  getOr({}, '/channels/personal'),
)
export const {
  data: personalDataSelector,
  meta: personalMetaSelector,
  fetching: personalFetchingSelector,
  fetched: personalFetchedSelector,
  failed: personalFailedSelector,
  requiresFetch: personalRequiresFetchSelector,
} = generateBasicSelectors(personalRootSelector)

export const personalTopicsSelector = createSelector(
  personalDataSelector,
  get('topics'),
)
export const personalProgressSelector = createSelector(
  personalDataSelector,
  get('progress'),
)
export const personalStatusSelector = createSelector(
  personalProgressSelector,
  get('status'),
)

export const isPersonalUnlockedSelector = createSelector(
  personalStatusSelector,
  negate(eq(STATUS_LOCKED)),
)

export const filteredPersonalTopicsSelector = createCachedSelector(
  personalTopicsSelector,
  personalStatusSelector,
  (state, completed) => completed,
  (state, completed, locked) => locked,
  (topics, status, completed = false, locked = false) => {
    if (!locked && status === STATUS_LOCKED) {
      return []
    }

    return pipe([
      filter(
        pipe([get('progress.status'), eq(STATUS_COMPLETED), eq(completed)]),
      ),
      sortChannelTopics,
    ])(topics)
  },
)((state, completed, locked) => `${completed}:${locked}`)

export const internalRootSelector = createSelector(
  rootSelector,
  getOr({}, '/channels/internal'),
)
export const internalDataSelector = createSelector(
  internalRootSelector,
  getOr([], 'data.topics'),
)
export const {
  meta: internalMetaSelector,
  fetching: internalFetchingSelector,
  fetched: internalFetchedSelector,
  failed: internalFailedSelector,
  requiresFetch: internalRequiresFetchSelector,
} = generateBasicSelectors(internalRootSelector)

export const newTopicsRootSelector = createSelector(
  rootSelector,
  getOr({}, '/channels/new_topics'),
)
export const newTopicsDataSelector = createSelector(
  newTopicsRootSelector,
  getOr([], 'data.topics'),
)
export const filteredNewTopicsSelector = createSelector(
  [newTopicsDataSelector, personalStatusSelector],
  (topics, status) => {
    if (status === STATUS_LOCKED) {
      return []
    }

    return topics
  },
)

export const {
  meta: newTopicsMetaSelector,
  fetching: newTopicsFetchingSelector,
  fetched: newTopicsFetchedSelector,
  failed: newTopicsFailedSelector,
  requiresFetch: newTopicsRequiresFetchSelector,
} = generateBasicSelectors(newTopicsRootSelector)

export const bestTopicsRootSelector = createSelector(
  rootSelector,
  getOr({}, '/channels/best_topics'),
)
export const bestTopicsDataSelector = createSelector(
  bestTopicsRootSelector,
  getOr([], 'data.topics'),
)
export const filteredBestTopicsSelector = createSelector(
  [bestTopicsDataSelector, personalStatusSelector],
  (topics, status) => {
    if (status === STATUS_LOCKED) {
      return []
    }

    return topics
  },
)

export const {
  meta: bestTopicsMetaSelector,
  fetching: bestTopicsFetchingSelector,
  fetched: bestTopicsFetchedSelector,
  failed: bestTopicsFailedSelector,
  requiresFetch: bestTopicsRequiresFetchSelector,
} = generateBasicSelectors(bestTopicsRootSelector)

export const highlightedTopicRootSelector = createSelector(
  rootSelector,
  getOr({}, '/channels/highlighted_topic'),
)

export const highlightedTopicSelectors = generateBasicSelectors(
  highlightedTopicRootSelector,
)

export const allStartedTopicsSelector = createSelector(
  [allTopicsSelector, internalDataSelector],
  (channelsTopics, internalTopics) => {
    return pipe([
      concat(channelsTopics),
      uniqBy(get('slug')),
      filter(pipe([get('progress.status'), eq(STATUS_STARTED)])),
    ])(internalTopics)
  },
)

export const completedTopicsByChannelSelector = createSelector(
  [dataSelector],
  ({ channels }) =>
    pipe([
      map((channel) =>
        defaults(channel, {
          topics: pipe([
            get('topics'),
            filter(pipe([get('progress.status'), eq(STATUS_COMPLETED)])),
          ])(channel),
        }),
      ),
      filter(pipe([get('topics'), size, gt(placeholder, 0)])),
    ])(channels),
)

export const completedInternalTopicsSelector = createSelector(
  [internalDataSelector],
  filter(pipe([get('progress.status'), eq(STATUS_COMPLETED)])),
)

export const numCompletedTopicsSelector = createSelector(
  [allTopicsSelector, internalDataSelector],
  (channelsTopics, internalTopics) => {
    return pipe([
      concat(channelsTopics),
      filter(pipe([get('progress.status'), eq(STATUS_COMPLETED)])),
      size,
    ])(internalTopics)
  },
)
