import { mergeAll } from 'lodash/fp'
import { createActions } from 'redux-actions'

import progressActions from './actions/progress.actions'
import trackingActions from './actions/tracking.actions'
import {
  lectureFeedbackSelectors,
  lectureSelectors,
} from './selectors/lecture.selectors'
import buildUniversalLectureApiUrl from './utils/buildUniversalLectureApiUrl'
import toMatchParams from './utils/toMatchParams'

const actions = createActions({
  LECTURE: {
    GET: (params) => ({
      options: {
        url: buildUniversalLectureApiUrl(params),
        returnRejectedPromiseOnError: false,
      },
      request: {
        url: buildUniversalLectureApiUrl(params, { withContext: true }),
      },
    }),
    FEEDBACK: {
      GET: (params) => ({
        options: {
          returnRejectedPromiseOnError: false,
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'feedback',
          }),
        },
        request: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'feedback',
            withContext: true,
          }),
        },
      }),
      POST: (params, data) => ({
        options: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'feedback',
          }),
        },
        request: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'feedback',
            withContext: true,
          }),
          method: 'post',
          data,
        },
      }),
      PUT: (params, data) => ({
        params,
        options: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'feedback',
          }),
        },
        request: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'feedback',
            withContext: true,
          }),
          method: 'put',
          data,
        },
      }),
      CLEAR: (params) => ({
        url: buildUniversalLectureApiUrl(params, { suffix: 'feedback' }),
      }),
    },
    BOOKMARKS: {
      GET: (params) => ({
        params,
        options: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'bookmarks',
          }),
        },
        request: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'bookmarks',
            withContext: true,
          }),
        },
      }),
      POST: (params, data) => ({
        params,
        options: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'bookmarks',
          }),
        },
        request: {
          method: 'post',
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'bookmarks',
            withContext: true,
          }),
          data,
        },
      }),
      PUT: (params, data) => ({
        params,
        options: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'bookmarks',
          }),
        },
        request: {
          method: 'put',
          url: buildUniversalLectureApiUrl(params, {
            suffix: `bookmarks/${params.id}`,
            withContext: true,
          }),
          data,
        },
      }),
      DELETE: (params) => ({
        params,
        options: {
          url: buildUniversalLectureApiUrl(params, {
            suffix: 'bookmarks',
          }),
        },
        request: {
          method: 'delete',
          url: buildUniversalLectureApiUrl(params, {
            suffix: `bookmarks/${params.id}`,
            withContext: true,
          }),
        },
      }),
    },
    FILE: {
      GET: (url) => ({
        request: {
          url,
          responseType: 'blob',
        },
      }),
    },
    CLEAR_ALL: () => ({}),
  },
})

actions.lecture.getIfRequired =
  ({ learnpathId, topic, lecture }) =>
  (dispatch, getState) => {
    const matchParams = toMatchParams({ learnpathId, topic, lecture })

    if (lectureSelectors.requiresFetch(getState(), matchParams)) {
      return dispatch(actions.lecture.get(matchParams))
    }

    return Promise.resolve()
  }

actions.lecture.feedback.getIfRequired = (params) => (dispatch, getState) => {
  if (lectureFeedbackSelectors.requiresFetch(getState(), params)) {
    return dispatch(actions.lecture.feedback.get(params))
  }

  return Promise.resolve()
}

export default mergeAll([
  actions.lecture,
  progressActions.lecture,
  trackingActions,
])
