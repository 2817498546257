import { Dispatch } from 'redux'

import { Topic } from '@/api/generated-api-and-types'
import { SYNC_TOPIC_LECTURES } from '@/lecture/reducers/getTopic.reducer'

import { createApiMethod, type } from './utils/createApiMethod'

const syncStoreActions = [SYNC_TOPIC_LECTURES] as const

export const topics = {
  details: createApiMethod({
    method: 'GET',
    path: '/topics/:slug',
    response: type<Topic>(),
  }),
}

export const topicsQueries = {
  all: () => ['topics'] as const,
  details: (slug: string, dispatch: Dispatch) => ({
    queryKey: [...topicsQueries.all(), slug] as const,
    queryFn: () =>
      topics.details({ params: { slug } }).then((data) => {
        dispatch({ type: syncStoreActions, payload: { data } })
        return data
      }),
    staleTime: 1000,
  }),
}
