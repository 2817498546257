import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Container.module.css'

function Container({ marginOnlyLeft, children, className, ...rest }) {
  return (
    <div
      className={classNames(styles.container, className, {
        [styles.marginOnlyLeft]: marginOnlyLeft,
      })}
      {...rest}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  marginOnlyLeft: PropTypes.bool,
}

Container.defaultProps = {
  className: null,
  marginOnlyLeft: false,
}

export default Container
