export default function toMatchParams({
  topic,
  lecture,
  learnpathId,
  elementId,
}) {
  return {
    match: { params: { topic, lecture, elementId, learnpathId } },
    elementId,
    learnpathId,
    lecture,
    topic,
  }
}
