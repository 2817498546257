import actions from './s3.actions'

/*
 * The axios cancel token API is based on the withdrawn cancelable promises proposal
 * https://github.com/axios/axios#cancellation
 *
 * To Avoid putting non-serializable value of cancel token to the store
 * https://redux.js.org/style-guide/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
 *
 * Middleware creates a list of tokens
 * Request action adds cancel token to the list
 * Cancel action calls cancel function on cancel token
 */
export default function createCancelAssetUploadMiddleware(cancelTokens = {}) {
  return () => (next) => (action) => {
    if (action.type === actions.s3.post.toString()) {
      if (action.meta) {
        const { fileId, cancelToken } = action.meta

        // eslint-disable-next-line no-param-reassign
        cancelTokens[fileId] = cancelToken
      }
    }

    if (action.type === actions.s3.cancel.toString()) {
      const fileId = action.payload
      const token = cancelTokens[fileId]
      if (token?.cancel) {
        token.cancel('File upload canceled')
      }
    }

    return next(action)
  }
}
