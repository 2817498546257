import { isPlainObject, pickBy } from 'lodash/fp'
import { shallowEqual } from 'react-redux'
import { createSelectorCreator, defaultMemoize } from 'reselect'

/**
 * Selector creator used to compare if certain keys of the object have changed.
 *
 * @param {string} regex - regular expression used to filter the keys.
 * @returns {boolean} - value indicating if object should be memoized or not.
 *
 * @example
 * return createMemoizeByKeysSelector(regex)(someDataSelector, identity)
 */
export default (regex: string | RegExp) =>
  createSelectorCreator(defaultMemoize, (prev, curr) => {
    if (isPlainObject(prev) && isPlainObject(curr)) {
      const regexFilters = (_, key) => key.match(regex)

      // @ts-ignore
      const filteredPrev = pickBy(regexFilters, prev)
      // @ts-ignore
      const filteredCurr = pickBy(regexFilters, curr)

      return shallowEqual(filteredPrev, filteredCurr)
    }

    return false
  })
