import { useKeycloak } from '@react-keycloak/web'
import type { KeycloakTokenParsed } from 'keycloak-js'
import { useSelector } from 'react-redux'

import { accountSelector } from '@/user/selectors'

import type { AuthUser } from '../types'

interface ParsedToken extends KeycloakTokenParsed, AuthUser {}

export function useAuth() {
  const { keycloak } = useKeycloak()
  const user = useSelector(accountSelector) as ParsedToken

  return {
    isAuthenticated: keycloak.authenticated,
    user,
    login: keycloak.login,
  }
}
