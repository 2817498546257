import classNames from 'classnames'

import { THEME_DARK, THEME_LIGHT } from '../constants/constants'
import styles from './Logo.module.css'

interface LogoProps {
  className?: string
  full?: boolean
  theme?: typeof THEME_LIGHT | typeof THEME_DARK
}

export default function Logo({ className, full = false, theme }: LogoProps) {
  return (
    <div
      className={classNames(
        styles.logo,
        {
          [styles.logoFull]: full,
          [styles.logoWhite]: theme === THEME_DARK,
          [styles.logoBlack]: theme === THEME_LIGHT,
        },
        className,
      )}
    />
  )
}
