import mergeState from '@/core/utils/redux/mergeState'

import normalizeTopicLectures from '../utils/normalizeTopicLectures'

export const SYNC_TOPIC_LECTURES = 'SYNC_TOPIC_LECTURES'

export default {
  [SYNC_TOPIC_LECTURES]: (state, { payload: { data: topic } }) =>
    mergeState(state, normalizeTopicLectures(state, topic)),
}
