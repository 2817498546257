import actions from './ScrollListener.actions'

export default {
  [actions.scrollListener.setThreshold]: (
    state,
    { payload: { name, threshold } },
  ) => ({
    ...state,
    scrollListener: {
      ...state.scrollListener,
      [name]: threshold,
    },
  }),
}
