import { endsWith } from 'lodash/fp'

import { env } from '@/env'

const SCORM_ENABLED_SUFFIX = '-lms.com'

/**
 * Checks if SCORM mode is enabled.
 *
 * @returns {boolean} boolean value indicating whether the application is
 * running in SCORM mode.
 *
 * @example
 * isScormEnabled()
 * // => true
 */
export default function isScormEnabled() {
  return (
    env.REACT_APP_SCORM_ENABLED ||
    endsWith(SCORM_ENABLED_SUFFIX, window.location.origin)
  )
}
