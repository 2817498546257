import { createActions } from 'redux-actions'

import {
  API_URL_LEARNGROUP,
  API_URL_LEARNGROUP_LEADERBOARD,
  API_URL_LEARNGROUP_MEMBERSHIPS,
  API_URL_LEARNGROUP_SCORING,
} from '@/core/constants/constants'

const actions = createActions({
  LEARNGROUP: {
    GET: () => ({
      options: { returnRejectedPromiseOnError: false },
      request: {
        url: API_URL_LEARNGROUP,
      },
    }),
    POST: (data, params) => ({
      request: {
        url: API_URL_LEARNGROUP,
        method: 'POST',
        params,
        data,
      },
    }),
    PUT: (data) => ({
      request: {
        url: API_URL_LEARNGROUP,
        method: 'PUT',
        data,
      },
    }),
    SCORING: {
      GET: (params) => ({
        options: { returnRejectedPromiseOnError: false },
        request: {
          url: API_URL_LEARNGROUP_SCORING,
          params,
        },
      }),
      CLEAR: () => ({}),
    },
    MEMBERSHIP: {
      PUT: (id) => ({
        request: {
          url: `${API_URL_LEARNGROUP_MEMBERSHIPS}/${id}`,
          method: 'PUT',
        },
      }),
      DELETE: (id) => ({
        request: {
          url: `${API_URL_LEARNGROUP_MEMBERSHIPS}/${id}`,
          method: 'DELETE',
        },
      }),
      RESEND: (id) => ({
        meta: { id },
        request: {
          url: `${API_URL_LEARNGROUP_MEMBERSHIPS}/${id}/mail`,
          method: 'POST',
        },
      }),
    },
    LEADERBOARD: {
      GET: (params) => ({
        request: {
          url: API_URL_LEARNGROUP_LEADERBOARD,
          params,
        },
      }),
      CLEAR: () => ({}),
    },
  },
})

export default actions.learngroup
