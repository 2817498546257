import { useStore } from 'react-redux'

/**
 * Selects data from the store, but does not force component to rerender when
 * the selected property changes.
 *
 * @param {function(object, any)} selector - selector to be used to get data
 * @param {any} [props] - additional props used in selector function
 *
 * @returns {*} selected data
 *
 * @example
 * const data = useRef(useLazySelector(initialValueSelector, { propA: 'a' }))
 */
export default function useLazySelector(selector, props) {
  return selector(useStore().getState(), props)
}
