import { createActions } from 'redux-actions'

import * as buildApiUrl from '../utils/buildApiUrl'

const actions = createActions({
  LEARNPATHS: {
    FEEDBACK: {
      POST: ({ text }) => ({
        options: { ignoreResponse: true },
        request: {
          url: buildApiUrl.feedback(),
          method: 'POST',
          data: {
            text,
          },
        },
      }),
    },
    MANAGED: {
      ACTIONS: {
        MARK_MODIFICATION: ({ learnpathId, status }) => ({
          learnpathId,
          status,
        }),
      },
    },
  },
})

export default actions.learnpaths
