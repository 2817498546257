import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Loader.module.css'

export const COLORS = {
  primary: 'primary',
  white: 'white',
}

export const SIZES = {
  md: 'md',
  sm: 'sm',
  xs: 'xs',
}

const Loader = ({ color, size, fixed }) => (
  <div
    className={classNames(
      styles.loader,
      fixed && styles.fixed,
      styles[`size-${size}`],
      styles[`color-${color}`],
    )}
    data-testid="loading-animation"
  />
)

Loader.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES)),
  fixed: PropTypes.bool,
}

Loader.defaultProps = {
  color: COLORS.primary,
  size: SIZES.md,
  fixed: false,
}

Loader.COLORS = COLORS
Loader.SIZES = SIZES

export default Loader
