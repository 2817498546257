import usePropsSelector from '@/core/utils/redux/usePropsSelector'

import { assignedLearnpathProgressCompletion } from '../selectors'
import useAssignedLearnpathFetchIfRequired from './useAssignedLearnpathFetchIfRequired'

interface Args {
  learnpathId: string
}

export default function useAssignedLearnpathCompletionProgress({
  learnpathId,
}: Args): number {
  useAssignedLearnpathFetchIfRequired({ learnpathId })
  return usePropsSelector(assignedLearnpathProgressCompletion, {
    learnpathId,
  })
}
