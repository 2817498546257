import { handleActions } from 'redux-actions'

import initialiseReducerState from '@/core/utils/redux/initialiseReducerState'

import { BOOKMARKED_LECTURES_ENDPOINT } from '../constants'
import bookmarked from './bookmarked.reducer'

export default handleActions(
  {
    ...bookmarked,
  },
  initialiseReducerState(BOOKMARKED_LECTURES_ENDPOINT),
)
