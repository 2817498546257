import { Cloudinary as BaseCloudinary } from '@cloudinary/url-gen'

import { getCloudinaryId } from '@masterplandev/utils'

import { env } from '@/env'

/**
 * Extend base cloudinary class to parse publicId
 */
class Cloudinary extends BaseCloudinary {
  image(publicID?: string) {
    const id = publicID
      ? getCloudinaryId(decodeURIComponent(publicID))
      : undefined

    return super.image(id)
  }
}

/**
 * @see {@link https://cloudinary.com/documentation/javascript_integration|Cloudinary JavaScript SDK}
 * @see {@link https://cloudinary.com/documentation/javascript_image_transformations|JavaScript SDK image transformations}
 *
 * @example
 * import { fill } from '@cloudinary/url-gen/actions/resize'
 * import { cloudinary } from '@/cloudinary/cloudinary'
 *
 * const image = cloudinary
 *     .image('system/arrow')
 *     .resize(fill().width(90))}
 *
 */
export const cloudinary = new Cloudinary({
  url: {
    analytics: false,
    secure: true,
    privateCdn: env.REACT_APP_CLOUDINARY_PRIVATE_CDN,
    cname: env.REACT_APP_CLOUDINARY_CNAME,
    secureDistribution: env.REACT_APP_CLOUDINARY_CNAME,
  },
  cloud: {
    cloudName: env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  },
})
