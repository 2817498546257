import { combineActions, handleActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import actions from '@/actions'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

const initialState = {}

const [GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, GET_COMPANY_ERROR] =
  getActionTypes({ type: actions.company.get })

const REQUEST_ACTIONS = [GET_COMPANY_REQUEST]
const SUCCESS_ACTIONS = [GET_COMPANY_SUCCESS]
const ERROR_ACTIONS = [GET_COMPANY_ERROR]

export default handleActions(
  {
    [combineActions(...REQUEST_ACTIONS)]: mergeRequestAction,
    [combineActions(...SUCCESS_ACTIONS)]: mergeSuccessAction,
    [combineActions(...ERROR_ACTIONS)]: mergeErrorAction,
  },
  initialState,
)
