import { setDefaultOptions } from 'date-fns'
import { isEmpty } from 'lodash/fp'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { eventTracker } from '@masterplandev/utils'

import actions from '@/actions'

import {
  dateLocaleSelector,
  ipSelector,
  localeSelector,
  requiresFetchSelector,
} from '../selectors'

class LocaleProvider extends Component {
  static propTypes = {
    locale: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    localeOverride: PropTypes.string,
    dateLocale: PropTypes.object.isRequired,
    ip: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    requiresFetch: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    customMessages: PropTypes.object,
  }

  static defaultProps = {
    localeOverride: undefined,
    ip: null,
    customMessages: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      messages: {},
    }
  }

  componentDidMount() {
    this.fetchIfRequired()
    this.setIpToMixpanel()
    this.updateDocumentLang()
    this.loadLocaleMessages().then((res) => {
      this.setState({ messages: res.default })
    })
  }

  componentDidUpdate(prevProps) {
    this.fetchIfRequired()
    if (prevProps.ip === null) {
      this.setIpToMixpanel()
    }
    this.updateDocumentLang(prevProps)

    if (prevProps.locale !== this.props.locale) {
      this.loadLocaleMessages().then((res) => {
        this.setState({ messages: res.default })
      })
    }

    // When locale changes, try to update date-fns global default locale option.
    if (prevProps.dateLocale !== this.props.dateLocale) {
      setDefaultOptions({ locale: this.props.dateLocale })
    }
  }

  setIpToMixpanel() {
    const { ip } = this.props
    if (ip) {
      eventTracker.register({ ip })
      eventTracker.people.set('$ip', ip)
    }
  }

  updateDocumentLang(prevProps = {}) {
    const { localeOverride, locale } = this.props
    if (localeOverride !== prevProps.localeOverride) {
      document.documentElement.lang = localeOverride
    } else if (locale !== prevProps.locale) {
      document.documentElement.lang = locale
    }
  }

  loadLocaleMessages() {
    const { locale } = this.props
    return import(`../../translations/${locale}.json`)
  }

  fetchIfRequired() {
    const { localeOverride, dispatch, requiresFetch } = this.props
    if (!localeOverride && requiresFetch) {
      dispatch(actions.locale.fetch())
    }
  }

  render() {
    const { locale, localeOverride, children, customMessages } = this.props
    const localeToUse = localeOverride ?? locale

    if (!localeToUse) {
      return null
    }

    const allMessages = isEmpty(customMessages)
      ? this.state.messages
      : { ...this.state.messages, ...customMessages }

    window.messages = allMessages

    // textComponent="span" in order to keep the old behavior from v2
    return (
      <IntlProvider
        locale={localeToUse}
        messages={allMessages}
        textComponent="span">
        {children}
      </IntlProvider>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  ip: ipSelector,
  locale: localeSelector,
  dateLocale: dateLocaleSelector,
  requiresFetch: requiresFetchSelector,
})

export default connect(mapStateToProps)(LocaleProvider)
