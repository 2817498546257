import { Link, useHistory } from 'react-router-dom'

import FormattedMessage from '@/core/components/FormattedMessage'
import Logo from '@/core/components/Logo'
import { URL_DASHBOARD } from '@/core/constants/constants'

import { PageWrapper } from '../PageWrapper/PageWrapper'
import styles from './PageNotFound.module.css'

export function PageNotFound() {
  const history = useHistory()

  const beforeContent = (
    <div className={styles.header}>
      <Link to={URL_DASHBOARD}>
        <Logo />
      </Link>
      <button
        type="button"
        id="not-found-go-back"
        onClick={() => history.goBack()}
        className={styles.backButton}>
        <FormattedMessage id="core.not-found.back" />
      </button>
    </div>
  )

  return (
    <PageWrapper
      wrapperClassName={styles.wrapper}
      beforeContent={beforeContent}>
      <div className={styles.page}>
        <FormattedMessage tagName="h1" id="core.not-found.title" />
        <FormattedMessage tagName="p" id="core.not-found.text" />
        <Link to={URL_DASHBOARD} className="btn btn-primary">
          <FormattedMessage id="core.not-found.button" />
        </Link>
      </div>
    </PageWrapper>
  )
}
