import { QueryClient } from '@tanstack/react-query'

import { env } from '@/env'

export const queryClient =
  env.MODE === 'test'
    ? new QueryClient({
        defaultOptions: { queries: { retry: false } },
      })
    : new QueryClient()
