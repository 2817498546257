import actions from '@/actions'
import aggregateActions from '@/core/utils/redux/aggregateActions'
import mergeFailureAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeState from '@/core/utils/redux/mergeState'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

const [REQUEST_ACTIONS, SUCCESS_ACTIONS, FAILURE_ACTIONS] = aggregateActions(
  actions.scorm.auth.post,
  actions.scorm.packages.get,
  actions.scorm.external.launch.get,
)

export default {
  [REQUEST_ACTIONS]: mergeRequestAction,
  [SUCCESS_ACTIONS]: mergeSuccessAction,
  [FAILURE_ACTIONS]: mergeFailureAction,
  [actions.scorm.setOrigin]: (state, { payload }) =>
    mergeState(state, { origin: payload }),
  [actions.scorm.setConfig]: (state, { payload }) =>
    mergeState(state, { config: payload }),
}
