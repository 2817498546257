import { isPlainObject } from 'lodash/fp'
import { useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

export default function usePropsSelector<T = any>(selector, props) {
  const cachedProps = useRef(props)

  if (isPlainObject(props) && shallowEqual(cachedProps.current, props)) {
    // Caching props so that re-reselect will not trigger re-renders even
    // when the cached key is the same.
    Object.assign(cachedProps.current, props)
  } else {
    cachedProps.current = props
  }

  return useSelector<any, T>((state) => selector(state, cachedProps.current))
}
