export default function buildS3FormData({
  file = {},
  key,
  acl,
  x_amz_credential,
  x_amz_algorithm,
  x_amz_date,
  x_amz_security_token,
  policy,
  x_amz_signature,
}: Record<string, any> = {}) {
  const formData = new FormData()

  formData.append('key', key)
  formData.append('acl', acl)
  formData.append('content-type', file.type)
  formData.append('x-amz-credential', x_amz_credential)
  formData.append('x-amz-algorithm', x_amz_algorithm)
  formData.append('x-amz-date', x_amz_date)
  formData.append('x-amz-security-token', x_amz_security_token)
  formData.append('policy', policy)
  formData.append('x-amz-signature', x_amz_signature)
  formData.append('file', file)

  return formData
}
