import axios from 'axios'
import { createActions } from 'redux-actions'

import { getPercentage } from '@masterplandev/utils'

import { ScormPackagePutRequest } from '@/api/generated-api-and-types'
import core from '@/core/utils/s3/s3.actions'

import { scormPackageSelectors } from '../selectors/scormPackage.selectors'
import buildApiUrl from '../utils/buildApiUrl'

const actions = createActions({
  SCORM: {
    PACKAGES: {
      GET: () => ({
        request: {
          url: buildApiUrl.packages(),
        },
      }),
    },
    PACKAGE: {
      POST: (data = {}) => ({
        request: {
          url: buildApiUrl.package(),
          method: 'POST',
          data,
        },
      }),
      GET: ({ scormPackageId }) => ({
        request: {
          url: buildApiUrl.package(scormPackageId),
          method: 'GET',
        },
      }),
      PUT: ({
        scormPackageId,
        ...data
      }: ScormPackagePutRequest & { scormPackageId: string }) => ({
        request: {
          url: buildApiUrl.package(scormPackageId),
          method: 'PUT',
          data,
        },
        scormPackageId,
      }),
      DELETE: ({ scormPackageId }) => ({
        request: {
          url: buildApiUrl.package(scormPackageId),
          method: 'DELETE',
        },
        scormPackageId,
      }),
    },
  },
})

// Similar code is used in frontend/src/learnpaths/actions/uploads.actions.js
// In the future might be wise to unify S3 uploading.
actions.scorm.package.upload = (packageFile) => async (dispatch) => {
  // Creating package record in DB first.
  const {
    payload: { data },
  } = await dispatch(
    actions.scorm.package.post({
      title: packageFile.name,
      bytes: packageFile.size,
    }),
  )

  if (data) {
    const {
      id: fileId,
      payload: { url, fields },
    } = data

    // Setting initial upload client-side progress to zero.
    dispatch(core.s3.progress({ fileId, progress: 0 }))

    // Upload SCORM package to AWS.
    try {
      await dispatch(
        core.s3.post({
          url,
          data: { file: packageFile, ...fields },
          onUploadProgress: (event) => {
            const progress = getPercentage(event.loaded, event.total)
            dispatch(core.s3.progress({ fileId, progress }))
          },
          fileId,
          cancelToken: axios.CancelToken.source(),
        }),
      )
    } catch {
      // Setting progress to null (nothing is being uploaded).
      dispatch(core.s3.progress({ fileId, progress: null }))
      return Promise.reject(data)
    }
  }

  return data
}

actions.scorm.package.getIfRequired = ({ scormPackageId }) =>
  function scormPackagesGetIfRequired(dispatch, getState) {
    if (scormPackageSelectors.requiresFetch(getState(), { scormPackageId })) {
      return dispatch(actions.scorm.package.get({ scormPackageId }))
    }

    return Promise.resolve()
  }

export default actions.scorm
