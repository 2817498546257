import { captureMessage } from '@sentry/react'
import { AxiosRequestConfig } from 'axios'
import type { OmitProperties } from 'ts-essentials'
import { type GenericSchema, flatten, safeParse } from 'valibot'

import axios from '@/core/axios'
import type { Prettify } from '@/core/types/Prettify'
import { env } from '@/env'

import type { ParamsFromUrl } from './ParamsFromUrl'
import { insertParamsIntoPath } from './insertParamsIntoPath'

export const type = <T>() => '' as unknown as T

/**
 * Converts object to FormData
 */
function toFormData(data: unknown) {
  if (typeof data === 'object' && data) {
    const formData = new FormData()
    Object.keys(data).forEach((key) => formData.append(key, data[key]))
    return formData
  }

  return data
}

/**
 * Schema type guard
 */
export function isSchema(value: any): value is GenericSchema {
  return (
    typeof value === `object` &&
    'kind' in value &&
    // eslint-disable-next-line no-underscore-dangle
    value.kind === 'schema'
  )
}

type Contract<TPath extends string, TData, TQuery, TOutput> = {
  path: TPath
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  response?: TOutput | GenericSchema<TOutput>
  data?: TData
  query?: TQuery
  config?: AxiosRequestConfig
}

type Input<TPath extends string, TData, TQuery> = Prettify<
  OmitProperties<
    {
      params: ParamsFromUrl<TPath>
      data: TData
      query: TQuery
    },
    Record<string, never> | undefined
  >
>

type CreateApiMethod = <
  TPath extends string,
  TData = undefined,
  TQuery extends Record<string, any> | undefined = undefined,
  TOutput = any,
>(
  contract: Contract<TPath, TData, TQuery, TOutput>,
) => keyof Input<TPath, TData, TQuery> extends never
  ? (input?: never) => Promise<TOutput>
  : (input: Input<TPath, TData, TQuery>) => Promise<TOutput>

/**
 * Creates function to fetch data from API
 *
 * Tip: You can use this [online tool](https://sinclairzx81.github.io/typebox-workbench/) to convert type to schema
 *
 * @param contract - Options
 * @param contract.response - Pass the type or schema of response.
 * @param contract.path - Path to the endpoint e.g. /posts/:id
 * @param contract.method - HTTP request methods e.g. `GET`
 * @param contract.data - Pass data type
 * @param contract.query - Pass query string params type
 * @param contract.config - Pass request config, for example, additional headers
 * @returns {Promise} Function that returns promise
 *
 * @example
 * createEndpoint({
 *   method: 'DELETE',
 *   path: `/post/:id`,
 * })
 *
 */
export const createApiMethod: CreateApiMethod =
  (contract) =>
  async (input = {}) => {
    const data = 'data' in input ? input.data : undefined
    const pathParams = 'params' in input ? input.params : undefined
    const queryParams = 'query' in input ? input.query : undefined

    const url = pathParams
      ? insertParamsIntoPath({
          path: contract.path,
          params: pathParams as Record<string, string>,
        })
      : contract.path

    const contentType = contract.config?.headers?.['Content-Type']

    const response = await axios({
      ...contract.config,
      method: contract.method,
      params: queryParams,
      url,
      data: contentType === 'multipart/form-data' ? toFormData(data) : data,
    })

    if (isSchema(contract.response)) {
      const result = safeParse(contract.response, response.data)

      if (!result.success) {
        const title = 'Invalid response data! 🤖'
        const errorMessage = flatten<typeof contract.response>(result.issues)

        if (env.MODE !== 'production') {
          console.error(
            title,
            { url },
            result.issues,
            JSON.stringify(errorMessage, null, 2),
          )
        }

        captureMessage(title, {
          level: 'fatal',
          extra: {
            url,
            contract,
            issues: JSON.stringify(errorMessage, null, 2),
          },
          fingerprint: [contract.path, contract.method],
        })
      }
    }

    return response.data
  }
