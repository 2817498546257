import React from 'react'

import styles from './AnonymousPage.module.css'
import { AnonymousPageNavigation } from './AnonymousPageNavigation'

type Props = {
  children: React.ReactNode
}

export function AnonymousPage({ children }: Props) {
  return (
    <div className={styles.page}>
      <AnonymousPageNavigation />
      <div className={styles.content}>{children}</div>
    </div>
  )
}
