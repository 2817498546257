import classNames from 'classnames'
import { ReactNode } from 'react'

import styles from './Dropdown.module.css'

interface DropdownProps {
  children: ReactNode
  className?: string
  contentClassName?: string
  dataTest?: string
  dropdownClassName?: string
  open?: boolean
  trigger: ReactNode
  darkMode?: boolean
}

export default function Dropdown({
  children,
  className,
  contentClassName,
  dataTest,
  dropdownClassName,
  open,
  trigger,
  darkMode = false,
}: DropdownProps) {
  return (
    <div
      data-testid={dataTest}
      className={classNames(
        styles.wrapper,
        {
          [styles.open]: open === true,
          [styles.close]: open === false,
        },
        className,
      )}>
      {trigger}
      <div
        data-test="dropdown"
        className={classNames(styles.dropdown, dropdownClassName)}>
        <div
          className={classNames(
            styles.content,
            { [styles.darkMode]: darkMode },
            contentClassName,
          )}>
          {children}
        </div>
      </div>
    </div>
  )
}
