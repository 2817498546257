import { ReactNode, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import {
  SCORM_ACTION_COMMIT,
  SCORM_ACTION_SET,
} from '@/core/constants/constants'

interface Props {
  children: ReactNode
  progress: number
  redirect: () => string | null
  sendMessage: (type: string, payload: any) => void
}

export function AppManagerScormConfigShared({
  children,
  progress,
  redirect,
  sendMessage,
}: Props) {
  const location = useLocation()
  const redirectUrl = redirect()

  useEffect(() => {
    if (progress) {
      sendMessage(SCORM_ACTION_SET, {
        name: 'cmi.progress_measure',
        value: progress,
      })

      if (progress >= 1) {
        sendMessage(SCORM_ACTION_SET, {
          name: 'cmi.core.lesson_status',
          value: 'passed',
        })
        sendMessage(SCORM_ACTION_SET, {
          name: 'cmi.success_status',
          value: 'passed',
        })
        sendMessage(SCORM_ACTION_SET, {
          name: 'cmi.completion_status',
          value: 'completed',
        })
      }

      sendMessage(SCORM_ACTION_COMMIT, '')
    }
  }, [progress, sendMessage])

  if (redirectUrl && redirectUrl !== location.pathname) {
    return (
      <Redirect
        to={{
          pathname: redirectUrl,
          search: location.search,
        }}
      />
    )
  }

  return children as JSX.Element
}
