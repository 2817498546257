import {
  compact,
  contains,
  curry,
  difference,
  filter,
  flatMap,
  get,
  getOr,
  gt,
  identity,
  isEmpty,
  keys,
  lt,
  map,
  omit,
  pick,
  pipe,
  placeholder,
  rest,
  size,
  some,
  toNumber,
  toPairs,
  trim,
  values,
} from 'lodash/fp'
import queryString from 'query-string'
import { createSelector } from 'reselect'

import { everyFalse, injectProps, passProps } from '@masterplandev/utils'

import { POLL_TYPE_CERTIFICATE } from '@/core/constants/constants'
import { FEATURE_SIGNUP_POLL } from '@/core/constants/features'
import { createQueryParamSelector } from '@/core/selectors'
import { createIsFeatureSelector, isStudentSelector } from '@/user/selectors'

import { buildUrl } from './utils'

export const stepSelector = createSelector(
  createQueryParamSelector('step'),
  toNumber,
)
export const maxStepSelector = createSelector(
  createQueryParamSelector('maxStep'),
  toNumber,
)

export const injectPollTypeProp = curry((type, selector) =>
  injectProps({ type }, selector),
)

export const rootSelector = ({ poll }, props) => get(buildUrl(props), poll)

export const metaSelector = createSelector(rootSelector, omit('data'))
export const dataSelector = createSelector(rootSelector, getOr({}, 'data'))

export const errorSelector = createSelector(metaSelector, get('error'))

export const errorStatusSelector = createSelector(
  metaSelector,
  get('error_status'),
)

export const fetchedSelector = createSelector(metaSelector, get('fetched'))
export const fetchingSelector = createSelector(metaSelector, get('fetching'))

export const loadingSelector = createSelector(
  isStudentSelector,
  metaSelector,
  (student, { fetched, failed }) => student && !fetched && !failed,
)

export const isSubmittedSelector = createSelector(
  dataSelector,
  get('is_completed'),
)

/*
 * Check if signup poll has been submitted by the user.
 * If signup poll feature flag is disabled fake submitted response
 * to prevent user accessing /signup/poll step
 */
export const isSignupPollSubmittedSelector = createSelector(
  injectPollTypeProp('signup', isSubmittedSelector),
  createIsFeatureSelector(FEATURE_SIGNUP_POLL),
  (result, enabled) => (enabled ? result : true),
)

export const requiresFetchSelector = createSelector(
  isStudentSelector,
  metaSelector,
  (student, meta) =>
    student &&
    pipe([pick(['fetched', 'failed', 'fetching']), values, everyFalse])(meta),
)

export const pollIdSelector = createSelector(dataSelector, get('id'))
export const titleSelector = createSelector(dataSelector, get('title'))
export const textSelector = createSelector(dataSelector, get('text'))
export const sectionsSelector = createSelector(dataSelector, get('sections'))
export const sectionsCountSelector = createSelector(sectionsSelector, size)

export const hasNextSectionSelector = createSelector(
  [stepSelector, sectionsCountSelector],
  lt,
)
export const hasPrevSectionSelector = createSelector(
  stepSelector,
  gt(placeholder, 1),
)

export const nextSectionLinkSelector = createSelector(
  [hasNextSectionSelector, stepSelector, passProps],
  (hasNext, step, { location: { pathname, search } }) => {
    if (!hasNext) {
      return null
    }

    const params = queryString.stringify({
      ...queryString.parse(search),
      step: step + 1,
    })

    return `${pathname}?${params}`
  },
)

export const prevSectionLinkSelector = createSelector(
  [hasPrevSectionSelector, stepSelector, maxStepSelector, passProps],
  (hasPrev, step, maxStep, { location: { pathname } }) => {
    if (!hasPrev) {
      return null
    }

    const params = {
      step: step - 1,
      maxStep: maxStep || step,
    }

    return `${pathname}?${queryString.stringify(params)}`
  },
)

export const sectionSelector = createSelector(
  [sectionsSelector, stepSelector],
  (sections, step) => getOr({}, step - 1, sections),
)

export const sectionTitleSelector = createSelector(
  sectionSelector,
  get('title'),
)

export const questionsSelector = createSelector(
  sectionSelector,
  getOr([], 'questions'),
)
export const allQuestionsSelector = createSelector(
  sectionsSelector,
  flatMap(getOr([], 'questions')),
)

export const checkedQuestionsSelector = createSelector(
  passProps,
  pipe([
    get('values'),
    toPairs,
    map(([questionId, answer]) => {
      if (get('skip', answer)) {
        return questionId
      }
      if (contains('text', keys(answer))) {
        const answerText = trim(get('text', answer))
        return answerText.length > 0 ? questionId : null
      }
      if (some(identity, answer)) {
        return questionId
      }
      return null
    }),
    compact,
  ]),
)

export const sectionFinishedSelector = createSelector(
  [questionsSelector, checkedQuestionsSelector],
  (questions, checked) => {
    if (isEmpty(questions)) {
      return false
    }

    return pipe([map(get('id')), difference(placeholder, checked), isEmpty])(
      questions,
    )
  },
)

export const injectCertificatePollProps = (selector) =>
  createSelector([rest(identity)], ([state, props]) =>
    injectProps({ type: POLL_TYPE_CERTIFICATE }, selector)(state, props),
  )

export const certificatePollRequiredQuestionsSelector = createSelector(
  injectCertificatePollProps(sectionsSelector),
  (sections) => {
    const questions = flatMap('questions', sections)
    return filter((question) => !question.optional, questions)
  },
)

export const certificatePollRequiredQuestionsIdsSelector = createSelector(
  certificatePollRequiredQuestionsSelector,
  map('id'),
)
