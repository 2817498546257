import { PropsWithChildren } from 'react'

import { FeatureContext } from './FeatureContext'
import { FeatureName } from './FeatureName'

type Props = {
  features: FeatureName[]
}

export function FeatureProvider({
  children,
  features = [],
}: PropsWithChildren<Props>) {
  return (
    <FeatureContext.Provider value={features}>
      {children}
    </FeatureContext.Provider>
  )
}
