import { createActions } from 'redux-actions'

const actions = createActions({
  COMPANY: {
    GET: () => ({
      request: {
        url: '/company',
      },
    }),
  },
})

export default actions.company
