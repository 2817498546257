import { map } from 'lodash/fp'

import buildLectureLink from '@/core/utils/links/buildLectureLink'
import initialiseReducerState from '@/core/utils/redux/initialiseReducerState'
import mergeStateAll from '@/core/utils/redux/mergeStateAll'

export default function normalizeTopicLectures(state, { slug, lectures }) {
  return mergeStateAll(
    state,
    map(({ slug: lecture, progress }) => {
      const lectureUrl = buildLectureLink({ topic: slug, lecture })

      return {
        [lectureUrl]: mergeStateAll(initialiseReducerState(), [
          state[lectureUrl],
          {
            data: {
              progress: {
                status: progress?.status,
              },
            },
          },
        ]),
      }
    }, lectures),
  )
}
