import { useQuery } from '@tanstack/react-query'

import { usersQueries } from '@/http/api/users'

export function useUsersDetails(userId: string) {
  return useQuery({
    ...usersQueries.usersDetail(userId),
    enabled: userId !== undefined,
  })
}
