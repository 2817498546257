import { get } from 'lodash/fp'

import mergeStateAll from './mergeStateAll'

export const mergeSuccessAction = (
  state,
  { meta, payload },
  { url, data } = {},
) => {
  const previousOptionsUrl = get('previousAction.payload.options.url', meta)
  const previousRequestUrl = get('previousAction.payload.request.url', meta)
  const urlToUse = url ?? previousOptionsUrl ?? previousRequestUrl

  return mergeStateAll(state, [
    { [urlToUse]: { data: null } },
    {
      [urlToUse]: {
        data: data || get('data', payload),
        fetching: false,
        fetched: true,
        failed: false,
        error: null,
        error_status: null,
      },
    },
  ])
}

export default mergeSuccessAction
