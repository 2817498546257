import { isEmpty, pick } from 'lodash/fp'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import actions from '@/actions'
import { SCORM_ACTION_AUTH } from '@/core/constants/constants'

import { scormAuthSelectors } from '../selectors/scorm.selectors'
import { ScormAuthPayload } from '../types/ScormAuthPayload'
import detectStudentId from '../utils/detectStudentId'

export default function useScormAuth() {
  const dispatch = useDispatch()
  const fetched = useSelector(scormAuthSelectors.fetched)
  const error = useSelector(scormAuthSelectors.error)
  const data = useSelector(scormAuthSelectors.data)

  // Id Masterplan is used as a SCORM package the code should fire up itself after it loads
  // inside an iframe. Then we post the first message signalling readiness to communicate and request
  // for authorisation. At this point Masterplan SCORM package can be called from any origin, but will
  // do the screening in the process message block, by calling auth REST API.
  useEffect(() => {
    window.parent?.postMessage({ type: SCORM_ACTION_AUTH }, '*')
  }, [])

  // Id Masterplan is used as a SCORM package the code should fire up itself after it loads
  // inside the iframe. Then we register message handler and get the referer.
  type ScormAuthData = {
    origin: string
    data: { type: string; payload: ScormAuthPayload }
  }
  useEffect(() => {
    const processMessage = async (event: ScormAuthData) => {
      const {
        origin,
        data: { type, payload },
      } = event

      const isDifferentOrigin = origin !== window.location.origin
      const isValidAuthAction = type === SCORM_ACTION_AUTH && !isEmpty(payload)

      if (isDifferentOrigin && isValidAuthAction) {
        const student_id = detectStudentId(
          pick(['student_id', 'learner_id'], payload),
        )

        const { client_id, config, location_hash } = payload
        dispatch(actions.scorm.setConfig(config))
        dispatch(actions.scorm.setOrigin(origin))

        dispatch(
          actions.scorm.auth.post({ client_id, location_hash, student_id }),
        )
      }
    }

    window.addEventListener('message', processMessage)

    return () => window.removeEventListener('message', processMessage)
  }, [dispatch])

  return {
    data,
    error,
    fetched,
  }
}
