import mergeState from '@/core/utils/redux/mergeState'

import actions from './s3.actions'

export default {
  [actions.s3.progress]: (state, { payload: { fileId, progress, type } }) => {
    return mergeState(state, {
      s3: {
        [fileId]: {
          progress,
          type,
        },
      },
    })
  },
  [actions.s3.cancel]: (state, { payload: fileId }) => {
    return mergeState(state, {
      s3: {
        [fileId]: {
          canceled: true,
        },
      },
    })
  },
}
