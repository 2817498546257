import { each } from 'lodash/fp'
import { combineActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

export default function combineActionsSuper(...actionCreators) {
  const requestTypes = []
  const successTypes = []
  const failureTypes = []

  each((type) => {
    const types = getActionTypes({ type })
    requestTypes.push(types[0])
    successTypes.push(types[1])
    failureTypes.push(types[2])
  }, actionCreators)

  return [
    combineActions(...requestTypes),
    combineActions(...successTypes),
    combineActions(...failureTypes),
  ]
}
