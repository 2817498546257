import { LOCATION_CHANGE } from 'connected-react-router'
import { has, mapValues, omit, pick, some, startsWith, values } from 'lodash/fp'

import { URL_LEARNPATHS_VIEW } from '@/core/constants/constants'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import parseLectureLink from '@/core/utils/links/parseLectureLink'

export default {
  // The aim of this action handler is to clear activeStatus boolean flag when user changes route.
  [LOCATION_CHANGE]: (state, { payload: { location } }) => {
    const match = parseLectureLink(location.pathname)

    // We need to add some exceptions, which may have match but should not be considered as the same page.
    const routeToLPsList = startsWith(URL_LEARNPATHS_VIEW, location.pathname)
    const routeToLecturePageWithoutLP =
      match?.params?.topic && !match?.params?.learnpathId

    // Is we pass the conditions it means we are routed to the /learnpaths element page.
    if (match && !routeToLPsList && !routeToLecturePageWithoutLP) {
      // Different API url (store key) is used for learning elements that routing URL. Thus we need to
      // generate redux state entry from params separately for both cases (PTE lecture, assigned element).
      const storeUrl = match.params.topic
        ? // => /learnpaths/learnpathId/elementId/topic/lecture
          buildLectureLink(
            pick(
              ['learnpathId', 'elementId', 'topic', 'lecture'],
              match.params,
            ),
          )
        : // => /learnpaths/assigned/learnpathId/elements/elementId
          buildLectureLink({
            ...pick(['learnpathId', 'elementId'], match.params),
            prefix: 'learnpaths/assigned',
            infix: 'element',
          })

      // We're jumping between tabs. Clear all statues expect the current one.
      if (state[storeUrl]?.activeStatus) {
        const newState: any = mapValues(omit('activeStatus'), state)
        newState[storeUrl].activeStatus = state[storeUrl]?.activeStatus
        return newState
      }

      // We're jumping to another lecture in the list, so clear all previous active statues.
      return mapValues(omit('activeStatus'), state)
    }

    // Potentially, we're jumping out of the /lecture or /learnpaths page, clear all active statues.
    if (some(has('activeStatus'), values(state))) {
      return mapValues(omit('activeStatus'), state)
    }

    // Do not force re-renders if nothing changed.
    return state
  },
}
