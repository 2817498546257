import { get, getOr, includes, isEmpty, merge, pipe } from 'lodash/fp'

import {
  CENSOR_STRING,
  SENSITIVE_DATA,
  anonymizeUserData,
} from '@masterplandev/utils'

export const actionSanitizer = (action) => {
  if (includes(get('meta.field', action), SENSITIVE_DATA)) {
    return { ...action, payload: CENSOR_STRING }
  }

  return pipe([
    getOr({}, 'payload.request.data'),
    anonymizeUserData,
    (data) => (isEmpty(data) ? {} : { payload: { request: { data } } }),
    merge(action),
  ])(action)
}

export default actionSanitizer
