import { createActions } from 'redux-actions'

export const actions = createActions({
  LOCALE: {
    FETCH: () => ({
      request: {
        url: '/lang',
      },
    }),
  },
})

export default actions.locale
