import { Suspense } from 'react'

import { Center, Spinner } from '@masterplandev/ui'

import { AnonymousPage } from '@/core/components/AnonymousPage/AnonymousPage'
import { lazyLoad } from '@/core/utils/lazyLoad'

const ConsentsSettings = lazyLoad(
  () => import('./ConsentsSettings/ConsentsSettings'),
)

// eslint-disable-next-line import/prefer-default-export
export function Consents() {
  return (
    <AnonymousPage>
      <Suspense
        fallback={
          <Center>
            <Spinner size="lg" />
          </Center>
        }>
        <ConsentsSettings />
      </Suspense>
    </AnonymousPage>
  )
}
