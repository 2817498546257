import { createActions } from 'redux-actions'

import { ERROR_TYPE_GENERAL } from './constants/constants'
import S3 from './utils/s3/s3.actions'
import ScrollThresholdListener from './utils/scroll/ScrollListener/ScrollListener.actions'

const actions = createActions({
  CORE: {
    ERROR: (type = ERROR_TYPE_GENERAL) => ({ type }),
    TOOLTIPS: {
      SHOW: (groupName, tooltipId) => ({ groupName, tooltipId }),
      HIDE: (groupName) => ({ groupName }),
    },
  },
})

export default {
  ...actions.core,
  ...S3,
  ...ScrollThresholdListener,
}
