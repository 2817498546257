import Cookies from 'js-cookie'
import type { KeycloakTokenParsed } from 'keycloak-js'

import { env } from '@/env'

import { INTERNAL_EMAIL_DOMAIN } from '../../constants/constants'

/**
 * Creates an internal cookie for users that log in to the app with Masterplan email domain.
 * Mainly used to eliminate internal traffic from Marketing tracking tools.
 */

export function setInternalCookie(user: KeycloakTokenParsed | undefined) {
  const domain = env.REACT_APP_DOMAIN?.replace('https://', '')

  const isInternalEmail = user?.email.endsWith(INTERNAL_EMAIL_DOMAIN)

  if (user?.email && isInternalEmail) {
    Cookies.set('internal_cookie', 'internal_traffic', {
      expires: 365,
      domain,
    })
  }
}

export default setInternalCookie
