import { createActions } from 'redux-actions'

import { scormPackagesSelectors } from '../selectors/scormPackages.selectors'
import buildApiUrl from '../utils/buildApiUrl'

const actions = createActions({
  SCORM: {
    AUTH: {
      POST: ({ client_id, student_id, location_hash }) => ({
        request: {
          url: buildApiUrl.auth(),
          method: 'POST',
          data: { client_id, student_id, location_hash },
        },
      }),
    },
    EXTERNAL: {
      LAUNCH: {
        GET: ({ courseExternalId, learnpathExternalId }) => ({
          request: {
            url: buildApiUrl.launch(),
            params: {
              content_type: courseExternalId ? 'topic' : 'learnpath',
              content_id: courseExternalId ?? learnpathExternalId,
              response_type: 'json',
            },
          },
        }),
      },
    },
    PACKAGES: {
      GET: () => ({
        request: {
          url: buildApiUrl.packages(),
        },
      }),
    },
    SET_CONFIG: (config) => config,
    SET_ORIGIN: (origin) => origin,
  },
})

actions.scorm.packages.getIfRequired = () =>
  function scormPackagesGetIfRequired(dispatch, getState) {
    if (scormPackagesSelectors.requiresFetch(getState())) {
      return dispatch(actions.scorm.packages.get())
    }

    return Promise.resolve()
  }

export default actions.scorm
