import { eq } from 'lodash/fp'

import buildLectureLink from '@/core/utils/links/buildLectureLink'
import buildApiUrl from '@/learnpaths-assigned/utils/buildApiUrl'

import { LECTURE_API_SUFFIX_PROGRESS } from '../constants'

export default function buildUniversalLectureApiUrl(
  { learnpathId, elementId, topic, lecture },
  { suffix = null, withContext = false } = {},
) {
  if (learnpathId && topic) {
    // as a context we provide learnpath_id param
    const query = withContext ? { learnpath_id: learnpathId } : {}
    return buildLectureLink({ topic, lecture, suffix, query })
  }

  if (learnpathId && elementId) {
    if (eq(suffix, LECTURE_API_SUFFIX_PROGRESS)) {
      return buildApiUrl.assignedElementProgress(learnpathId, elementId)
    }

    return buildApiUrl.assignedElement(learnpathId, elementId)
  }

  return buildLectureLink({ topic, lecture, suffix })
}
