import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

/**
 * A custom hook that builds on useLocation to parse
 * the query string.
 *
 * @returns {object} Object with search query params
 *
 * @example
 * const { step } = useSearchParams()
 */

export default function useSearchParams() {
  return queryString.parse(useLocation().search)
}
