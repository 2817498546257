import { get, identity } from 'lodash/fp'
import { createSelector } from 'reselect'

import { generateBasicSelectors } from '@/core/selectors'

import { BOOKMARKED_LECTURES_ENDPOINT } from '../constants'

export const bookmarkedRootSelector = createSelector(
  get('bookmarked'),
  identity,
)

export const bookmarkedLecturesSelectors = generateBasicSelectors(
  createSelector(bookmarkedRootSelector, get(BOOKMARKED_LECTURES_ENDPOINT)),
)

export const bookmarkedLecturesListSelector = createSelector(
  bookmarkedLecturesSelectors.data,
  get('lectures'),
)
