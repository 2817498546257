import { noop } from 'lodash/fp'
import { useRef } from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@masterplandev/ui'

import useTranslation from '@/core/hooks/useTranslation'

export function ScormIframeWarning() {
  const { translate } = useTranslation('scorm-iframe-warning')
  const ref = useRef(null)

  return (
    <AlertDialog
      size="2xl"
      isCentered
      isOpen
      leastDestructiveRef={ref}
      variant="alert"
      onClose={noop}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{translate('tile')}</AlertDialogHeader>
          <AlertDialogBody>{translate('description')}</AlertDialogBody>
          <AlertDialogFooter />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
