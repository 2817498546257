import { merge, pick } from 'lodash/fp'
import { combineActions, handleActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import actions from '@/actions'
import initialiseReducerState from '@/core/utils/redux/initialiseReducerState'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

import {
  BOOKMARKS_ENDPOINT,
  COURSE_ENDPOINT,
  SCORE_ENDPOINT,
} from './constants'

const [GET_COURSE_REQUEST, GET_COURSE_SUCCESS, GET_COURSE_ERROR] =
  getActionTypes({ type: actions.dashboard.getCourse })

const [GET_SCORE_REQUEST, GET_SCORE_SUCCESS, GET_SCORE_ERROR] = getActionTypes({
  type: actions.dashboard.getScore,
})

const [
  GET_CURRENT_LECTURE_REQUEST,
  GET_CURRENT_LECTURE_SUCCESS,
  GET_CURRENT_LECTURE_ERROR,
] = getActionTypes({ type: actions.dashboard.getCurrentLecture })

const [GET_BOOKMARKS_REQUEST, GET_BOOKMARKS_SUCCESS, GET_BOOKMARKS_ERROR] =
  getActionTypes({ type: actions.dashboard.getBookmarks })

const [
  GET_BOOKMARKS_DOWNLOAD_REQUEST,
  GET_BOOKMARKS_DOWNLOAD_SUCCESS,
  GET_BOOKMARKS_DOWNLOAD_ERROR,
] = getActionTypes({ type: actions.dashboard.getBookmarksDownload })

const [
  POST_BOOKMARKS_DOWNLOAD_REQUEST,
  POST_BOOKMARKS_DOWNLOAD_SUCCESS,
  POST_BOOKMARKS_DOWNLOAD_ERROR,
] = getActionTypes({ type: actions.dashboard.postBookmarksDownload })

const [, LECTURE_BOOKMARKS_POST_SUCCESS] = getActionTypes({
  type: actions.lecture.bookmarks.post,
})
const [, LECTURE_BOOKMARKS_PUT_SUCCESS] = getActionTypes({
  type: actions.lecture.bookmarks.put,
})
const [, LECTURE_BOOKMARKS_DELETE_SUCCESS] = getActionTypes({
  type: actions.lecture.bookmarks.delete,
})

const REQUEST_ACTIONS = combineActions(
  GET_COURSE_REQUEST,
  GET_SCORE_REQUEST,
  GET_CURRENT_LECTURE_REQUEST,
  GET_BOOKMARKS_REQUEST,
  GET_BOOKMARKS_DOWNLOAD_REQUEST,
  POST_BOOKMARKS_DOWNLOAD_REQUEST,
)
const SUCCESS_ACTIONS = combineActions(
  GET_COURSE_SUCCESS,
  GET_SCORE_SUCCESS,
  GET_CURRENT_LECTURE_SUCCESS,
  GET_BOOKMARKS_SUCCESS,
  GET_BOOKMARKS_DOWNLOAD_SUCCESS,
  POST_BOOKMARKS_DOWNLOAD_SUCCESS,
)
const ERROR_ACTIONS = combineActions(
  GET_COURSE_ERROR,
  GET_SCORE_ERROR,
  GET_CURRENT_LECTURE_ERROR,
  GET_BOOKMARKS_ERROR,
  GET_BOOKMARKS_DOWNLOAD_ERROR,
  POST_BOOKMARKS_DOWNLOAD_ERROR,
)

const LECTURE_BOOKMARKS_CHANGED = combineActions(
  LECTURE_BOOKMARKS_POST_SUCCESS,
  LECTURE_BOOKMARKS_PUT_SUCCESS,
  LECTURE_BOOKMARKS_DELETE_SUCCESS,
)

export default handleActions(
  {
    [REQUEST_ACTIONS]: mergeRequestAction,
    [SUCCESS_ACTIONS]: mergeSuccessAction,
    [ERROR_ACTIONS]: mergeErrorAction,
    [LECTURE_BOOKMARKS_CHANGED]: (state) =>
      merge(state, {
        [BOOKMARKS_ENDPOINT]: { fetched: false },
      }),
    [actions.dashboard.clearAll]: pick([SCORE_ENDPOINT]),
  },
  initialiseReducerState(COURSE_ENDPOINT),
)
