import { createRoot } from 'react-dom/client'

import { App } from '@/app/App'
import { AppErrorBoundary } from '@/app/AppErrorBoundary'
import { AppProviders } from '@/app/AppProviders'
import { env } from '@/env'

import './init'
import './styles'

function renderApp() {
  const rootElement = document.getElementById('root')

  if (rootElement === null) {
    throw new Error('Root container missing in index.html')
  }

  createRoot(rootElement).render(
    <AppErrorBoundary>
      <AppProviders>
        <App />
      </AppProviders>
    </AppErrorBoundary>,
  )
  console.info('APP_VERSION:', env.APP_VERSION)
}

// Setup MSW mock server in development
if (env.DEV && env.REACT_APP_MSW_ENABLED) {
  import('./test/server/browser').then(({ worker }) => {
    worker.start({
      onUnhandledRequest: 'bypass',
    })

    renderApp()
  })
} else {
  renderApp()
}
