import { filter, get, map } from 'lodash/fp'
import { createSelector } from 'reselect'

import { ScormPackage } from '@/api/generated-api-and-types'
import { generateBasicSelectors } from '@/core/selectors'

import buildApiUrl from '../utils/buildApiUrl'
import { rootSelector } from './scorm.selectors'

export const scormPackagesSelectors = generateBasicSelectors(
  createSelector(rootSelector, get(buildApiUrl.packages())),
)

export const scormPackagesListSelector = createSelector(
  scormPackagesSelectors.data,
  get('packages'),
)

export const scormPackagesListPendingSelector = createSelector(
  scormPackagesListSelector,
  filter<ScormPackage>({ status: 'pending' }),
)

export const scormPackagesListPendingIdsSelector = createSelector(
  scormPackagesListPendingSelector,
  map('id'),
)
